//* ======= Libraries
import React, { useState, useEffect } from 'react'
//* ======= Components and features
import TextWidgetSettings from 'features/report-designer/widgets/text-widget/TextWidgetSettings'
import ImageWidgetSettings from 'features/report-designer/widgets/image-widget/ImageWidgetSettings'
import ChartWidgetSettings from 'features/report-designer/widgets/chart-widget/ChartWidgetSettings'
import NetworkWidgetSettings from './network-widget/NetworkWidgetSettings'
import FilterWidgetSettings from './filter-widget/FilterWidgetSettings'
import TableWidgetSettings from 'features/report-designer/widgets/table-widget/TableWidgetSettings'
import InsightWidgetSettings from './insight-widget/InsightWidgetSettings'
import VideoWidgetSettings from 'features/report-designer/widgets/video-widget/VideoWidgetSettings'
import InfoWidgetSettings from 'features/report-designer/widgets/info-widget/InfoWidgetSettings'
import NavLinkWidgetSettings from 'features/report-designer/widgets/nav-link/NavLinkWidgetSettings'
import AIWidgetSettings from './ai-widget/AIWidgetSettings'
import HorizontalLineWidgetSettings from 'features/report-designer/widgets/horizontal-line-widget/HorizontalLineWidgetSettings'
import VerticalLineWidgetSettings from 'features/report-designer/widgets/vertical-line-widget/VerticalLineWidgetSettings'
//* ======= Custom logic
import useReportStore, {
    useReportActiveWidget,
    useReportSelectedWidgets,
} from 'features/report-designer/store/reportDesignerStore'
import { ReportWidgetType } from '../types/reportDesigner.types'
import { Box, Chip, Divider, Stack, Tooltip, Typography } from '@mui/material'
import BaseButton from 'components/base/BaseButton'
import CombinedWidgetSettings from './combined-widget/CombineWidgetSettings'
import DynamicControlWidgetSettings from './dynamic-control-widget/DynamicControlWidgetSettings'
import PanelWidgetSettings from './panel-widget/PanelWidgetSettings'
import KeyInsightWidgetSettings from './key-insight-widget/KeyInsightWidgetSettings'
//* ======= Assets and styles

function ReportWidgetSettings() {
    const activeWidget = useReportActiveWidget()
    const selectedWidgets = useReportSelectedWidgets()

    return (
        <>
            {selectedWidgets !== null ? (
                <MultiWidgetSettings selectedWidgets={selectedWidgets} />
            ) : (
                <ReportWidgetSettingsSwitcher widget={activeWidget} />
            )}
        </>
    )
}

function MultiWidgetSettings({ selectedWidgets }: { selectedWidgets: ReportWidgetType[] }) {
    const { combineWidgets } = useReportStore((store) => ({
        combineWidgets: store.combineWidgets,
    }))

    return (
        <Stack
            gap={1}
            sx={(theme) => ({
                height: '100%',
                paddingTop: theme.spacing(0.5),
                paddingX: theme.spacing(2),
                overflowY: 'auto',
                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                overflowX: 'hidden',
            })}
            className="u-scrollbar"
        >
            {/* Heading
                        ========================================= */}
            <Typography fontSize={16} noWrap sx={{ flexShrink: 0 }}>
                Selected Widgets
            </Typography>

            <Divider
                sx={{
                    marginBottom: 1.5,
                }}
            />

            {selectedWidgets !== null ? (
                <>
                    {selectedWidgets.map((widget) => (
                        <Chip key={widget.id} label={widget.title} />
                    ))}

                    <Divider
                        sx={{
                            marginTop: 1.5,
                            marginBottom: 1.5,
                        }}
                    />
                    <Tooltip title="Group widgets">
                        <BaseButton
                            variant="outlined"
                            size="small"
                            fullWidth
                            onClick={() => combineWidgets({ mode: 'group' })}
                        >
                            Group
                        </BaseButton>
                    </Tooltip>

                    <Tooltip title="Combine widgets">
                        <BaseButton
                            variant="outlined"
                            size="small"
                            fullWidth
                            onClick={() => combineWidgets({ mode: 'select' })}
                        >
                            Combine
                        </BaseButton>
                    </Tooltip>
                </>
            ) : (
                /*  Null view
                    ========================================= */
                false
            )}
        </Stack>
    )
}

type ReportWidgetSettingsSwitcherProps = {
    widget: ReportWidgetType | null
    isInternal?: boolean
}

export function ReportWidgetSettingsSwitcher({ widget, isInternal = false }: ReportWidgetSettingsSwitcherProps) {
    return (
        <Box sx={{ height: '100%', overflow: 'hidden' }}>
            {widget !== null ? (
                widget.content.kind === 'text' ? (
                    /*  Text widget settings
                        ========================================= */
                    <TextWidgetSettings
                        // The key is needed to trigger a re-render.
                        key={widget.id}
                        widgetId={widget.id}
                        isInternal={isInternal}
                    />
                ) : widget.content.kind === 'image' ? (
                    /*  Image widget settings
                        ========================================= */
                    <ImageWidgetSettings
                        // The key is needed to trigger a re-render.
                        key={widget.id}
                        widgetId={widget.id}
                        isInternal={isInternal}
                    />
                ) : widget.content.kind === 'chart' ? (
                    /*  Chart widget settings
                        ========================================= */
                    <ChartWidgetSettings
                        // The key is needed to trigger a re-render.
                        key={widget.id}
                        widgetId={widget.id}
                        isInternal={isInternal}
                    />
                ) : widget.content.kind === 'network' ? (
                    /*  Network widget settings
                        ========================================= */
                    <NetworkWidgetSettings
                        // The key is needed to trigger a re-render.
                        key={widget.id}
                        widgetId={widget.id}
                        isInternal={isInternal}
                    />
                ) : widget.content.kind === 'filter' ? (
                    /*  Filter widget settings
                        ========================================= */
                    <FilterWidgetSettings
                        // The key is needed to trigger a re-render.
                        key={widget.id}
                        widgetId={widget.id}
                        isInternal={isInternal}
                    />
                ) : widget.content.kind === 'table' ? (
                    /*  Table widget settings
                        ========================================= */
                    <TableWidgetSettings
                        // The key is needed to trigger a re-render.
                        key={widget.id}
                        widgetId={widget.id}
                        isInternal={isInternal}
                    />
                ) : widget.content.kind === 'insight' ? (
                    /*  Insight widget settings
                        ========================================= */
                    <InsightWidgetSettings
                        // The key is needed to trigger a re-render.
                        key={widget.id}
                        widgetId={widget.id}
                        isInternal={isInternal}
                    />
                ) : widget.content.kind === 'ai' ? (
                    /*  AI widget settings
                        ========================================= */
                    <AIWidgetSettings
                        // The key is needed to trigger a re-render.
                        key={widget.id}
                        widgetId={widget.id}
                        isInternal={isInternal}
                    />
                ) : widget.content.kind === 'video' ? (
                    /*  Video widget settings
                        ========================================= */
                    <VideoWidgetSettings
                        // The key is needed to trigger a re-render.
                        key={widget.id}
                        widgetId={widget.id}
                        isInternal={isInternal}
                    />
                ) : widget.content.kind === 'info' ? (
                    /*  Info widget settings
                        ========================================= */
                    <InfoWidgetSettings
                        // The key is needed to trigger a re-render.
                        key={widget.id}
                        widgetId={widget.id}
                        isInternal={isInternal}
                    />
                ) : widget.content.kind === 'navLink' ? (
                    /*  Info widget settings
                        ========================================= */
                    <NavLinkWidgetSettings
                        // The key is needed to trigger a re-render.
                        key={widget.id}
                        widgetId={widget.id}
                        isInternal={isInternal}
                    />
                ) : widget.content.kind === 'horizontalLine' ? (
                    /*  Horizontal line widget settings
                        ========================================= */
                    <HorizontalLineWidgetSettings
                        // The key is needed to trigger a re-render.
                        key={widget.id}
                        widgetId={widget.id}
                        isInternal={isInternal}
                    />
                ) : widget.content.kind === 'verticalLine' ? (
                    /*  Vertical line widget settings
                        ========================================= */
                    <VerticalLineWidgetSettings
                        // The key is needed to trigger a re-render.
                        key={widget.id}
                        widgetId={widget.id}
                        isInternal={isInternal}
                    />
                ) : widget.content.kind === 'combined' ? (
                    /*  Combined widget settings
                        ========================================= */
                    <CombinedWidgetSettings
                        // The key is needed to trigger a re-render.
                        key={widget.id}
                        widgetId={widget.id}
                        isInternal={isInternal}
                    />
                ) : widget.content.kind === 'dynamicControl' ? (
                    /*  Dynamic control widget settings
                        ========================================= */
                    <DynamicControlWidgetSettings
                        // The key is needed to trigger a re-render.
                        key={widget.id}
                        widgetId={widget.id}
                        isInternal={isInternal}
                    />
                ) : widget.content.kind === 'keyInsight' ? (
                    /*  Key insight widget settings
                        ========================================= */
                    <KeyInsightWidgetSettings
                        // The key is needed to trigger a re-render.
                        key={widget.id}
                        widgetId={widget.id}
                        isInternal={isInternal}
                    />
                ) : widget.content.kind === 'panel' ? (
                    <PanelWidgetSettings key={widget.id} widgetId={widget.id} isInternal={isInternal} />
                ) : (
                    false
                )
            ) : (
                /*  Null view
                    ========================================= */
                false
            )}
        </Box>
    )
}

export default ReportWidgetSettings
