import { useMemo } from 'react'
import {
    InsightMessageType,
    InsightWidgetType,
    ReportWidgetType,
} from 'features/report-designer/types/reportDesigner.types'
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import TableDataHeader, { TableDataHeaderType } from '../../table-widget/views/TableDataHeader'
import { Box, Divider, Stack, Typography } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import InfoIcon from '@mui/icons-material/Info'
import CircleIcon from '@mui/icons-material/Circle'

type InsightWidgetCardViewProps = {
    widgetId: ReportWidgetType['id']
    isActive: boolean
    insightWidget: InsightWidgetType
    fontSize: number
}

const InsightWidgetCardView = ({ widgetId, isActive, insightWidget, fontSize }: InsightWidgetCardViewProps) => {
    const { slideId, updateInsightWidgetContent } = useReportStore((store) => ({
        slideId: store.activeSlideId,
        updateInsightWidgetContent: store.updateInsightWidgetContent,
    }))

    const activeRowIndex = useMemo(() => {
        if (insightWidget.cachedData === null) return null
        if (insightWidget.mode === 'group' && insightWidget.groupBy === undefined) return 0
        if (Object.keys(insightWidget.cachedData).length === 0) return null
        if (Object.keys(insightWidget.cachedData).length === 1) return 0
        if (insightWidget.selectedItem === null) return null
        const key = insightWidget.selectedItem
        return Object.keys(insightWidget.cachedData).indexOf(key)
    }, [insightWidget.selectedItem, insightWidget.cachedData, insightWidget.mode, insightWidget.groupBy])

    const rowOptions = useMemo<TableDataHeaderType[]>(() => {
        // If cachedData is null, return an empty array immediately
        if (insightWidget.cachedData === null) {
            return []
        }

        return Object.keys(insightWidget.cachedData).map((key) => {
            return {
                id: key,
                title: insightWidget.cachedData![key].label,
            }
        }) as TableDataHeaderType[]
    }, [insightWidget.cachedData])

    const insightsData: InsightMessageType[] | undefined = useMemo(() => {
        if (insightWidget.cachedData != null) {
            if (insightWidget.mode === 'group' && insightWidget.groupBy === undefined) {
                return insightWidget.cachedData['all'].data
            } else if (insightWidget.mode === 'ergm/alaam') {
                return insightWidget.cachedData['all'].data
            } else if (insightWidget.selectedItem) {
                return insightWidget.cachedData[insightWidget.selectedItem]?.data
            }
        }
    }, [insightWidget.selectedItem, insightWidget.cachedData, insightWidget.mode, insightWidget.groupBy])

    return (
        <>
            {/* Data header */}
            {insightWidget.basicConfiguration.hasHeader &&
                (activeRowIndex !== null || insightWidget.basicConfiguration.canChangeRows) && (
                    <Box py={1} px={0}>
                        <TableDataHeader
                            activeRowIndex={activeRowIndex}
                            updateActiveRowIndex={(updatedIndex) => {
                                const v = rowOptions[updatedIndex].id + ''
                                updateInsightWidgetContent({
                                    slideId: slideId!,
                                    widgetId: widgetId,
                                    data: {
                                        selectedItem: v,
                                    },
                                })
                            }}
                            avatarSize={fontSize * 2}
                            data={rowOptions}
                            canChangeRows={insightWidget.basicConfiguration.canChangeRows}
                            hasSearch={insightWidget.basicConfiguration.hasSearch}
                            headerStyle={insightWidget.basicConfiguration.headerStyle}
                            disabled={!isActive}
                        />
                    </Box>
                )}

            {/* Insights */}
            <Box>
                {/* if no item is selected, display a text */}
                {activeRowIndex === null ? (
                    <Box sx={{ margin: 'auto' }}>
                        <Typography textAlign="center">Select an item to see the insights</Typography>
                    </Box>
                ) : insightsData === undefined || insightsData.length === 0 ? (
                    <Box sx={{ margin: 'auto' }}>
                        <Typography fontWeight={700}>{insightWidget.emptyDataMessage}</Typography>
                    </Box>
                ) : insightWidget.groupInsights ? (
                    <InsightsListGrouped
                        insightsData={insightsData}
                        fontStyle={{ size: fontSize, family: insightWidget.fontStyles.fontFamily }}
                    />
                ) : (
                    <InsightsList
                        insightsData={insightsData}
                        fontStyle={{ size: fontSize, family: insightWidget.fontStyles.fontFamily }}
                    />
                )}
            </Box>
        </>
    )
}

type InsightsListProps = {
    insightsData: InsightMessageType[]
    fontStyle: {
        size: number
        family: string
    }
}

const InsightsList = ({ insightsData, fontStyle }: InsightsListProps) => {
    return (
        <>
            {insightsData.map((message) => {
                return (
                    <Stack padding={1} gap={0.5}>
                        <Stack direction="row" alignItems="center" gap={2}>
                            {/* Icon */}
                            {message.icon ? (
                                <></>
                            ) : message.mode === 'negative' ? (
                                <WarningIcon color="warning" sx={{ fontSize: fontStyle.size }} />
                            ) : message.mode === 'neutral' ? (
                                <InfoIcon color="primary" sx={{ fontSize: fontStyle.size }} />
                            ) : (
                                <DoneAllIcon color="success" sx={{ fontSize: fontStyle.size }} />
                            )}
                            <Typography
                                fontWeight={500}
                                sx={{ fontSize: fontStyle.size, fontFamily: fontStyle.family }}
                            >
                                {message.text}
                            </Typography>
                        </Stack>
                        {message.description && (
                            <Typography ml={5} sx={{ fontSize: fontStyle.size * 0.7, fontFamily: fontStyle.family }}>
                                {message.description}
                            </Typography>
                        )}
                        <Divider />
                    </Stack>
                )
            })}
        </>
    )
}

const InsightsListGrouped = ({ insightsData, fontStyle }: InsightsListProps) => {
    const groupedData = useMemo(() => {
        const groups: { [key: string]: { title: string; description?: string }[] } = {
            negative: [],
            positive: [],
            neutral: [],
        }

        for (let message of insightsData) {
            groups[message.mode].push({
                title: message.text,
                description: message.description,
            })
        }
        return groups
    }, [insightsData])

    return (
        <>
            {groupedData.negative.length > 0 && (
                <>
                    <Stack direction="row" padding={1} gap={1} alignItems="center">
                        <WarningIcon color="warning" />
                        <Typography fontWeight={600} sx={{ fontSize: fontStyle.size, fontFamily: fontStyle.family }}>
                            Areas of Attention
                        </Typography>
                    </Stack>

                    {groupedData.negative.map((item) => (
                        <Stack direction="row" padding={1} paddingLeft={5} gap={1} alignItems="center">
                            <CircleIcon sx={{ fontSize: fontStyle.size }} />
                            <Stack>
                                <Typography
                                    fontWeight={500}
                                    sx={{ fontSize: fontStyle.size, fontFamily: fontStyle.family }}
                                >
                                    {item.title}
                                </Typography>
                                {item.description && (
                                    <Typography sx={{ fontSize: fontStyle.size * 0.7, fontFamily: fontStyle.family }}>
                                        {item.description}
                                    </Typography>
                                )}
                            </Stack>
                        </Stack>
                    ))}
                </>
            )}

            {groupedData.positive.length > 0 && (
                <>
                    <Stack direction="row" padding={1} gap={1} alignItems="center">
                        <DoneAllIcon color="success" />
                        <Typography fontWeight={600} sx={{ fontSize: fontStyle.size, fontFamily: fontStyle.family }}>
                            Strengths
                        </Typography>
                    </Stack>

                    {groupedData.positive.map((item) => (
                        <Stack direction="row" padding={1} paddingLeft={5} gap={1} alignItems="center">
                            <CircleIcon sx={{ fontSize: fontStyle.size }} />
                            <Stack>
                                <Typography
                                    fontWeight={500}
                                    sx={{ fontSize: fontStyle.size, fontFamily: fontStyle.family }}
                                >
                                    {item.title}
                                </Typography>
                                {item.description && (
                                    <Typography sx={{ fontSize: fontStyle.size * 0.7, fontFamily: fontStyle.family }}>
                                        {item.description}
                                    </Typography>
                                )}
                            </Stack>
                        </Stack>
                    ))}

                    <Divider sx={{ width: '80%', marginX: 'auto', marginY: 2 }} />
                </>
            )}

            {groupedData.neutral.length > 0 && (
                <>
                    <Stack direction="row" padding={1} gap={1} alignItems="center">
                        <InfoIcon color="primary" />
                        <Typography fontWeight={600} sx={{ fontSize: fontStyle.size, fontFamily: fontStyle.family }}>
                            Information
                        </Typography>
                    </Stack>

                    {groupedData.neutral.map((item) => (
                        <Stack direction="row" padding={1} paddingLeft={5} gap={1} alignItems="center">
                            <CircleIcon sx={{ fontSize: fontStyle.size }} />
                            <Stack>
                                <Typography
                                    fontWeight={500}
                                    sx={{ fontSize: fontStyle.size, fontFamily: fontStyle.family }}
                                >
                                    {item.title}
                                </Typography>
                                {item.description && (
                                    <Typography sx={{ fontSize: fontStyle.size * 0.7, fontFamily: fontStyle.family }}>
                                        {item.description}
                                    </Typography>
                                )}
                            </Stack>
                        </Stack>
                    ))}

                    <Divider sx={{ width: '80%', marginX: 'auto', marginY: 2 }} />
                </>
            )}
        </>
    )
}

export default InsightWidgetCardView
