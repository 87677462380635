//* ======= Libraries
import React from 'react'
import { useDrag } from 'react-dnd'
import { Tooltip, Stack } from '@mui/material'
//* ======= Components and features
//* ======= Custom logic
import {
    ReportWidgetContentKindType,
    ReportWidgetsToolbarDragItemType,
} from 'features/report-designer/types/reportDesigner.types'
//* ======= Assets and styles
import TextIcon from '@mui/icons-material/Abc'
import ImageIcon from '@mui/icons-material/Image'
import ChartIcon from '@mui/icons-material/LineAxis'
import HubIcon from '@mui/icons-material/Hub'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import TableIcon from '@mui/icons-material/TableChart'
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt'
import VideoIcon from '@mui/icons-material/Movie'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import SmartButtonIcon from '@mui/icons-material/SmartButton'
import WindowIcon from '@mui/icons-material/Window'
import DnsIcon from '@mui/icons-material/Dns'

export const ReportWidgetsToolbarDraggableItemDragTypeValue = 'WIDGET'

type Props = {
    tooltip: string
    widgetKind: ReportWidgetContentKindType
}

function ReportWidgetsToolbarDraggableItem({ tooltip, widgetKind }: Props) {
    const [, dragSource] = useDrag<ReportWidgetsToolbarDragItemType, unknown, unknown>(() => ({
        type: ReportWidgetsToolbarDraggableItemDragTypeValue,
        item: {
            widgetKind: widgetKind,
        },
        // collect: (monitor) => ({
        //     isDragging: monitor.isDragging(),
        // }),
    }))

    return (
        <Tooltip title={tooltip} enterDelay={200} arrow placement="right">
            <Stack
                ref={dragSource}
                alignItems="center"
                justifyContent="center"
                sx={{
                    width: '100%',
                    paddingY: 0.75,
                    cursor: 'pointer',
                }}
            >
                {widgetKind === 'text' ? (
                    <TextIcon
                        sx={{
                            height: '100%',
                            width: 30,
                        }}
                    />
                ) : widgetKind === 'image' ? (
                    <ImageIcon
                        sx={{
                            height: '100%',
                            width: 26,
                        }}
                    />
                ) : widgetKind === 'chart' ? (
                    <ChartIcon
                        sx={{
                            height: '100%',
                            width: 28,
                        }}
                    />
                ) : widgetKind === 'network' ? (
                    <HubIcon
                        sx={{
                            height: '100%',
                            width: 24,
                        }}
                    />
                ) : widgetKind === 'filter' ? (
                    <ManageSearchIcon
                        sx={{
                            height: '100%',
                            width: 28,
                        }}
                    />
                ) : widgetKind === 'table' ? (
                    <TableIcon
                        sx={{
                            height: '100%',
                            width: 26,
                        }}
                    />
                ) : widgetKind === 'insight' ? (
                    <PsychologyAltIcon
                        sx={{
                            height: '100%',
                            width: 26,
                        }}
                    />
                ) : widgetKind === 'ai' ? (
                    <TipsAndUpdatesIcon
                        sx={{
                            height: '100%',
                            width: 26,
                        }}
                    />
                ) : widgetKind === 'video' ? (
                    <VideoIcon
                        sx={{
                            height: '100%',
                            width: 26,
                        }}
                    />
                ) : widgetKind === 'info' ? (
                    <InfoOutlinedIcon
                        sx={{
                            height: '100%',
                            width: 28,
                        }}
                    />
                ) : widgetKind === 'navLink' ? (
                    <DatasetLinkedIcon
                        sx={{
                            height: '100%',
                            width: 26,
                        }}
                    />
                ) : widgetKind === 'horizontalLine' ? (
                    <HorizontalRuleIcon
                        sx={{
                            height: '100%',
                            width: 30,
                        }}
                    />
                ) : widgetKind === 'verticalLine' ? (
                    <HorizontalRuleIcon
                        sx={{
                            height: '100%',
                            width: 30,

                            transform: 'rotate(90deg)',
                        }}
                    />
                ) : widgetKind === 'dynamicControl' ? (
                    <SmartButtonIcon
                        sx={{
                            height: '100%',
                            width: 30,
                        }}
                    />
                ) : widgetKind === 'panel' ? (
                    <WindowIcon
                        sx={{
                            height: '100%',
                            width: 30,
                        }}
                    />
                ) : widgetKind === 'keyInsight' ? (
                    <DnsIcon
                        sx={{
                            height: '100%',
                            width: 26,
                        }}
                    />
                ) : undefined}
            </Stack>
        </Tooltip>
    )
}

export default ReportWidgetsToolbarDraggableItem
