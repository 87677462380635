//* ======= Libraries
import { Draft } from 'immer'
import { PartialDeep } from 'type-fest'
import { assign, cloneDeep, difference } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
//* ======= Components and features
//* ======= Custom logic
import { HistoryActionItemType, ReportDesignerStoreStateType } from 'features/report-designer/store/reportDesignerStore'
import {
    ReportCopySourceType,
    ReportDataSourceType,
    ReportLayoutSettingsType,
    ReportMasterSettingsType,
    ReportSavedStateType,
    ReportSlideType,
    ReportWidgetContentType,
    ReportWidgetStylesType,
    ReportWidgetType,
    FilterRecordType,
    TextWidgetType,
    ImageWidgetType,
    ChartWidgetType,
    NetworkWidgetType,
    FilterWidgetType,
    TableWidgetType,
    InsightWidgetType,
    AIWidgetType,
    VideoWidgetType,
    InfoWidgetType,
    NavLinkWidgetType,
    HorizontalLineWidgetType,
    VerticalLineWidgetType,
    CombinedWidgetType,
    ReportTourType,
    DynamicControlWidgetType,
    PanelWidgetType,
    KeyInsightWidgetType,
} from 'features/report-designer/types/reportDesigner.types'
import { ReportCustomViewType, ReportShareType } from 'features/share-report-dialog/ShareReportDialog'
import { deepMergeCustom } from 'helpers/helpers'
import { ReportType } from 'services/ReportApi'
import {
    defaultReportSlideValues,
    defaultReportWidgetValues,
} from 'features/report-designer/helpers/reportDesignerDefaultValues'
import WebWorker from 'helpers/webWorkerHelper'
import { APP_VERSIONS } from 'helpers/constants'
import migrateReportState from 'features/report-designer/helpers/reportDesigner.migration'
import { generateChartOptions } from 'features/chart/Chart.helper'
import {
    applySelectedItemChangeToWidgets,
    getBaseDimensions,
    getChartAttributes,
    getConnectedWidgets,
    getDataSourceData,
    parseWidgetPosition,
} from 'features/report-designer/helpers/reportDesigner.helper'
import {
    getTableAttributes,
    parseTableData,
} from 'features/report-designer/widgets/table-widget/helpers/TableWidget.helper'
import { retrieveBaseDimensions, scaleUpDimensions } from '../widgets/WidgetContainer'
import { genereateInsightWidgetData } from '../widgets/insight-widget/InisghtWidget.helper'
import {
    addToHistory,
    addUpdateSlideToHistory,
    addUpdateWidgetToHistory,
    perfromHistoryAction,
} from 'features/report-designer/store/historyTraker.helper'

//* ======= Assets and styles

/* *
 * Notes:
 *
 *  We intentionally leave out the "actions" type in here to prevent accidentally overwriting actions in state updates.
 *  The "nextStateOrUpdater" method callback's "state" param is the entire store value; state AND actions, but
 *  we've typed it as "ReportDesignerStoreStateType" only, leaving out the "ReportDesignerStoreActionsType" type.
 */

type ReportDesignerStoreSetCallback = (
    nextStateOrUpdater:
        | ReportDesignerStoreStateType
        | Partial<ReportDesignerStoreStateType>
        | ((state: Draft<ReportDesignerStoreStateType>) => void),
    shouldReplace?: boolean | undefined
) => void

type ReportDesignerStoreGetCallback = () => ReportDesignerStoreStateType & ReportDesignerStoreActionsType

/*
 * Report actions
 * ========================================= */

//* Reset

const resetReport =
    (storeUpdater: ReportDesignerStoreSetCallback, initialState: ReportDesignerStoreStateType) => () => {
        storeUpdater((state) => {
            assign(state, initialState)
        })
    }

//* General

type UpdateReportParams = {
    data: Partial<ReportDesignerStoreStateType>
}

const updateReport =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ data }: UpdateReportParams) => {
        storeUpdater((state) => {
            assign(state, data)
        })
    }

//* Report status and error message

type UpdateStatus = {
    status: ReportDesignerStoreStateType['status']
    errorMessage?: ReportDesignerStoreStateType['errorMessage']
}

const updateStatus =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ status, errorMessage }: UpdateStatus) => {
        storeUpdater((state) => {
            assign(state, {
                status,
                errorMessage,
            })
        })
    }

//* Report title

type UpdateTitleParams = {
    title: ReportDesignerStoreStateType['title']
}

const updateTitle =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ title }: UpdateTitleParams) => {
        storeUpdater((state) => {
            state.title = title
        })
    }

//* Report view mode

type UpdateViewModeParams = {
    mode: ReportDesignerStoreStateType['viewMode']
}

const updateViewMode =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ mode }: UpdateViewModeParams) => {
        storeUpdater((state) => {
            state.viewMode = mode
        })
    }

//* Report aspect ratio

type UpdateAspectRatioParams = {
    ratio: ReportDesignerStoreStateType['aspectRatio']
}

const updateAspectRatio =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ ratio }: UpdateAspectRatioParams) => {
        storeUpdater((state) => {
            state.aspectRatio = ratio
        })
    }

//* Report master styles

type UpdateMasterSettingsParams = {
    settings: PartialDeep<ReportMasterSettingsType>
}

const updateMasterSettings =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ settings }: UpdateMasterSettingsParams) => {
        storeUpdater((state) => {
            deepMergeCustom(state.masterSettings, settings)
        })
    }

//* Report background

type UpdateBackgroundColorParams = {
    styles: PartialDeep<ReportMasterSettingsType['backgroundColor']>
}

const updateBackgroundColor =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ styles }: UpdateBackgroundColorParams) => {
        storeUpdater((state) => {
            deepMergeCustom(state.masterSettings.backgroundColor, styles)

            // Update any slide that hasn't configured its own background styles with the report's master styles.
            for (let index = 0; index < state.slides.length; index++) {
                const slide = state.slides[index]

                if (slide.styles.backgroundColor.isEnabled === false) {
                    const { isEnabled, ...reportBGStyles } = styles

                    deepMergeCustom(state.slides[index].styles.backgroundColor, reportBGStyles)
                }
            }
        })
    }

//* Report header

type UpdateHeaderParams = {
    settings: PartialDeep<ReportMasterSettingsType['header']>
}

const updateHeader =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ settings }: UpdateHeaderParams) => {
        storeUpdater((state) => {
            deepMergeCustom(state.masterSettings.header, settings)

            const { isEnabled, ...reportHeaderSettings } = settings

            // Update slides' header settings based on the updated report's master header settings.
            for (let index = 0; index < state.slides.length; index++) {
                // We have to use lodash's "cloneDeep" to clone store values because they are Proxy objects with possible
                // nested Proxies and seemingly "structuredClone" can't handle them!
                // Cloning also allows safe direct updates, with access to pre-update values.
                // Updating "state.slides[index]" directly and without cloning the initial value in this variable
                // would mess up the subsequent conditions.
                const slide = cloneDeep(state.slides[index])

                //* Report header visibility change

                if (isEnabled !== undefined && isEnabled === true) {
                    if (slide.header.isEnabled) {
                        // Do nothing.
                    } else if (slide.header.isEnabled === false) {
                        // Do nothing.
                    } else if (slide.header.isEnabled === null) {
                        state.slides[index].header.isEnabled = true
                    }
                } else if (isEnabled !== undefined && isEnabled === false) {
                    if (slide.header.isEnabled) {
                        if (slide.header.isOverridden) {
                            // Do nothing.
                        } else {
                            // If master header is being disabled while slide hasn't overridden master settings,
                            // then the current slide header's settings are equal to the master's and disabling it should be
                            // as if the slide still has its initial, untouched "null" value for the "isEnabled" field.
                            // This "null" value is important for the other conditions when enabling slide headers.
                            state.slides[index].header.isEnabled = null
                        }
                    } else if (slide.header.isEnabled === false) {
                        // Do nothing.
                    } else if (slide.header.isEnabled === null) {
                        // Do nothing.
                    }
                }

                //* Report header settings change (other than "isEnabled")

                // If slide header is enabled
                if (slide.header.isEnabled) {
                    // If slide has overridden master header settings
                    if (slide.header.isOverridden) {
                        // Do nothing. Let slide's own header settings remain.
                    }
                    // If slide didn't override any of the master header settings, then it will be equal to the master.
                    // Therefore, we can safely merge master header settings with slide's header.
                    else {
                        deepMergeCustom(state.slides[index].header, reportHeaderSettings)
                    }
                }
                // If slide has disabled header for itself
                else if (slide.header.isEnabled === false) {
                    // If slide has overridden master header settings
                    if (slide.header.isOverridden) {
                        // Do nothing. Let slide's own header settings remain.
                    } else {
                        // We don't need to update "isEnabled" because slide has explicitly configured its header to be disabled.
                        deepMergeCustom(state.slides[index].header, reportHeaderSettings)
                    }
                }
                // If slide hasn't explicitly disabled header
                else if (slide.header.isEnabled === null) {
                    // If slide has overridden master header settings
                    if (slide.header.isOverridden) {
                        // Do nothing. Let slide's own header settings remain.
                    } else {
                        deepMergeCustom(state.slides[index].header, reportHeaderSettings)
                    }
                }
            }
            console.log(state.slides)
        })
    }

//* Report layout settings, left sidebar

type UpdateLayoutLeftSidebarParams = {
    settings: PartialDeep<ReportLayoutSettingsType['leftSidebar']>
}

const updateLayoutLeftSidebar =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ settings }: UpdateLayoutLeftSidebarParams) => {
        storeUpdater((state) => {
            deepMergeCustom(state.layoutSettings.leftSidebar, settings)
        })
    }

//* Report layout settings, settings sidebar

type UpdateLayoutSettingsSidebarParams = {
    settings: PartialDeep<ReportLayoutSettingsType['settingsSidebar']>
}

const updateLayoutSettingsSidebar =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ settings }: UpdateLayoutSettingsSidebarParams) => {
        storeUpdater((state) => {
            deepMergeCustom(state.layoutSettings.settingsSidebar, settings)
        })
    }

//* Report layout settings, settings sidebar view

type UpdateLayoutSettingsSidebarViewParams = {
    view: ReportLayoutSettingsType['settingsSidebar']['view']
}

const updateLayoutSettingsSidebarView =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ view }: UpdateLayoutSettingsSidebarViewParams) => {
        storeUpdater((state) => {
            // If the selected settings view is anything other than "widget"
            // then logically, there shouldn't be an active widget.
            if (view !== 'widget') {
                state.activeWidgetId = null
                state.secondarySelectedWidgetIds = []
            }

            state.layoutSettings.settingsSidebar.view = view
        })
    }

//* Report active slide ID

type SetActiveSlideIdParams = {
    slideId: ReportDesignerStoreStateType['activeSlideId']
}

const setActiveSlideId =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId }: SetActiveSlideIdParams) => {
        storeUpdater((state) => {
            // If the active slide is already the same as the new slide ID, then do nothing.
            if (state.activeSlideId === slideId) return

            state.activeSlideId = slideId

            // When the active slide changes, we should show the slide settings
            // and also clear the active widget ID because if we are explicitly
            // trying to "set active slide", then we mean to start from that new slide's
            // view and a new slide has no selected or "active" widgets.
            if (slideId !== null) {
                state.widgetsToRender =
                    state.slides
                        .find((slide) => slide.id === slideId)
                        ?.widgets.filter((widget) => !widget.hide)
                        .map((x) => x.id) ?? []

                state.layoutSettings.settingsSidebar.view = 'slide'

                state.activeWidgetId = null
                state.secondarySelectedWidgetIds = []
            }
        })
    }

type OnWidgetReadyProps = {
    widgetId: ReportWidgetType['id']
}

const onWidgetReady =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ widgetId }: OnWidgetReadyProps) => {
        storeUpdater((state) => {
            state.widgetsToRender = state.widgetsToRender.filter((x) => x !== widgetId)
        })
    }

//* Report active widget ID

type SetActiveWidgetIdParams = {
    widgetId: ReportDesignerStoreStateType['activeWidgetId']
}

const setActiveWidgetId =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ widgetId }: SetActiveWidgetIdParams) => {
        storeUpdater((state) => {
            state.activeWidgetId = widgetId
            // when the active widget changes, all the secondary selected widgets should be cleared.
            state.secondarySelectedWidgetIds = []
            // Setting active widget ID to null should change settings view to slide.
            if (widgetId === null) {
                state.layoutSettings.settingsSidebar.view = 'slide'
            }
            // Otherwise, we should show the active widget settings.
            else {
                state.layoutSettings.settingsSidebar.view = 'widget'
            }
        })
    }

type UpdateSecondarySelectedWidgetParams = {
    widgetId: string | number
}

const updateSecondarySelectedWidget =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ widgetId }: UpdateSecondarySelectedWidgetParams) => {
        storeUpdater((state) => {
            if (state.activeWidgetId !== null && state.activeWidgetId !== widgetId) {
                if (state.secondarySelectedWidgetIds.includes(widgetId)) {
                    state.secondarySelectedWidgetIds = state.secondarySelectedWidgetIds.filter((id) => id !== widgetId)
                } else {
                    state.secondarySelectedWidgetIds.push(widgetId)
                }
            }
        })
    }

//* Report copy source

type UpdateCopySourceParams = {
    data: Partial<ReportCopySourceType>
}

const updateCopySource =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ data }: UpdateCopySourceParams) => {
        storeUpdater((state) => {
            assign(state.copySource, data)
        })
    }

//* Report custom views

type UpdateCustomViewsParams = {
    views: ReportCustomViewType[]
}

const updateCustomViews =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ views }: UpdateCustomViewsParams) => {
        storeUpdater((state) => {
            state.customViews = structuredClone(views)
        })
    }

//* Report report access

type UpdateReportAccessParams = {
    data: ReportShareType[]
}

const updateReportAccess =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ data }: UpdateReportAccessParams) => {
        storeUpdater((state) => {
            state.reportAccess = structuredClone(data)
        })
    }

/*
 * Slide actions
 * ========================================= */

//* Add slide

type AddSlideParams = {
    slide: ReportSlideType
    insertionPosition?: number
}

const addSlide =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slide, insertionPosition }: AddSlideParams) => {
        storeUpdater((state) => {
            const newSlide = structuredClone(slide)

            //* Slide background

            // If the new slide hasn't configured its own "backgroundColor", we use the report's master styles.
            if (newSlide.styles.backgroundColor.isEnabled === false) {
                newSlide.styles.backgroundColor.color = state.masterSettings.backgroundColor.color
            }

            //* Slide header

            // Enable slide's header if report has enabled master header and slide has the initial value for "isEnabled", i.e. "null".
            if (state.masterSettings.header.isEnabled && newSlide.header.isEnabled === null) {
                newSlide.header.isEnabled = true
            }

            // If the new slide hasn't configured its own header settings, we use the report's master header settings.
            if (newSlide.header.isOverridden === false) {
                const { isEnabled, ...reportHeaderSettings } = state.masterSettings.header

                deepMergeCustom(newSlide.header, reportHeaderSettings)
            }

            if (insertionPosition !== undefined && insertionPosition >= 0) {
                state.slides.splice(insertionPosition, 0, newSlide)
            } else {
                state.slides.push(newSlide)
            }

            const index = state.slides.findIndex((_slide) => _slide.id === newSlide.id)

            addToHistory(
                state.history,
                { type: 'slide', action: 'add', slideId: newSlide.id, index, data: cloneDeep(newSlide) },
                'Add slide'
            )

            // Whenever a new slide is added, it should be activated and the settings
            // sidebar should show its settings.
            state.layoutSettings.settingsSidebar.view = 'slide'
            state.activeSlideId = newSlide.id
            // We should also clear the active widget ID because if we are explicitly
            // trying to "set active slide", then we mean to start from that new slide's
            // view and a new slide has no selected or "active" widgets.
            state.activeWidgetId = null
            state.secondarySelectedWidgetIds = []
        })
    }

//* Remove slide

type RemoveSlideParams = {
    slideId: ReportSlideType['id']
}

const removeSlide =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId }: RemoveSlideParams) => {
        storeUpdater((state) => {
            const targetIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetIndex !== -1) {
                // If the target slide is the active slide, we need to do additional work before removing.
                if (state.activeSlideId === slideId) {
                    // If the target slide is the first slide in the list, activate the next slide.
                    if (targetIndex === 0) {
                        state.activeSlideId = state.slides[1].id
                    }
                    // Otherwise, activate the previous slide.
                    else {
                        state.activeSlideId = state.slides[targetIndex - 1].id
                    }

                    // When removing an active slide, we should always clear the activeWidgetId because
                    // there can't be a case where a widget is selected from another slide.
                    state.activeWidgetId = null
                    state.secondarySelectedWidgetIds = []

                    // We should make sure to adjust the settings view to prevent
                    // "null view" for widget settings in case there was an active widget
                    // selected while we deleted the active slide.
                    state.layoutSettings.settingsSidebar.view = 'slide'
                }

                addToHistory(
                    state.history,
                    {
                        type: 'slide',
                        action: 'delete',
                        slideId,
                        index: targetIndex,
                        data: cloneDeep(state.slides[targetIndex]),
                    },
                    'Remove slide'
                )

                state.slides.splice(targetIndex, 1)
            }
        })
    }

//* Update slide

type UpdateSlideParams = {
    slideId: ReportSlideType['id']
    data: PartialDeep<Omit<ReportSlideType, 'id'>>
}

const updateSlide =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, data }: UpdateSlideParams) => {
        storeUpdater((state) => {
            const targetIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetIndex !== -1) {
                const currentSlide = cloneDeep(state.slides[targetIndex])
                deepMergeCustom(state.slides[targetIndex], data)
                addUpdateSlideToHistory({
                    currentSlide,
                    updatedSlide: state.slides[targetIndex],
                    history: state.history,
                })
            }
        })
    }

//* Update slide Variable

type UpdateSlideVariableParams = {
    slideId: ReportSlideType['id']
    data: {
        variableName: string
        variableValue: string | null
        toggle?: boolean
    }
}

const updateSlideVariable =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, data }: UpdateSlideVariableParams) => {
        storeUpdater((state) => {
            const targetIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetIndex !== -1) {
                const { variableName, variableValue, toggle = true } = data
                // If the variable is being toggled, we should check if it's already set to the same value
                if (toggle && state.slides[targetIndex].variables[variableName] === variableValue) {
                    state.slides[targetIndex].variables[variableName] = null
                } else {
                    state.slides[targetIndex].variables[variableName] = variableValue
                }
            }
        })
    }

//* Relocate slide

type RelocateSlideParams = {
    previousIndex: number
    newIndex: number
}

const relocateSlide =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ previousIndex, newIndex }: RelocateSlideParams) => {
        storeUpdater((state) => {
            const targetSlide = state.slides[previousIndex]

            if (targetSlide !== undefined) {
                state.slides.splice(previousIndex, 1)
                state.slides.splice(newIndex, 0, targetSlide)
            }
        })
    }

/*
 * Widget actions
 * ========================================= */

//* Add widget

type AddWidgetParams = {
    slideId: ReportSlideType['id']
    data: ReportWidgetType
}

const addWidget =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, data }: AddWidgetParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                state.slides[targetSlideIndex].widgets.push(structuredClone(data))

                addToHistory(
                    state.history,
                    {
                        type: 'widget',
                        action: 'add',
                        slideId,
                        widgetId: data.id,
                        index: state.slides[targetSlideIndex].widgets.length - 1,
                        data: cloneDeep(data),
                    },
                    `Add ${data.content.kind} widget`
                )

                // Whenever a new widget is added, it should be activated and the settings
                // sidebar should show its settings.
                state.activeWidgetId = data.id
                state.secondarySelectedWidgetIds = []
                state.layoutSettings.settingsSidebar.view = 'widget'
            }
        })
    }

//* Remove widget

type RemoveWidgetParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
}

const removeWidget =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId }: RemoveWidgetParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const recursiveRemoveIds = new Set<ReportWidgetType['id']>()

                // function to recursively find all the widgets that should be removed.
                const gatherWidgetsToRemove = (widgetIdToRemove: ReportWidgetType['id']) => {
                    recursiveRemoveIds.add(widgetIdToRemove)
                    const widgetToRemove = state.slides[targetSlideIndex].widgets.find(
                        (_widget) => _widget.id === widgetIdToRemove
                    )

                    // Check if it's a combined widget and gather its children's IDs recursively.
                    if (widgetToRemove && widgetToRemove.content.kind === 'combined') {
                        widgetToRemove.content.details.widgets.forEach((innerWidgetId) => {
                            gatherWidgetsToRemove(innerWidgetId.id)
                        })
                    }
                }

                // Gather IDs of all widgets that need to be removed.
                gatherWidgetsToRemove(widgetId)

                const historyAction: Array<HistoryActionItemType> = []

                for (const widgetIdToRemove of recursiveRemoveIds) {
                    const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                        (_widget) => _widget.id === widgetIdToRemove
                    )

                    if (targetWidgetIndex !== -1) {
                        historyAction.push({
                            type: 'widget',
                            action: 'delete',
                            slideId,
                            widgetId: widgetIdToRemove,
                            index: targetWidgetIndex,
                            data: cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex]),
                        })
                    }
                }

                const widgetTitle = state.slides[targetSlideIndex].widgets.find(
                    (_widget) => _widget.id === widgetId
                )?.title

                addToHistory(state.history, historyAction, `Remove ${widgetTitle} widget`)

                // Filter out the widgets to be removed.
                state.slides[targetSlideIndex].widgets = state.slides[targetSlideIndex].widgets.filter(
                    (_widget) => !recursiveRemoveIds.has(_widget.id)
                )

                // If the target widget is the active widget, we need to do additional work before removing.
                if (state.activeWidgetId === widgetId) {
                    state.activeWidgetId = null
                    state.secondarySelectedWidgetIds = []

                    // we should change the settings view to "slide" to prevent "null view" of
                    // widget settings.
                    state.layoutSettings.settingsSidebar.view = 'slide'
                }
            }
        })
    }

//* Toggle widget visibility

type ToggleWidgetDesinerHideParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
}

const toggleWidgetDesinerHide =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId }: ToggleWidgetDesinerHideParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    state.slides[targetSlideIndex].widgets[targetWidgetIndex].designerHide =
                        !state.slides[targetSlideIndex].widgets[targetWidgetIndex].designerHide
                }
            }
        })
    }

//* Update designer visibility of all widgets in a slide
type changeSlideWidgetsDesignerHideParams = {
    slideId: ReportSlideType['id']
    excludeWidgetIds?: ReportWidgetType['id'][]
    hide: boolean
}

const changeSlideWidgetsDesignerHide =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, excludeWidgetIds = [], hide }: changeSlideWidgetsDesignerHideParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                state.slides[targetSlideIndex].widgets.forEach((widget) => {
                    if (!excludeWidgetIds.includes(widget.id)) {
                        widget.designerHide = hide
                    }
                })
            }
        })
    }

//* Update widget

type UpdateWidgetParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: PartialDeep<Omit<ReportWidgetType, 'id'>>
}

const updateWidget =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data }: UpdateWidgetParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])

                    deepMergeCustom(state.slides[targetSlideIndex].widgets[targetWidgetIndex], structuredClone(data))
                    addUpdateWidgetToHistory({
                        widgets: [
                            { currentWidget, updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex] },
                        ],
                        history: state.history,
                        slideId,
                    })
                }
            }
        })
    }

//* Update widget layer

type UpdateWidgetLayerParams = {
    slideId: ReportSlideType['id']
    previousIndex: number
    newIndex: number
}

const updateWidgetLayer =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, previousIndex, newIndex }: UpdateWidgetLayerParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidget = state.slides[targetSlideIndex].widgets[previousIndex]
                if (targetWidget !== undefined) {
                    addToHistory(
                        state.history,
                        {
                            type: 'widget',
                            action: 'layer',
                            slideId,
                            widgetId: targetWidget.id,
                            index: previousIndex,
                        },
                        `Update ${targetWidget.title} widget layer`
                    )

                    state.slides[targetSlideIndex].widgets.splice(previousIndex, 1)
                    state.slides[targetSlideIndex].widgets.splice(newIndex, 0, targetWidget)
                }
            }
        })
    }

//* Update widget styles

type UpdateWidgetStylesParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    styles: PartialDeep<ReportWidgetStylesType>
}

const updateWidgetStyles =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, styles }: UpdateWidgetStylesParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                    deepMergeCustom(
                        state.slides[targetSlideIndex].widgets[targetWidgetIndex].styles,
                        structuredClone(styles)
                    )
                    addUpdateWidgetToHistory({
                        widgets: [
                            {
                                currentWidget,
                                updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                            },
                        ],
                        history: state.history,
                        slideId,
                    })
                }
            }
        })
    }

//* Update widget content

type UpdateWidgetContentParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: ReportWidgetContentType
}

const updateWidgetContent =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data }: UpdateWidgetContentParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )
                if (targetWidgetIndex !== -1) {
                    const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                    state.slides[targetSlideIndex].widgets[targetWidgetIndex].content = data
                    addUpdateWidgetToHistory({
                        widgets: [
                            { currentWidget, updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex] },
                        ],
                        history: state.history,
                        slideId,
                    })
                }
            }
        })
    }

//* combine widgets

type CombineWidgetsParams = {
    mode: CombinedWidgetType['mode']
}

/** //TODO List
 * 1. Refactor the combineWidgets function into smaller functions for better readability and maintainability.
 * 2. Performance optimization: the loops and some calculations can be optimized for better performance.
 **/
const combineWidgets =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ mode }: CombineWidgetsParams) => {
        storeUpdater((state) => {
            const historyWidgets: { currentWidget: ReportWidgetType; updatedWidget: ReportWidgetType }[] = []
            const widgetIdsToCombine = [...state.secondarySelectedWidgetIds, state.activeWidgetId].filter(
                (x) => x !== null
            ) as (string | number)[]
            const activeSlideId = state.activeSlideId

            if (activeSlideId !== null && widgetIdsToCombine.length > 1) {
                const activeSlide = state.slides.find((slide) => slide.id === activeSlideId)
                if (activeSlide === undefined) return

                const widgetsToCombine = activeSlide.widgets
                    .filter((widget) => widgetIdsToCombine.includes(widget.id))
                    .map((widget) => cloneDeep(widget))

                // create a combined widget
                let combinedWidget: ReportWidgetType

                if (mode === 'select') {
                    combinedWidget = {
                        ...widgetsToCombine[0],
                        id: uuidv4(),
                        content: {
                            kind: 'combined',
                            details: {
                                widgets: widgetIdsToCombine.map((id) => ({ id, icon: undefined, linkedWidgets: [] })),
                                selectedWidgetId: widgetsToCombine[0].id,
                                mode: 'select',
                                variant: 'drop-down',
                                iconOnly: false,
                                iconPosition: 'end',
                                fontStyle: {
                                    family: 'Poppins',
                                    size: 12,
                                    weight: 'normal',
                                    color: '#000000ff',
                                },
                                accordion: {
                                    enabled: true,
                                    closeOthers: false,
                                },
                                stackDirection: 'column',
                            },
                        },
                        title: 'Combined Widget',
                    }
                } else {
                    let combinedTop = Infinity,
                        combinedLeft = Infinity,
                        combinedBottom = 0,
                        combinedRight = 0
                    widgetsToCombine.forEach((widget) => {
                        const widgetDimensions = scaleUpDimensions(
                            widget.dimensions.width,
                            widget.dimensions.height,
                            activeSlide.dimensions.currentScale
                        )

                        const top = (parseWidgetPosition(widget.position.top) * activeSlide.dimensions.height) / 100
                        const left = (parseWidgetPosition(widget.position.left) * activeSlide.dimensions.width) / 100
                        const bottom = top + widgetDimensions.height
                        const right = left + widgetDimensions.width

                        combinedTop = Math.min(combinedTop, top)
                        combinedLeft = Math.min(combinedLeft, left)
                        combinedBottom = Math.max(combinedBottom, bottom)
                        combinedRight = Math.max(combinedRight, right)
                    })

                    // computer the relative position and dimensions of each widget
                    const position = {
                        top: ((combinedTop / activeSlide.dimensions.height) * 100).toFixed(2) + '%',
                        left: ((combinedLeft / activeSlide.dimensions.width) * 100).toFixed(2) + '%',
                    }
                    const dimensions = retrieveBaseDimensions(
                        combinedRight - combinedLeft,
                        combinedBottom - combinedTop,
                        activeSlide.dimensions.currentScale
                    )

                    widgetIdsToCombine.forEach((widgetId) => {
                        const widget = activeSlide.widgets.find((widget) => widget.id === widgetId)
                        if (widget === undefined) return
                        const top = (parseWidgetPosition(widget.position.top) * activeSlide.dimensions.height) / 100
                        const left = (parseWidgetPosition(widget.position.left) * activeSlide.dimensions.width) / 100

                        widget.position = {
                            top: (((top - combinedTop) / (combinedBottom - combinedTop)) * 100).toFixed(6) + '%',
                            left: (((left - combinedLeft) / (combinedRight - combinedLeft)) * 100).toFixed(6) + '%',
                        }

                        widget.dimensions = {
                            width: (widget.dimensions.width / dimensions.width) * 100,
                            height: (widget.dimensions.height / dimensions.height) * 100,
                        }
                    })

                    combinedWidget = {
                        ...defaultReportWidgetValues,
                        id: uuidv4(),
                        content: {
                            kind: 'combined',
                            details: {
                                widgets: widgetsToCombine.map(({ id }) => ({ id, icon: undefined, linkedWidgets: [] })),
                                selectedWidgetId: widgetsToCombine[0].id,
                                mode: 'group',
                            },
                        },
                        title: 'Combined Widget',
                        position,
                        dimensions,
                    }
                }

                //hide widgets from active slide and add them to history
                activeSlide.widgets.forEach((widget) => {
                    if (widgetIdsToCombine.includes(widget.id)) {
                        const _currentWidget = widgetsToCombine.find((_widget) => _widget.id === widget.id)!
                        widget.hide = true
                        historyWidgets.push({ currentWidget: _currentWidget, updatedWidget: widget })
                    }
                })

                // generate history to track the changes of the widgets that are being combined
                const widgetUpdateHistory =
                    addUpdateWidgetToHistory({
                        widgets: historyWidgets,
                        history: state.history,
                        slideId: activeSlideId,
                        updateHistory: false,
                    }) ?? []

                // add combined widget to history
                addToHistory(
                    state.history,
                    [
                        {
                            type: 'widget',
                            action: 'add',
                            slideId: activeSlideId,
                            widgetId: combinedWidget.id,
                            index: activeSlide.widgets.length - 1,
                            data: cloneDeep(combinedWidget),
                        },

                        ...widgetUpdateHistory,
                    ],
                    'Combine widgets'
                )

                // add combined widget to active slide
                activeSlide.widgets.push(combinedWidget)
            }
        })
    }

// * uncombine widgets
const unCombineWidgets = (storeUpdater: ReportDesignerStoreSetCallback) => () => {
    storeUpdater((state) => {
        const activeSlideId = state.activeSlideId
        const activeWidgetId = state.activeWidgetId
        const historyWidgets: { currentWidget: ReportWidgetType; updatedWidget: ReportWidgetType }[] = []

        if (activeSlideId !== null && activeWidgetId !== null) {
            const activeSlide = state.slides.find((slide) => slide.id === activeSlideId)
            if (activeSlide === undefined) return

            const activeWidget = activeSlide.widgets.find((widget) => widget.id === activeWidgetId)
            if (activeWidget === undefined) return

            if (activeWidget.content.kind === 'combined') {
                const widgetsToUnCombine = activeSlide.widgets.filter(
                    (widget) =>
                        activeWidget.content.kind === 'combined' &&
                        activeWidget.content.details.widgets.some(({ id: _id }) => _id === widget.id)
                )

                const isGrouped = activeWidget.content.details.mode === 'group'

                const baseDimension = getBaseDimensions(state.aspectRatio)

                widgetsToUnCombine.forEach((widget) => {
                    const _currentWidget = cloneDeep(widget)
                    widget.hide = false

                    // In the grouped widgets, the position and dimensions are relative to the parent widget, so we need to update them
                    // to be relative to the slide
                    if (isGrouped) {
                        const newDimensions = {
                            width: (activeWidget.dimensions.width * widget.dimensions.width) / 100,
                            height: (activeWidget.dimensions.height * widget.dimensions.height) / 100,
                        }

                        const newPosition = {
                            top:
                                parseWidgetPosition(activeWidget.position.top) +
                                (parseWidgetPosition(widget.position.top) * activeWidget.dimensions.height) /
                                    baseDimension.height,
                            left:
                                parseWidgetPosition(activeWidget.position.left) +
                                (parseWidgetPosition(widget.position.left) * activeWidget.dimensions.width) /
                                    baseDimension.width,
                        }

                        widget.dimensions = newDimensions
                        widget.position = {
                            top: newPosition.top + '%',
                            left: newPosition.left + '%',
                        }
                    }
                    historyWidgets.push({ currentWidget: _currentWidget, updatedWidget: widget })
                })

                // generate history to track the changes of the widgets that are being uncombined
                const widgetUpdateHistory =
                    addUpdateWidgetToHistory({
                        widgets: historyWidgets,
                        history: state.history,
                        slideId: activeSlideId,
                        updateHistory: false,
                    }) ?? []

                // update history to track the changes of the un combined action
                addToHistory(
                    state.history,
                    [
                        {
                            action: 'delete',
                            type: 'widget',
                            data: cloneDeep(activeWidget),
                            index: activeSlide.widgets.findIndex((widget) => widget.id === activeWidgetId),
                            slideId: activeSlideId,
                            widgetId: activeWidgetId,
                        },
                        ...widgetUpdateHistory,
                    ],
                    'Uncombine widgets'
                )

                activeSlide.widgets = activeSlide.widgets.filter((widget) => widget.id !== activeWidget.id)
            }
        }
    })
}

/*
 * Widget-specific actions
 * ========================================= */

//* Update text widget content

type UpdateTextWidgetContentParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: PartialDeep<TextWidgetType>
}

const updateTextWidgetContent =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data }: UpdateTextWidgetContentParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    if (state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.kind === 'text') {
                        const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])

                        deepMergeCustom(state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.details, data)

                        addUpdateWidgetToHistory({
                            widgets: [
                                {
                                    currentWidget,
                                    updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                                },
                            ],
                            history: state.history,
                            slideId,
                        })
                    }
                }
            }
        })
    }

//* Update image widget content

type UpdateImageWidgetContentParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: PartialDeep<ImageWidgetType>
}

const updateImageWidgetContent =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data }: UpdateImageWidgetContentParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    if (state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.kind === 'image') {
                        const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                        deepMergeCustom(state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.details, data)
                        addUpdateWidgetToHistory({
                            widgets: [
                                {
                                    currentWidget,
                                    updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                                },
                            ],
                            history: state.history,
                            slideId,
                        })
                    }
                }
            }
        })
    }

//* Update chart widget content

type UpdateChartWidgetContentParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: ChartWidgetType
    disableTracking?: boolean
}

const updateChartWidgetContent =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data, disableTracking = false }: UpdateChartWidgetContentParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    if (state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.kind === 'chart') {
                        const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                        state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.details = data
                        if (!disableTracking) {
                            addUpdateWidgetToHistory({
                                widgets: [
                                    {
                                        currentWidget,
                                        updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                                    },
                                ],
                                history: state.history,
                                slideId,
                            })
                        }
                    }
                }
            }
        })
    }

//* Update network widget content

type UpdateNetworkWidgetContentParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: NetworkWidgetType
    disableTracking?: boolean
}

const updateNetworkWidgetContent =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data, disableTracking = false }: UpdateNetworkWidgetContentParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    if (state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.kind === 'network') {
                        const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                        state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.details = data
                        if (!disableTracking) {
                            addUpdateWidgetToHistory({
                                widgets: [
                                    {
                                        currentWidget,
                                        updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                                    },
                                ],
                                history: state.history,
                                slideId,
                            })
                        }
                    }
                }
            }
        })
    }

//* Update filter widget content

type UpdateFilterWidgetContentParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: FilterWidgetType
    disableTracking?: boolean
}

const updateFilterWidgetContent =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data, disableTracking = false }: UpdateFilterWidgetContentParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    if (state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.kind === 'filter') {
                        const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                        state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.details = data
                        if (!disableTracking) {
                            addUpdateWidgetToHistory({
                                widgets: [
                                    {
                                        currentWidget,
                                        updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                                    },
                                ],
                                history: state.history,
                                slideId,
                            })
                        }
                    }
                }
            }
        })
    }

//* Update table widget content

type UpdateTableWidgetContentParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: TableWidgetType
    disableTracking?: boolean
}

const updateTableWidgetContent =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data, disableTracking = false }: UpdateTableWidgetContentParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    if (state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.kind === 'table') {
                        const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                        state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.details = data
                        if (!disableTracking) {
                            addUpdateWidgetToHistory({
                                widgets: [
                                    {
                                        currentWidget,
                                        updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                                    },
                                ],
                                history: state.history,
                                slideId,
                            })
                        }
                    }
                }
            }
        })
    }

type UpdateTableWidgetConfigParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: TableWidgetType['config']
}

const updateTableWidgetConfig =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data }: UpdateTableWidgetConfigParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    const content = state.slides[targetSlideIndex].widgets[targetWidgetIndex].content
                    if (content.kind === 'table') {
                        const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                        content.details.config = data
                        addUpdateWidgetToHistory({
                            widgets: [
                                {
                                    currentWidget,
                                    updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                                },
                            ],
                            history: state.history,
                            slideId,
                        })
                    }
                }
            }
        })
    }

//* Update insight widget content

type UpdateInsightWidgetContentParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: PartialDeep<InsightWidgetType>
}

const updateInsightWidgetContent =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data }: UpdateInsightWidgetContentParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    const content = state.slides[targetSlideIndex].widgets[targetWidgetIndex].content
                    if (content.kind === 'insight') {
                        const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                        deepMergeCustom(content.details, data)

                        // we need to handle the cached data separately because it's a complex object and we don't want to merge it.
                        if (data.cachedData !== undefined) {
                            content.details.cachedData = structuredClone(
                                data.cachedData
                            ) as InsightWidgetType['cachedData']
                        }
                        addUpdateWidgetToHistory({
                            widgets: [
                                {
                                    currentWidget,
                                    updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                                },
                            ],
                            history: state.history,
                            slideId,
                        })
                    }
                }
            }
        })
    }

//* Update AI widget content

type UpdateAIWidgetContentParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: PartialDeep<AIWidgetType>
}

const updateAIWidgetContent =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data }: UpdateAIWidgetContentParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    if (state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.kind === 'ai') {
                        const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                        deepMergeCustom(state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.details, data)
                        addUpdateWidgetToHistory({
                            widgets: [
                                {
                                    currentWidget,
                                    updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                                },
                            ],
                            history: state.history,
                            slideId,
                        })
                    }
                }
            }
        })
    }

//* Update video widget content

type UpdateVideoWidgetContentParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: PartialDeep<VideoWidgetType>
}

const updateVideoWidgetContent =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data }: UpdateVideoWidgetContentParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    if (state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.kind === 'video') {
                        const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                        deepMergeCustom(state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.details, data)
                        addUpdateWidgetToHistory({
                            widgets: [
                                {
                                    currentWidget,
                                    updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                                },
                            ],
                            history: state.history,
                            slideId,
                        })
                    }
                }
            }
        })
    }

//* Update info widget content

type UpdateInfoWidgetContentParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: PartialDeep<InfoWidgetType>
}

const updateInfoWidgetContent =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data }: UpdateInfoWidgetContentParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    if (state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.kind === 'info') {
                        const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                        deepMergeCustom(state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.details, data)
                        addUpdateWidgetToHistory({
                            widgets: [
                                {
                                    currentWidget,
                                    updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                                },
                            ],
                            history: state.history,
                            slideId,
                        })
                    }
                }
            }
        })
    }

//* Update key insights widget content
type UpdateKeyInsightsWidgetContentParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: PartialDeep<KeyInsightWidgetType>
}

const updateKeyInsightWidgetContent =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data }: UpdateKeyInsightsWidgetContentParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    if (state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.kind === 'keyInsight') {
                        const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                        deepMergeCustom(state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.details, data)
                        addUpdateWidgetToHistory({
                            widgets: [
                                {
                                    currentWidget,
                                    updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                                },
                            ],
                            history: state.history,
                            slideId,
                        })
                    }
                }
            }
        })
    }

//* Update panel widget content
type UpdatePanelWidgetContentParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: PartialDeep<PanelWidgetType>
}

const updatePanelWidgetContent =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data }: UpdatePanelWidgetContentParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    if (state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.kind === 'panel') {
                        const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                        deepMergeCustom(state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.details, data)
                        addUpdateWidgetToHistory({
                            widgets: [
                                {
                                    currentWidget,
                                    updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                                },
                            ],
                            history: state.history,
                            slideId,
                        })
                    }
                }
            }
        })
    }

type AddLayerToPanelWidgetParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: {
        name: string
        parent: string
        widgets: ReportWidgetType['id'][]
    }
}

const addLayerToPanelWidget =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data }: AddLayerToPanelWidgetParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)
            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )
                if (targetWidgetIndex !== -1) {
                    const content = state.slides[targetSlideIndex].widgets[targetWidgetIndex].content
                    if (content.kind === 'panel') {
                        const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                        const historyWidgets: { currentWidget: ReportWidgetType; updatedWidget: ReportWidgetType }[] =
                            []
                        const widgetsToAdd: ReportWidgetType['id'][] = []

                        for (const widgetId of data.widgets) {
                            const widget = state.slides[targetSlideIndex].widgets.find((x) => x.id === widgetId)
                            if (widget !== undefined && widget.hide !== true) {
                                const currentWidget = cloneDeep(widget)
                                widget.hide = true
                                historyWidgets.push({ currentWidget, updatedWidget: widget })
                                widgetsToAdd.push(widgetId)
                            }
                        }

                        content.details.views[data.name] = {
                            parent: data.parent,
                            widgets: widgetsToAdd,
                        }
                        historyWidgets.push({
                            currentWidget,
                            updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                        })

                        addUpdateWidgetToHistory({
                            widgets: historyWidgets,
                            history: state.history,
                            slideId,
                        })
                    }
                }
            }
        })
    }

type RemoveLayerFromPanelWidgetParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: {
        name: string
    }
}

const removeLayerFromPanelWidget =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data }: RemoveLayerFromPanelWidgetParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)
            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )
                if (targetWidgetIndex !== -1) {
                    const content = state.slides[targetSlideIndex].widgets[targetWidgetIndex].content
                    if (content.kind === 'panel') {
                        const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])

                        const historyWidgets: { currentWidget: ReportWidgetType; updatedWidget: ReportWidgetType }[] =
                            []

                        content.details.views[data.name].widgets.forEach((widgetId) => {
                            const widget = state.slides[targetSlideIndex].widgets.find((x) => x.id === widgetId)
                            if (widget !== undefined) {
                                const currentWidget = cloneDeep(widget)
                                widget.hide = false
                                historyWidgets.push({ currentWidget, updatedWidget: widget })
                            }
                        })
                        const { [data.name]: removedLayer, ...rest } = content.details.views
                        content.details.views = rest
                        historyWidgets.push({
                            currentWidget,
                            updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                        })
                        addUpdateWidgetToHistory({
                            widgets: historyWidgets,
                            history: state.history,
                            slideId,
                        })
                    }
                }
            }
        })
    }

type UpdatePanelLayerParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    layerName: string
    data: {
        name: string
        parent: string | null
        widgets: ReportWidgetType['id'][]
    }
}

const updatePanelLayer =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, layerName, data }: UpdatePanelLayerParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)
            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )
                if (targetWidgetIndex !== -1) {
                    const content = state.slides[targetSlideIndex].widgets[targetWidgetIndex].content
                    if (content.kind === 'panel') {
                        const historyWidgets: { currentWidget: ReportWidgetType; updatedWidget: ReportWidgetType }[] =
                            []

                        const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])

                        const widgetsToAdd = difference(data.widgets, content.details.views[layerName].widgets)
                        const widgetsToRemove = difference(content.details.views[layerName].widgets, data.widgets)

                        for (const widgetId of widgetsToAdd) {
                            const widget = state.slides[targetSlideIndex].widgets.find((x) => x.id === widgetId)
                            if (widget !== undefined && widget.hide !== true) {
                                const currentWidget = cloneDeep(widget)
                                widget.hide = true
                                historyWidgets.push({ currentWidget, updatedWidget: widget })
                            }
                        }

                        for (const widgetId of widgetsToRemove) {
                            const widget = state.slides[targetSlideIndex].widgets.find((x) => x.id === widgetId)
                            if (widget !== undefined) {
                                const currentWidget = cloneDeep(widget)
                                widget.hide = false
                                historyWidgets.push({ currentWidget, updatedWidget: widget })
                            }
                        }

                        content.details.views[data.name] = {
                            parent: data.parent,
                            widgets: data.widgets,
                        }

                        if (data.name !== layerName) delete content.details.views[layerName]

                        historyWidgets.push({
                            currentWidget,
                            updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                        })
                        addUpdateWidgetToHistory({
                            widgets: historyWidgets,
                            history: state.history,
                            slideId,
                        })
                    }
                }
            }
        })
    }

type AddWidgetToPanelLayerParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    layerName: string
    data: {
        widgetId: string
    }
}

const addWidgetToPanelLayer =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, layerName, data }: AddWidgetToPanelLayerParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)
            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )
                if (targetWidgetIndex !== -1) {
                    const content = state.slides[targetSlideIndex].widgets[targetWidgetIndex].content
                    if (content.kind === 'panel') {
                        const widgetToAdd = state.slides[targetSlideIndex].widgets.find((x) => x.id === data.widgetId)

                        if (widgetToAdd !== undefined && widgetToAdd.hide !== true) {
                            const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                            const currentWidgetToAdd = cloneDeep(widgetToAdd)
                            widgetToAdd.hide = true
                            content.details.views[layerName].widgets.push(data.widgetId)
                            addUpdateWidgetToHistory({
                                widgets: [
                                    {
                                        currentWidget,
                                        updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                                    },
                                    { currentWidget: currentWidgetToAdd, updatedWidget: widgetToAdd },
                                ],
                                history: state.history,
                                slideId,
                            })
                        }
                    }
                }
            }
        })
    }

type RemoveWidgetFromPanelLayerParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    layerName: string
    data: {
        widgetId: string
    }
}

const removeWidgetFromPanelLayer =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, layerName, data }: RemoveWidgetFromPanelLayerParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)
            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )
                if (targetWidgetIndex !== -1) {
                    const content = state.slides[targetSlideIndex].widgets[targetWidgetIndex].content
                    if (content.kind === 'panel') {
                        const widgetToRemoveIndex = content.details.views[layerName].widgets.findIndex(
                            (x) => x === data.widgetId
                        )
                        if (widgetToRemoveIndex !== -1) {
                            const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                            const widgetToRemove = state.slides[targetSlideIndex].widgets.find(
                                (x) => x.id === data.widgetId
                            )
                            if (widgetToRemove !== undefined) {
                                const currentWidgetToRemove = cloneDeep(widgetToRemove)
                                widgetToRemove.hide = false
                                content.details.views[layerName].widgets.splice(widgetToRemoveIndex, 1)
                                addUpdateWidgetToHistory({
                                    widgets: [
                                        {
                                            currentWidget,
                                            updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                                        },
                                        {
                                            currentWidget: currentWidgetToRemove,
                                            updatedWidget: widgetToRemove,
                                        },
                                    ],
                                    history: state.history,
                                    slideId,
                                    name: 'Remove Panel Layer',
                                })
                            }
                        }
                    }
                }
            }
        })
    }

//* Update nav link widget content

type UpdateNavLinkWidgetContentParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: NavLinkWidgetType
}

const updateNavLinkWidgetContent =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data }: UpdateNavLinkWidgetContentParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    if (state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.kind === 'navLink') {
                        const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                        state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.details = data
                        addUpdateWidgetToHistory({
                            widgets: [
                                {
                                    currentWidget,
                                    updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                                },
                            ],
                            history: state.history,
                            slideId,
                        })
                    }
                }
            }
        })
    }

//* Update Dynamic Control widget content

type UpdateDynamicControlWidgetContentParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: DynamicControlWidgetType
}

const updateDynamicControlWidgetContent =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data }: UpdateDynamicControlWidgetContentParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    if (state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.kind === 'dynamicControl') {
                        const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                        state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.details = data
                        addUpdateWidgetToHistory({
                            widgets: [
                                {
                                    currentWidget,
                                    updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                                },
                            ],
                            history: state.history,
                            slideId,
                        })
                    }
                }
            }
        })
    }

//* Update horizontal line widget content

type UpdateHorizontalLineWidgetContentParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: PartialDeep<HorizontalLineWidgetType>
}

const updateHorizontalLineWidgetContent =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data }: UpdateHorizontalLineWidgetContentParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    if (state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.kind === 'horizontalLine') {
                        const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                        deepMergeCustom(state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.details, data)
                        addUpdateWidgetToHistory({
                            widgets: [
                                {
                                    currentWidget,
                                    updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                                },
                            ],
                            history: state.history,
                            slideId,
                        })
                    }
                }
            }
        })
    }

//* Update vertical line widget content

type UpdateVerticalLineWidgetContentParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: PartialDeep<VerticalLineWidgetType>
}

const updateVerticalLineWidgetContent =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data }: UpdateVerticalLineWidgetContentParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    if (state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.kind === 'verticalLine') {
                        const currentWidget = cloneDeep(state.slides[targetSlideIndex].widgets[targetWidgetIndex])
                        deepMergeCustom(state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.details, data)
                        addUpdateWidgetToHistory({
                            widgets: [
                                {
                                    currentWidget,
                                    updatedWidget: state.slides[targetSlideIndex].widgets[targetWidgetIndex],
                                },
                            ],
                            history: state.history,
                            slideId,
                        })
                    }
                }
            }
        })
    }

//* Update Combined widget content

type UpdateCombinedWidgetContentParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: PartialDeep<CombinedWidgetType>
}

const updateCombinedWidgetContent =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data }: UpdateCombinedWidgetContentParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    const widget = state.slides[targetSlideIndex].widgets[targetWidgetIndex]
                    if (widget.content.kind === 'combined') {
                        // If the selectedWidgetId is changed, we should also update the linked combined widget selected widget id
                        if (
                            data.selectedWidgetId !== undefined &&
                            data.selectedWidgetId !== widget.content.details.selectedWidgetId
                        ) {
                            const linkedWidgets = widget.content.details.widgets.find(
                                ({ id }) => id === data.selectedWidgetId
                            )?.linkedWidgets
                            if (linkedWidgets !== undefined) {
                                linkedWidgets.forEach((item) => {
                                    const linkedWidget = state.slides[targetSlideIndex].widgets.find(
                                        (_widget) => _widget.id === item.id
                                    )
                                    if (linkedWidget !== undefined && linkedWidget.content.kind === 'combined') {
                                        linkedWidget.content.details.selectedWidgetId = item.tab
                                    }
                                })
                            }
                        }
                        deepMergeCustom(state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.details, data)
                    }
                }
            }
        })
    }

//* Update the order of inner widgets in a combined widget

type UpdateCombinedWidgetInnerWidgetsOrderParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: {
        dragId: string | number
        newIndex: number
    }
}

const updateCombinedWidgetInnerWidgetsOrder =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data }: UpdateCombinedWidgetInnerWidgetsOrderParams) => {
        storeUpdater((state) => {
            const { dragId, newIndex } = data
            const targetSlide = state.slides.find((_slide) => _slide.id === slideId)
            if (!targetSlide) return

            const targetWidget = targetSlide.widgets.find((_widget) => _widget.id === widgetId)
            if (!targetWidget || targetWidget.content.kind !== 'combined') return

            const widgets = targetWidget.content.details.widgets
            const dragIndex = widgets.findIndex(({ id }) => id === dragId)

            // Validate indices and ensure they are different to proceed with reordering
            if (dragIndex === -1 || dragIndex === newIndex) return

            // Reorder widgets
            const [dragWidget] = widgets.splice(dragIndex, 1)
            widgets.splice(newIndex, 0, dragWidget)
        })
    }

//* Add Widget filter

type AddWidgetFilterParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    data: FilterRecordType
}

const addWidgetFilter =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, data }: AddWidgetFilterParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === widgetId
                )

                if (targetWidgetIndex !== -1) {
                    const targetWidget = state.slides[targetSlideIndex].widgets[targetWidgetIndex]

                    if (
                        ['chart', 'network', 'table'].includes(targetWidget.content.kind) &&
                        'filters' in targetWidget.content.details
                    ) {
                        // Reset selected node/row on data filter change
                        if (targetWidget.content.kind === 'network') {
                            ;(
                                state.slides[targetSlideIndex].widgets[targetWidgetIndex].content
                                    .details as NetworkWidgetType
                            ).selectedNode = null
                        } else if (targetWidget.content.kind === 'table') {
                            ;(
                                state.slides[targetSlideIndex].widgets[targetWidgetIndex].content
                                    .details as TableWidgetType
                            ).selectedRowId = null
                        }

                        targetWidget.content.details.filters = {
                            ...targetWidget.content.details.filters,
                            ...data,
                        }
                    }
                }
            }
        })
    }

//* Remove Widget filter

type RemoveWidgetFilterParams = {
    slideId: ReportSlideType['id']
    widgetId: ReportWidgetType['id']
    filterWidgetId: ReportWidgetType['id']
}

const removeWidgetFilter =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, widgetId, filterWidgetId }: RemoveWidgetFilterParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetWidgetIndex = state.slides[targetSlideIndex].widgets.findIndex(
                    (_widget) => _widget.id === filterWidgetId
                )

                if (targetWidgetIndex !== -1) {
                    const targetWidget = state.slides[targetSlideIndex].widgets[targetWidgetIndex]

                    if (['chart', 'network', 'table'].includes(targetWidget.content.kind)) {
                        const currentFilters =
                            'filters' in targetWidget.content.details ? targetWidget.content.details.filters : {}

                        // Reset selected node/row on data filter change
                        if (targetWidget.content.kind === 'network') {
                            ;(
                                state.slides[targetSlideIndex].widgets[targetWidgetIndex].content
                                    .details as NetworkWidgetType
                            ).selectedNode = null
                        } else if (targetWidget.content.kind === 'table') {
                            ;(
                                state.slides[targetSlideIndex].widgets[targetWidgetIndex].content
                                    .details as TableWidgetType
                            ).selectedRowId = null
                        }

                        ;(
                            state.slides[targetSlideIndex].widgets[targetWidgetIndex].content.details as
                                | ChartWidgetType
                                | NetworkWidgetType
                                | TableWidgetType
                        ).filters = {
                            ...currentFilters,
                            [widgetId]: null,
                        }
                    }
                }
            }
        })
    }

/*
 * Data source actions
 * ========================================= */

//* Add data source

type AddDataSourceParams = {
    dataSource: ReportDataSourceType
}

const addDataSource =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ dataSource }: AddDataSourceParams) => {
        storeUpdater((state) => {
            // Checking to see if the new data source already exists.
            const currentDataSourceIndex = state.dataSources.findIndex(
                (_dataSource) => _dataSource.id === dataSource.id
            )

            // Add the data source if it doesn't already exist
            if (currentDataSourceIndex === -1) {
                state.dataSources.push(dataSource)
            }
            // Update the state with the new data
            else {
                state.dataSources[currentDataSourceIndex] = dataSource
            }
        })
    }

//* Remove data source

type RemoveDataSourceParams = {
    dataSourceId: ReportDataSourceType['id']
}

const removeDataSource =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ dataSourceId }: RemoveDataSourceParams) => {
        storeUpdater((state) => {
            const targetIndex = state.dataSources.findIndex((_dataSource) => _dataSource.id === dataSourceId)

            if (targetIndex !== -1) {
                state.dataSources.splice(targetIndex, 1)
            }
        })
    }

//* Update data source

type UpdateDataSourceParams = {
    dataSourceId: ReportDataSourceType['id']
    // TODO: This part should not be Partial, but the entire object. Update this and the using component.
    data: Partial<ReportDataSourceType>
}

const updateDataSource =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ dataSourceId, data }: UpdateDataSourceParams) => {
        storeUpdater((state) => {
            const targetIndex = state.dataSources.findIndex((_dataSource) => _dataSource.id === dataSourceId)

            if (targetIndex !== -1) {
                assign(state.dataSources[targetIndex], data)
            }
        })
    }

/*
 * General actions
 * ========================================= */

//* Load report

type LoadReportParams = {
    responseData: ReportType
    viewMode: ReportDesignerStoreStateType['viewMode']
    reportId?: string | number
}

const loadReport =
    (storeUpdater: ReportDesignerStoreSetCallback, getState: ReportDesignerStoreGetCallback) =>
    async ({ responseData, viewMode, reportId }: LoadReportParams): Promise<void> => {
        // TODO: This code is temporary and fixes the "double loading" of report on initial page load.
        //       To fix the issue and remove this line, refactor the API requesting and fetching structure
        //       to allow adding "Abort Controllers" and then, abort the previous fetch request in the clean-up function
        //       of the effect that runs this code.
        if (getState().status !== 'pending') return

        try {
            let newState: Partial<ReportDesignerStoreStateType> = {}

            // If this is a new report with no slides saved from before, then
            // state should be undefined or null
            if (responseData.state === undefined || responseData.state === null) {
                // Design mode
                if (viewMode === 'design') {
                    const newSlideId = uuidv4()

                    newState = {
                        id: reportId,
                        title: responseData.title,
                        accessToken: responseData.accessToken || null,
                        slides: [
                            {
                                ...defaultReportSlideValues,
                                id: newSlideId,
                            },
                        ],
                        dataSources: [],
                        activeSlideId: newSlideId,
                        activeWidgetId: null,
                    }
                }
                // Preview mode
                else if (viewMode === 'preview') {
                    newState = {
                        status: 'faulty',
                        errorMessage: 'This report is empty!',
                    }

                    storeUpdater((state) => {
                        assign(state, newState)
                    })

                    return Promise.reject('This report is empty!')
                }
            }
            // If there are already some saved data on the server:
            else {
                const worker = new WebWorker<ReportSavedStateType>('workers/network/zip-worker.js')

                let rawReportState: ReportSavedStateType

                //! TEMPORARY SOLUTION
                // We use this method UNTIL all the previous reports have been MIGRATED
                // to the new zip state code.
                // Afterwards, remove this trycatch and only use the worker.
                try {
                    rawReportState = await worker.run({
                        mode: 'un-zip',
                        data: responseData.state,
                    })
                } catch (error) {
                    // the server will return the state in string format, which needs to be parsed
                    rawReportState = JSON.parse(responseData.state) as ReportSavedStateType
                }

                // un-zip worker might return null when unable to un-zip the data, this condtion is to handle that case
                if (rawReportState === null) {
                    // the server will return the state in string format, which needs to be parsed
                    rawReportState = JSON.parse(responseData.state) as ReportSavedStateType
                }

                // get the report version to preform migration if it's needed
                const { reportVersion, ...rawContextState } = rawReportState

                let upToDateReportState: Omit<ReportSavedStateType, 'reportVersion'>

                // Check if the version inside the saved state matches the current report version, and migrate if its needed
                if (reportVersion !== APP_VERSIONS.REPORT) {
                    upToDateReportState = migrateReportState(rawContextState, reportVersion)
                } else {
                    upToDateReportState = { ...rawContextState }
                }

                // upToDateReportState.slides = upToDateReportState.slides.map((_slide) => {
                //     const clonedSlide = structuredClone(_slide)
                //     clonedSlide.onboarding = []
                //     return clonedSlide
                // })

                //? [Preview mode code]
                // Based on the custom views, the user may not be able to see anything.
                // The server is filtering out slides with no widgets and also
                // slides or widgets which need to access a data source which is not shared.
                // Therefore, the slides array may be empty.
                if (viewMode === 'preview' && upToDateReportState.slides.length === 0) {
                    newState = {
                        status: 'faulty',
                        errorMessage: "No data available!\nYou may need to contact the report's creator.",
                    }

                    storeUpdater((state) => {
                        assign(state, newState)
                    })

                    return Promise.reject("No data available!\nYou may need to contact the report's creator.")
                }

                // create the new state
                newState = {
                    id: viewMode === 'design' ? reportId : responseData.primaryKey || -1,
                    title: responseData.title,
                    aspectRatio: upToDateReportState.aspectRatio,
                    masterSettings: upToDateReportState.masterSettings,
                    slides: [...upToDateReportState.slides],
                    dataSources: [...upToDateReportState.dataSources],
                    activeSlideId: upToDateReportState.slides[0].id,
                    widgetsToRender: upToDateReportState.slides[0].widgets
                        .filter((x) => x.hide !== true)
                        .map((widget) => widget.id),
                    activeWidgetId: null,
                    customViews:
                        responseData.reportCustomAccess == null
                            ? []
                            : responseData.reportCustomAccess.map((_access) => ({
                                  ..._access.state,
                                  title: _access.title,
                                  primaryKey: _access.primaryKey,
                              })),
                    reportAccess: responseData.reportAccess || [],
                    accessToken: responseData.accessToken || null,
                    tour: {
                        mode: 'slide',
                        isOpen: false,
                        step: 0,
                    },
                }

                // Function to process individual widgets
                async function processWidget(_widget: ReportWidgetType) {
                    if (_widget.content.kind === 'chart') {
                        const widgetDetails = { ..._widget.content.details } as ChartWidgetType

                        // Chart logic
                        if (widgetDetails.options !== null) {
                            widgetDetails.options = generateChartOptions({
                                type: widgetDetails.type,
                                mode: 'all',
                                prevOptions: null,
                                unfilteredData: getDataSourceData(
                                    newState.dataSources!,
                                    widgetDetails.selectedDataSource,
                                    getChartAttributes(widgetDetails.type, widgetDetails.dataDefinition)
                                ),
                                filteredData: getDataSourceData(
                                    newState.dataSources!,
                                    widgetDetails.selectedDataSource,
                                    getChartAttributes(widgetDetails.type, widgetDetails.dataDefinition),
                                    widgetDetails.filters
                                ),
                                dataDefinition: widgetDetails.dataDefinition,
                                config: widgetDetails.config,
                            })

                            _widget.content.details = structuredClone(widgetDetails)
                        }

                        return _widget
                    } else if (_widget.content.kind === 'insight') {
                        const widgetDetails = { ..._widget.content.details } as InsightWidgetType

                        // Insight logic
                        const selectedDataSource = newState.dataSources?.find(
                            (ds) => ds.id === widgetDetails.selectedDataSource?.id
                        )

                        if (selectedDataSource) {
                            widgetDetails.cachedData = await genereateInsightWidgetData(
                                selectedDataSource,
                                widgetDetails.mode,
                                widgetDetails.groupBy,
                                widgetDetails.insights,
                                widgetDetails.labelField,
                                widgetDetails.idField,
                                widgetDetails.selectedDataSource?.panel
                            )
                            _widget.content.details = structuredClone(widgetDetails)
                        }

                        return _widget
                    } else {
                        // Logic for other widget types, if any
                        return _widget
                    }
                }

                // Function to update a single slide
                async function updateSlide(_slide: ReportSlideType) {
                    // First, parse all table widgets
                    const interimWidgets = _slide.widgets.map((_widget) => {
                        if (_widget.content.kind === 'table') {
                            const tableDetails = { ..._widget.content.details } as TableWidgetType

                            tableDetails.parsedData = parseTableData({
                                filteredData:
                                    getDataSourceData(
                                        newState.dataSources!,
                                        tableDetails.selectedDataSource,
                                        getTableAttributes(tableDetails.dataDefinition),
                                        undefined,
                                        'compareWith' in tableDetails.dataDefinition
                                            ? tableDetails.dataDefinition.compareWith
                                            : null
                                    ) || [],
                                definition: tableDetails.dataDefinition,
                            })

                            _widget.content.details = structuredClone(tableDetails)

                            return _widget
                        } else {
                            return _widget
                        }
                    })

                    // initialize the selected row/node for the connected widgets
                    interimWidgets.forEach((_widget) => {
                        if (_widget.content.kind === 'table' && _widget.content.details.config.viewMode === 'card') {
                            const connectedWidgets = getConnectedWidgets({ id: _widget.id }, interimWidgets).filter(
                                (x) => x.id !== _widget.id
                            )
                            if (connectedWidgets.length > 0) {
                                const selectedItem =
                                    _widget.content.details.selectedRowId ??
                                    _widget.content.details.parsedData?.rows[0]?._internal_id
                                if (selectedItem) {
                                    _widget.content.details.selectedRowId = selectedItem

                                    for (const _connectedWidget of connectedWidgets) {
                                        // skip if the connected widget is the same as the source widget
                                        const _connectedWidgetId = _connectedWidget.id
                                        if (_connectedWidgetId === _widget.id) continue

                                        const targetWidget = interimWidgets.find((_w) => _w.id === _connectedWidgetId)
                                        if (targetWidget === undefined) continue

                                        // Use the updateWidget function here
                                        applySelectedItemChangeToWidgets(
                                            _connectedWidget,
                                            _widget,
                                            targetWidget,
                                            selectedItem,
                                            _widget.id,
                                            connectedWidgets,
                                            upToDateReportState.dataSources
                                        )
                                    }
                                }
                            }
                        }
                    })

                    const updatedWidgets = await Promise.all(interimWidgets.map(processWidget))

                    _slide.widgets = structuredClone(updatedWidgets)
                    return _slide
                }

                // Main function to update all slides
                async function updateSlides(slides: ReportSlideType[]) {
                    const updatedSlides = await Promise.all(slides.map(updateSlide))

                    // Now updatedSlides contains slides with fully processed widgets
                    return updatedSlides
                }

                newState.slides = await updateSlides(newState.slides!)
            }

            // Update store
            storeUpdater((state) => {
                // Final touch for the loaded state.
                newState = {
                    ...newState,
                    status: 'ready',
                    viewMode: viewMode,
                    layoutSettings: {
                        ...state.layoutSettings,
                        leftSidebar: {
                            ...state.layoutSettings.leftSidebar,
                            // For the preview mode, we increase the left sidebar's width.
                            width: viewMode === 'preview' ? 250 : state.layoutSettings.leftSidebar.width,
                        },
                    },
                }

                assign(state, newState)
            })

            return Promise.resolve()
        } catch (error) {
            // TODO: Log these errors in "development" env only.
            //      Also send errors to Sentry only on "production".
            console.error(error)

            storeUpdater((state) => {
                state.status = 'faulty'
                state.errorMessage = 'Failed to load report data!'
            })

            return Promise.reject('Failed to load report data!')
        }
    }

//* Update connected widgets

type UpdateConnectedWidgetsParams = {
    slideId: ReportSlideType['id']
    sourceWidgetId: ReportWidgetType['id']
    selectedItem: string | null
}

const updateConnectedWidgets =
    (storeUpdater: ReportDesignerStoreSetCallback) =>
    ({ slideId, sourceWidgetId, selectedItem }: UpdateConnectedWidgetsParams) => {
        storeUpdater((state) => {
            const targetSlideIndex = state.slides.findIndex((_slide) => _slide.id === slideId)

            if (targetSlideIndex !== -1) {
                const targetSlide = state.slides[targetSlideIndex]

                const sourceWidget = targetSlide.widgets.find((_widget) => _widget.id === sourceWidgetId)
                if (sourceWidget === undefined) return
                const connectedWidgetsIds = getConnectedWidgets({ id: sourceWidgetId }, targetSlide.widgets)

                switch (sourceWidget.content.kind) {
                    case 'table':
                        const tableDetails = sourceWidget.content.details as TableWidgetType
                        tableDetails.selectedRowId = selectedItem
                        break
                    case 'network':
                        const networkDetails = sourceWidget.content.details as NetworkWidgetType
                        networkDetails.selectedNode = selectedItem
                        break
                    case 'insight':
                        const insightDetails = sourceWidget.content.details as InsightWidgetType
                        insightDetails.selectedItem = selectedItem
                        break
                }

                for (let _connectedWidget of connectedWidgetsIds) {
                    const _connectedWidgetId = _connectedWidget.id
                    const targetConnectedWidgetIndex = targetSlide.widgets.findIndex(
                        (_widget: ReportWidgetType) => _widget.id === _connectedWidgetId
                    )

                    if (targetConnectedWidgetIndex !== -1) {
                        applySelectedItemChangeToWidgets(
                            _connectedWidget,
                            sourceWidget,
                            targetSlide.widgets[targetConnectedWidgetIndex],
                            selectedItem,
                            sourceWidgetId,
                            connectedWidgetsIds,
                            state.dataSources
                        )
                    }
                }
            }
        })
    }

//* Update tour
type UpdateReportTourParams = { data: Partial<ReportTourType> }

const updateReportTour = (storeUpdater: ReportDesignerStoreSetCallback) => (data: UpdateReportTourParams) => {
    storeUpdater((state) => {
        state.tour = {
            ...state.tour,
            ...data.data,
        }
    })
}

type UpdateReportTourStepParams = { data: 'next' | 'prev' }

const updateReportTourStep = (storeUpdater: ReportDesignerStoreSetCallback) => (data: UpdateReportTourStepParams) => {
    storeUpdater((state) => {
        state.tour = {
            ...state.tour,
            step: data.data === 'next' ? state.tour.step + 1 : state.tour.step - 1,
        }
    })
}

//* History actions

//* Undo
const undo = (storeUpdater: ReportDesignerStoreSetCallback) => () => {
    storeUpdater((state) => {
        if (state.history.past.length > 0) {
            const previous = state.history.past[state.history.past.length - 1]
            state.history.past.pop()
            const futureAction = perfromHistoryAction(state, previous, 'undo')
            if (futureAction !== null) state.history.future.push(futureAction)
        }
    })
}

//* Redo
const redo = (storeUpdater: ReportDesignerStoreSetCallback) => () => {
    storeUpdater((state) => {
        if (state.history.future.length > 0) {
            const previous = state.history.future[state.history.future.length - 1]
            state.history.future.pop()
            const futureAction = perfromHistoryAction(state, previous, 'redo')
            if (futureAction !== null) state.history.past.push(futureAction)
        }
    })
}

//* Clear history
const clearHistory = (storeUpdater: ReportDesignerStoreSetCallback) => () => {
    storeUpdater((state) => {
        state.history.past = []
        state.history.future = []
    })
}

/*
 * Type and exports
 * ========================================= */

export type ReportDesignerStoreActionsType = {
    //* Report
    resetReport: () => void
    updateReport: ({ data }: UpdateReportParams) => void
    updateStatus: ({ status, errorMessage }: UpdateStatus) => void
    updateTitle: ({ title }: UpdateTitleParams) => void
    updateViewMode: ({ mode }: UpdateViewModeParams) => void
    updateAspectRatio: ({ ratio }: UpdateAspectRatioParams) => void
    updateMasterSettings: ({ settings }: UpdateMasterSettingsParams) => void
    updateBackgroundColor: ({ styles }: UpdateBackgroundColorParams) => void
    updateHeader: ({ settings }: UpdateHeaderParams) => void
    updateLayoutLeftSidebar: ({ settings }: UpdateLayoutLeftSidebarParams) => void
    updateLayoutSettingsSidebar: ({ settings }: UpdateLayoutSettingsSidebarParams) => void
    updateLayoutSettingsSidebarView: ({ view }: UpdateLayoutSettingsSidebarViewParams) => void
    setActiveSlideId: ({ slideId }: SetActiveSlideIdParams) => void
    onWidgetReady: ({ widgetId }: OnWidgetReadyProps) => void
    setActiveWidgetId: ({ widgetId }: SetActiveWidgetIdParams) => void
    updateSecondarySelectedWidget: ({ widgetId }: UpdateSecondarySelectedWidgetParams) => void
    updateCopySource: ({ data }: UpdateCopySourceParams) => void
    updateCustomViews: ({ views }: UpdateCustomViewsParams) => void
    updateReportAccess: ({ data }: UpdateReportAccessParams) => void
    updateReportTour: ({ data }: UpdateReportTourParams) => void
    updateReportTourStep: ({ data }: UpdateReportTourStepParams) => void
    //* Slide
    addSlide: ({ slide, insertionPosition }: AddSlideParams) => void
    removeSlide: ({ slideId }: RemoveSlideParams) => void
    updateSlide: ({ slideId, data }: UpdateSlideParams) => void
    updateSlideVariable: ({ slideId, data }: UpdateSlideVariableParams) => void
    relocateSlide: ({ previousIndex, newIndex }: RelocateSlideParams) => void
    //* Widget
    addWidget: ({ slideId, data }: AddWidgetParams) => void
    removeWidget: ({ slideId, widgetId }: RemoveWidgetParams) => void
    toggleWidgetDesignerHide: ({ slideId, widgetId }: ToggleWidgetDesinerHideParams) => void
    updateSlideWidgetsDesignerHide: ({ slideId, hide, excludeWidgetIds }: changeSlideWidgetsDesignerHideParams) => void
    updateWidget: ({ slideId, widgetId, data }: UpdateWidgetParams) => void
    updateWidgetLayer: ({ slideId, previousIndex, newIndex }: UpdateWidgetLayerParams) => void
    updateWidgetStyles: ({ slideId, widgetId, styles }: UpdateWidgetStylesParams) => void
    updateWidgetContent: ({ slideId, widgetId, data }: UpdateWidgetContentParams) => void
    combineWidgets: ({ mode }: CombineWidgetsParams) => void
    unCombineWidgets: () => void
    //* Widget-specific
    updateTextWidgetContent: ({ slideId, widgetId, data }: UpdateTextWidgetContentParams) => void
    updateImageWidgetContent: ({ slideId, widgetId, data }: UpdateImageWidgetContentParams) => void
    updateChartWidgetContent: ({ slideId, widgetId, data, disableTracking }: UpdateChartWidgetContentParams) => void
    updateNetworkWidgetContent: ({ slideId, widgetId, data, disableTracking }: UpdateNetworkWidgetContentParams) => void
    updateFilterWidgetContent: ({ slideId, widgetId, data, disableTracking }: UpdateFilterWidgetContentParams) => void
    updateTableWidgetContent: ({ slideId, widgetId, data, disableTracking }: UpdateTableWidgetContentParams) => void
    updateTableWidgetConfig: ({ slideId, widgetId, data }: UpdateTableWidgetConfigParams) => void
    updateInsightWidgetContent: ({ slideId, widgetId, data }: UpdateInsightWidgetContentParams) => void
    updateAIWidgetContent: ({ slideId, widgetId, data }: UpdateAIWidgetContentParams) => void
    updateVideoWidgetContent: ({ slideId, widgetId, data }: UpdateVideoWidgetContentParams) => void
    updateInfoWidgetContent: ({ slideId, widgetId, data }: UpdateInfoWidgetContentParams) => void
    updateKeyInsightWidgetContent: ({ slideId, widgetId, data }: UpdateKeyInsightsWidgetContentParams) => void
    updatePanelWidgetContent: ({ slideId, widgetId, data }: UpdatePanelWidgetContentParams) => void
    addLayerToPanelWidget: ({ slideId, widgetId, data }: AddLayerToPanelWidgetParams) => void
    removeLayerFromPanelWidget: ({ slideId, widgetId, data }: RemoveLayerFromPanelWidgetParams) => void
    addWidgetToPanelLayer: ({ slideId, widgetId, data }: AddWidgetToPanelLayerParams) => void
    removeWidgetFromPanelLayer: ({ slideId, widgetId, data }: RemoveWidgetFromPanelLayerParams) => void
    updatePanelLayer: ({ slideId, widgetId, data }: UpdatePanelLayerParams) => void
    updateNavLinkWidgetContent: ({ slideId, widgetId, data }: UpdateNavLinkWidgetContentParams) => void
    updateDynamicControlWidgetContent: ({ slideId, widgetId, data }: UpdateDynamicControlWidgetContentParams) => void
    updateHorizontalLineWidgetContent: ({ slideId, widgetId, data }: UpdateHorizontalLineWidgetContentParams) => void
    updateVerticalLineWidgetContent: ({ slideId, widgetId, data }: UpdateVerticalLineWidgetContentParams) => void
    addWidgetFilter: ({ slideId, widgetId, data }: AddWidgetFilterParams) => void
    updateCombinedWidgetContent: ({ slideId, widgetId, data }: UpdateCombinedWidgetContentParams) => void
    updateCombinedWidgetInnerWidgetsOrder: ({
        slideId,
        widgetId,
        data,
    }: UpdateCombinedWidgetInnerWidgetsOrderParams) => void
    removeWidgetFilter: ({ slideId, widgetId, filterWidgetId }: RemoveWidgetFilterParams) => void
    //* Data source
    addDataSource: ({ dataSource }: AddDataSourceParams) => void
    removeDataSource: ({ dataSourceId }: RemoveDataSourceParams) => void
    updateDataSource: ({ dataSourceId, data }: UpdateDataSourceParams) => void
    //* General
    loadReport: ({ reportId, responseData, viewMode }: LoadReportParams) => Promise<void>
    updateConnectedWidgets: ({ slideId, sourceWidgetId, selectedItem }: UpdateConnectedWidgetsParams) => void
    //* history tracking
    undo: () => void
    redo: () => void
    clearHistory: () => void
}

export default function reportStoreActionsCreator(
    initialState: ReportDesignerStoreStateType,
    storeUpdater: ReportDesignerStoreSetCallback,
    getState: ReportDesignerStoreGetCallback
): ReportDesignerStoreActionsType {
    return {
        //* Report
        resetReport: resetReport(storeUpdater, initialState),
        updateReport: updateReport(storeUpdater),
        updateStatus: updateStatus(storeUpdater),
        updateTitle: updateTitle(storeUpdater),
        updateViewMode: updateViewMode(storeUpdater),
        updateAspectRatio: updateAspectRatio(storeUpdater),
        updateMasterSettings: updateMasterSettings(storeUpdater),
        updateBackgroundColor: updateBackgroundColor(storeUpdater),
        updateHeader: updateHeader(storeUpdater),
        updateLayoutLeftSidebar: updateLayoutLeftSidebar(storeUpdater),
        updateLayoutSettingsSidebar: updateLayoutSettingsSidebar(storeUpdater),
        updateLayoutSettingsSidebarView: updateLayoutSettingsSidebarView(storeUpdater),
        setActiveSlideId: setActiveSlideId(storeUpdater),
        onWidgetReady: onWidgetReady(storeUpdater),
        setActiveWidgetId: setActiveWidgetId(storeUpdater),
        updateSecondarySelectedWidget: updateSecondarySelectedWidget(storeUpdater),
        updateCopySource: updateCopySource(storeUpdater),
        updateCustomViews: updateCustomViews(storeUpdater),
        updateReportAccess: updateReportAccess(storeUpdater),
        updateReportTour: updateReportTour(storeUpdater),
        updateReportTourStep: updateReportTourStep(storeUpdater),
        updateKeyInsightWidgetContent: updateKeyInsightWidgetContent(storeUpdater),
        //* Slide
        addSlide: addSlide(storeUpdater),
        removeSlide: removeSlide(storeUpdater),
        updateSlide: updateSlide(storeUpdater),
        updateSlideVariable: updateSlideVariable(storeUpdater),
        relocateSlide: relocateSlide(storeUpdater),
        //* Widget
        addWidget: addWidget(storeUpdater),
        removeWidget: removeWidget(storeUpdater),
        toggleWidgetDesignerHide: toggleWidgetDesinerHide(storeUpdater),
        updateSlideWidgetsDesignerHide: changeSlideWidgetsDesignerHide(storeUpdater),
        updateWidget: updateWidget(storeUpdater),
        updateWidgetLayer: updateWidgetLayer(storeUpdater),
        updateWidgetStyles: updateWidgetStyles(storeUpdater),
        updateWidgetContent: updateWidgetContent(storeUpdater),
        combineWidgets: combineWidgets(storeUpdater),
        unCombineWidgets: unCombineWidgets(storeUpdater),
        //* Widget-specific
        updateTextWidgetContent: updateTextWidgetContent(storeUpdater),
        updateImageWidgetContent: updateImageWidgetContent(storeUpdater),
        updateChartWidgetContent: updateChartWidgetContent(storeUpdater),
        updateNetworkWidgetContent: updateNetworkWidgetContent(storeUpdater),
        updateFilterWidgetContent: updateFilterWidgetContent(storeUpdater),
        updateTableWidgetContent: updateTableWidgetContent(storeUpdater),
        updateTableWidgetConfig: updateTableWidgetConfig(storeUpdater),
        updateInsightWidgetContent: updateInsightWidgetContent(storeUpdater),
        updateAIWidgetContent: updateAIWidgetContent(storeUpdater),
        updateVideoWidgetContent: updateVideoWidgetContent(storeUpdater),
        updateInfoWidgetContent: updateInfoWidgetContent(storeUpdater),
        updatePanelWidgetContent: updatePanelWidgetContent(storeUpdater),
        addLayerToPanelWidget: addLayerToPanelWidget(storeUpdater),
        removeLayerFromPanelWidget: removeLayerFromPanelWidget(storeUpdater),
        addWidgetToPanelLayer: addWidgetToPanelLayer(storeUpdater),
        removeWidgetFromPanelLayer: removeWidgetFromPanelLayer(storeUpdater),
        updatePanelLayer: updatePanelLayer(storeUpdater),
        updateNavLinkWidgetContent: updateNavLinkWidgetContent(storeUpdater),
        updateDynamicControlWidgetContent: updateDynamicControlWidgetContent(storeUpdater),
        updateHorizontalLineWidgetContent: updateHorizontalLineWidgetContent(storeUpdater),
        updateVerticalLineWidgetContent: updateVerticalLineWidgetContent(storeUpdater),
        updateCombinedWidgetContent: updateCombinedWidgetContent(storeUpdater),
        updateCombinedWidgetInnerWidgetsOrder: updateCombinedWidgetInnerWidgetsOrder(storeUpdater),
        addWidgetFilter: addWidgetFilter(storeUpdater),
        removeWidgetFilter: removeWidgetFilter(storeUpdater),
        //* Data source
        addDataSource: addDataSource(storeUpdater),
        removeDataSource: removeDataSource(storeUpdater),
        updateDataSource: updateDataSource(storeUpdater),
        //* General
        loadReport: loadReport(storeUpdater, getState),
        updateConnectedWidgets: updateConnectedWidgets(storeUpdater),
        //* history tracking
        undo: undo(storeUpdater),
        redo: redo(storeUpdater),
        clearHistory: clearHistory(storeUpdater),
    }
}
