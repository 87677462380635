import React, { useEffect, useMemo } from 'react'
import { AccordionSummary, Divider, Accordion, Stack, Typography, useTheme, AccordionDetails } from '@mui/material'
import { KeyInsightWidgetType, ReportWidgetType } from 'features/report-designer/types/reportDesigner.types'
import LabelIcon from '@mui/icons-material/Label'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

type KeyInsightWidgetProps = {
    Id: ReportWidgetType['id']
    isActive: boolean
    data: KeyInsightWidgetType
    title: string
    onReady: () => void
    fontSizeScaleFactor: number
}

function KeyInsightWidget({
    Id,
    isActive,
    data,
    title,
    onReady,
    fontSizeScaleFactor: fontSizeScale,
}: KeyInsightWidgetProps) {
    useEffect(() => {
        onReady()
    }, [])

    const theme = useTheme()

    const [expanded, setExpanded] = React.useState<string | false>(false)

    return (
        <Stack
            sx={{
                height: '100%',
                position: 'relative',
            }}
            gap={1}
        >
            <Typography
                width="100%"
                sx={(theme) => ({
                    padding: theme.spacing(1, 1, 0),
                    color: '#2a6f97',
                    fontSize: 16 * fontSizeScale,
                    textAlign: 'left',
                })}
            >
                {title}
            </Typography>

            <Divider />

            {/* Content */}
            <Stack
                flexGrow={1}
                sx={{
                    overflowY: 'auto',
                    padding: theme.spacing(1),
                    maxHeight: '100%',
                }}
                className="u-scrollbar"
                gap={1}
            >
                {data.items.map((item, index) => (
                    <Accordion
                        key={index}
                        sx={{ borderRadius: 1, backgroundColor: '#E3EFFA', mb: 1 }}
                        expanded={expanded === item.id}
                        onChange={() => setExpanded(item.id === expanded ? false : item.id)}
                    >
                        {/* Accordion Summary */}
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                            sx={{
                                borderRadius: 1,
                                padding: theme.spacing(1),
                            }}
                        >
                            {/* title and text */}
                            <Typography
                                sx={{
                                    fontSize: 14 * fontSizeScale,
                                    fontWeight: 500,
                                    color: '#0074E0',
                                }}
                            >
                                {item.title}
                                <Typography
                                    component="span"
                                    sx={{
                                        fontSize: 13 * fontSizeScale,
                                        fontWeight: 400,
                                        color: '#5A607F',
                                    }}
                                >
                                    {` ${item.content}`}
                                </Typography>
                            </Typography>
                        </AccordionSummary>
                        {/* Accordion Details */}
                        <AccordionDetails sx={{ paddingX: theme.spacing(2) }}>
                            {/* chips */}
                            <Stack
                                direction="column"
                                gap={1}
                                sx={{
                                    padding: theme.spacing(1),
                                    borderLeft: '1px solid #66ACEC',
                                }}
                            >
                                {item.chips.map((chip, index) => (
                                    <Stack key={index} direction="row" gap={1} alignItems={'center'}>
                                        <LabelIcon sx={{ color: '#5A607F', fontSize: 12 * fontSizeScale }} />
                                        <Typography key={index} sx={{ fontSize: 12 * fontSizeScale, color: '#5A607F' }}>
                                            {chip}
                                        </Typography>
                                    </Stack>
                                ))}
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Stack>
        </Stack>
    )
}

export default KeyInsightWidget
