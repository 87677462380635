import React, { useEffect, useState } from 'react'
import useReportStore, { useReportActiveSlide } from '../store/reportDesignerStore'
import { ReportSlideType } from '../types/reportDesigner.types'
import { v4 as uuidv4 } from 'uuid'
import StyledDialog from 'components/dialog/StyledDialog'
import {
    Box,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    IconButton,
    Stack,
    Typography,
} from '@mui/material'
import BaseButton from 'components/base/BaseButton'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import DeleteIcon from '@mui/icons-material/Delete'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import TextEditor from 'features/text-editor/TextEditor'
import { StyledSwitch } from 'features/StyledComponents/StyledSwitch'
import { PartialDeep } from 'type-fest'

type OnboardingDialogType = {
    open: boolean
    onClose: () => void
}

const ReportOnboardingDialog: React.FC<OnboardingDialogType> = ({ open, onClose }) => {
    const { updateSlide } = useReportStore((store) => ({
        updateSlide: store.updateSlide,
    }))

    const activeSlide = useReportActiveSlide()

    const [internalState, setInternalState] = useState<ReportSlideType['onboarding']>()

    useEffect(() => {
        if (activeSlide !== null) {
            setInternalState(activeSlide.onboarding)
        }
    }, [activeSlide])

    const handleAddStep = () => {
        setInternalState((prevSteps) => {
            if (prevSteps === undefined) {
                return undefined
            }

            return {
                ...prevSteps,
                steps: [
                    ...prevSteps.steps,
                    { id: uuidv4(), component: [''], content: '', interactive: true, hideNextButton: false },
                ],
            }
        })
    }

    const handleDeleteStep = (id: String) => {
        setInternalState((prevSteps) => {
            if (prevSteps === undefined) {
                return undefined
            }

            return {
                ...prevSteps,
                steps: prevSteps.steps.filter((x) => x.id !== id),
            }
        })
    }

    const moveStep = (index: number, direction: 'up' | 'down') => {
        if (internalState === undefined) return

        setInternalState((prevSteps) => {
            if (prevSteps === undefined) {
                return undefined
            }
            const newState = structuredClone(prevSteps)

            if (direction === 'up' && index > 0) {
                ;[newState.steps[index], newState.steps[index - 1]] = [newState.steps[index - 1], newState.steps[index]]
            } else if (direction === 'down' && index < internalState.steps.length - 1) {
                ;[newState.steps[index], newState.steps[index + 1]] = [newState.steps[index + 1], newState.steps[index]]
            }

            return newState
        })
    }

    const updateStepSelector = (stepIndex: number, selectorIndex: number, value: string) => {
        setInternalState((prevSteps) => {
            if (prevSteps === undefined) {
                return undefined
            }

            const newState = structuredClone(prevSteps)
            const newComponents = []
            for (let i = 0; i < selectorIndex; i++) {
                newComponents.push(newState.steps[stepIndex].component[i])
            }
            newComponents.push(value)
            if (value.startsWith('#widget-')) {
                newComponents.push(' ')
            }

            newState.steps[stepIndex].component = structuredClone(newComponents)

            return newState
        })
    }

    const updateStep = (
        stepIndex: number,
        value: PartialDeep<Pick<ReportSlideType['onboarding']['steps'][number], 'interactive' | 'content'>>
    ) => {
        setInternalState((prevSteps) => {
            if (prevSteps === undefined) {
                return undefined
            }

            const newState = structuredClone(prevSteps)
            newState.steps[stepIndex] = {
                ...newState.steps[stepIndex],
                ...value,
            }

            return newState
        })
    }

    const handleSave = () => {
        if (activeSlide === null) return
        updateSlide({ slideId: activeSlide.id, data: { onboarding: internalState } })
        onClose()
    }

    const getComponentOptions = (widgetId: string | null) => {
        if (activeSlide === null) return []
        if (widgetId === null) {
            return [
                {
                    label: 'Slide',
                    value: `#slide-${activeSlide.id}`,
                },
                {
                    label: 'Slide Header',
                    value: `#slide-${activeSlide.id} .slide-header`,
                },
                {
                    label: 'Full Screen',
                    value: `.fullscreen-toggle-button`,
                },
                {
                    label: 'Navigation',
                    value: '.slide-navigation',
                },
                ...activeSlide.widgets
                    .filter((x) => x.hide !== true)
                    .map((widget) => ({
                        label: widget.title,
                        value: `#widget-${widget.id}`,
                    })),
            ]
        }
        const widget = activeSlide?.widgets.find((x) => x.id === widgetId.substring('#widget-'.length))
        if (widget === undefined) return []
        const options = [
            {
                label: 'Main',
                value: ' ',
            },
        ]
        switch (widget.content.kind) {
            case 'combined':
                if (widget.content.details.mode === 'select') {
                    options.push(
                        {
                            label: 'Tabs',
                            value: `.combined-widget-selector`,
                        },
                        ...widget.content.details.widgets.map((subWidget, index) => ({
                            label: activeSlide?.widgets.find((x) => x.id === subWidget.id)?.title ?? '',
                            value: `#widget-${subWidget.id}`,
                        }))
                    )
                }
                break
            case 'network':
                options.push(
                    {
                        label: 'Fit Network',
                        value: '.network-viz-fit-button',
                    },
                    {
                        label: 'Show Labels',
                        value: '.network-viz-labels-button',
                    },
                    {
                        label: 'Play Key',
                        value: '.network-viz-play-button',
                    }
                )
                if (widget.content.details.legend.edge || widget.content.details.legend.node) {
                    options.push({
                        label: 'Legend',
                        value: '.network-viz-legend',
                    })
                }
                if (widget.content.details.sizeScale.enabled) {
                    options.push({
                        label: 'Scale',
                        value: '.size-scale',
                    })
                }
                if (widget.content.details.displaySearchBar) {
                    options.push({
                        label: 'Search',
                        value: '.node-search',
                    })
                }
                break
            case 'table':
                options.push({
                    label: 'Search icon',
                    value: '.search-rows',
                })
                break
            case 'insight':
                if (widget.content.details.mode === 'intervention') {
                    options.push(
                        {
                            label: 'Tabs',
                            value: '.tabs',
                        },
                        {
                            label: 'impacted rows',
                            value: '.impacted-table',
                        },
                        {
                            label: 'description',
                            value: '.description',
                        },
                        {
                            label: 'actions',
                            value: '.actions',
                        }
                    )
                }
        }

        return options
    }

    return (
        <StyledDialog open={open} onClose={onClose} fullWidth maxWidth="xl">
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <div>Onboarding</div>
                    <BaseButton onClick={handleAddStep}>Add Step</BaseButton>
                </Stack>
            </DialogTitle>
            {activeSlide === null || internalState === undefined ? (
                <DialogContent>There is no active slide</DialogContent>
            ) : (
                <DialogContent sx={{ height: '80vh' }}>
                    <Stack gap={2}>
                        {internalState.steps.map((step, index) => (
                            <Stack key={step.id} gap={1}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                                    <Typography>Step {index + 1}</Typography>
                                    <Stack gap={1} flexGrow={1}>
                                        {step.component.map((component, stepIndex) => (
                                            <BaseSelectWithLabel
                                                label="Selector"
                                                fullWidth
                                                value={component}
                                                onChange={(value) => {
                                                    updateStepSelector(index, stepIndex, value as string)
                                                }}
                                                options={getComponentOptions(
                                                    stepIndex > 0
                                                        ? internalState.steps[index].component[stepIndex - 1]
                                                        : null
                                                )}
                                            />
                                        ))}

                                        <TextEditor
                                            defaultValue={step.content}
                                            height="100px"
                                            onBlur={(value: any) => {
                                                updateStep(index, { content: value })
                                            }}
                                        />
                                        <Stack direction="row" gap={1}>
                                            <FormControlLabel
                                                control={
                                                    <StyledSwitch
                                                        checked={step.interactive}
                                                        onChange={(e, checked) =>
                                                            updateStep(index, {
                                                                interactive: checked,
                                                            })
                                                        }
                                                    />
                                                }
                                                label="Interactive"
                                            />

                                            {/* <FormControlLabel
                                                control={
                                                    <StyledSwitch
                                                        checked={step.hideNextButton}
                                                        onChange={(e, checked) =>
                                                            updateStep(index, {
                                                                hideNextButton: checked,
                                                            })
                                                        }
                                                    />
                                                }
                                                label="Hide Next Button"
                                            /> */}
                                        </Stack>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <IconButton onClick={() => moveStep(index, 'up')} size="small">
                                            <ArrowUpwardIcon />
                                        </IconButton>
                                        <IconButton onClick={() => moveStep(index, 'down')} size="small">
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteStep(step.id)} size="small">
                                            <DeleteIcon />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                                <Divider />
                            </Stack>
                        ))}
                    </Stack>
                </DialogContent>
            )}
            <DialogActions>
                <BaseButton onClick={onClose}>Cancel</BaseButton>
                <BaseButton onClick={handleSave} color="primary" variant="contained">
                    Save
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}

export default ReportOnboardingDialog
