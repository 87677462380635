import {
    ReportSlideType,
    ReportWidgetType,
    TextWidgetType,
    ImageWidgetType,
    ChartWidgetType,
    VideoWidgetType,
    NetworkWidgetType,
    FilterWidgetType,
    TableWidgetType,
    InsightWidgetType,
    InfoWidgetType,
    NavLinkWidgetType,
    HorizontalLineWidgetType,
    VerticalLineWidgetType,
    AIWidgetType,
    DynamicControlWidgetType,
    PanelWidgetType,
    KeyInsightWidgetType,
} from 'features/report-designer/types/reportDesigner.types'
import { LineChartDefaultDataDefinition, LineChartDefaultConfig } from 'features/chart/Chart.asset'
import { NetworkVizInitialState } from 'features/network-viz/context/NetworkVizContext'
import {
    TableConfigGridViewDefaultValues,
    TableDefinitionEachRowDefaultValues,
} from 'features/report-designer/widgets/table-widget/helpers/TableWidget.asset'
import { v4 as uuidv4 } from 'uuid'

/*
 * Notes:
 * 	1.	These values should be used when creating corresponding components that use them.
 * 		Fields that are marked as "Placeholder" should immediately be overwritten with sensible values.
 * 		The point of having these default values is to make adding/creating components easier.
 */

/*
 * Slide
 * ========================================= */
export const defaultReportSlideValues: ReportSlideType = {
    // Placeholder: This property should be overwritten on initialization.
    id: -1,
    title: '',
    zoomLevel: 0,
    // Placeholder: This property should be overwritten on initialization.
    dimensions: {
        width: 1280,
        height: 720,
        currentScale: 1,
        offsetTop: 104,
        offsetLeft: 278,
    },
    styles: {
        // Placeholder: This property should be overwritten on initialization.
        backgroundColor: {
            isEnabled: false,
            color: undefined,
        },
    },
    widgets: [],
    // Placeholder: This property should be overwritten on initialization.
    header: {
        isEnabled: null,
        isOverridden: false,
        primaryText: '',
        height: 10,
    },
    // TODO: You could add a good-looking image as the initial thumbnail.
    thumbnail: '',
    onboarding: {
        steps: [],
        config: {
            finishBehavior: 'close',
            finishButtonText: 'Finish',
        },
    },
    variables: {},
}

/*
 * Text widget
 * ========================================= */
export const defaultTextWidgetValues: TextWidgetType = {
    value: '',
}

/*
 * Image widget
 * ========================================= */
export const defaultImageWidgetValues: ImageWidgetType = {
    status: 'ready',
    source: '',
    imageName: '',
    fit: 'contain',
}

/*
 * Chart widget
 * ========================================= */
export const defaultChartWidgetValues: ChartWidgetType = {
    selectedDataSource: null,
    filters: null,
    type: 'line',
    dataDefinition: LineChartDefaultDataDefinition,
    config: LineChartDefaultConfig,
    options: null,
}

/*
 * Video widget
 * ========================================= */
export const defaultVideoWidgetValues: VideoWidgetType = {
    source: '',
}

/*
 * Network widget
 * ========================================= */
export const defaultNetworkWidgetValues: NetworkWidgetType = {
    displayMode: '2d+',
    filters: {},
    shrinkMode: false,
    networkDatasourceId: -1,
    selectedPreset: 'default',
    displaySearchBar: false,
    legend: {
        edge: false,
        node: false,
    },
    sizeScale: {
        enabled: false,
        placement: 'left',
        fields: [],
        selectedField: null,
    },
    groupBy: {
        enabled: false,
        placement: 'left',
        fields: [],
        selectedField: null,
    },
    networkAnalyticsPanel: {
        show: false,
        components: {
            selectedNode: {
                title: 'Selected Node',
                show: false,
            },
            graph: {
                title: 'Graph Analytics',
                show: false,
            },
            top10: {
                title: 'Top 10',
                show: false,
                field: '',
                sort: 1,
            },
            gmc: {
                title: 'Greedy Modularity Communities',
                show: false,
            },
            scc: {
                title: 'Strongly Connected Components',
                show: false,
            },
            wcc: {
                title: 'Weakly Connected Components',
                show: false,
            },
        },
    },
    runLayoutAfterFilter: true,
    // ToDo - this should be selected item - migration is needed
    selectedNode: NetworkVizInitialState.selectedItem?.mode === 'node' ? NetworkVizInitialState.selectedItem.id : null,
    connectedWidgets: [],
    focusMode: false,
}

/*
 * Insight widget
 * ========================================= */
export const defaultInsightWidgetValues: InsightWidgetType = {
    groupInsights: false,
    insights: [],
    emptyDataMessage: 'No Insight',
    idField: null,
    labelField: null,
    mode: 'group',
    selectedDataSource: null,
    selectedItem: null,
    showTitle: false,
    version: 0,
    basicConfiguration: {
        hasHeader: false,
        canChangeRows: false,
        hasSearch: false,
        headerStyle: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 'bold',
            overflow: 'truncate',
            color: undefined,
        },
    },
    interventionConfig: {
        columnWidth: 60,
        showExport: false,
        showSearch: false,
        showActions: true,
        showDescription: true,
        iconPosition: 'end',
        iconOnly: false,
        layout: 'one-column',
        tableTitle: {
            enabled: true,
            text: 'Impacted Nodes',
            fontFamily: 'Poppins',
            fontSize: 12,
            fontWeight: 'bold',
            color: '#000000FF',
        },
        tabTextStyle: {
            fontFamily: 'Poppins',
            fontSize: 14,
            fontWeight: 'normal',
            color: '#000000FF',
        },
        categories: [],
        filterConfig: [],
        compareConfig: [],
        correlationConfig: [],
    },
    fontStyles: {
        fontFamily: 'Poppins',
        fontSize: 14,
    },
    cachedData: null,
}

/*
 * Dynamic control widget
 * ========================================= */
export const defaultDynamicControlWidgetValues: DynamicControlWidgetType = {
    variableName: null,
    variableValue: null,
    toggle: true,
    variant: 'contained',
    fontStyle: {
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeight: 'normal',
    },
    activeConfig: {
        enabled: true,
        color: '#217BBF',
        icon: 'check',
        label: 'Active',
    },
    nonActiveConfig: {
        color: '#eee',
        icon: 'close',
        label: 'Non-Active',
    },
}

/*
 * AI widget
 * ========================================= */
export const defaultAIWidgetValues: AIWidgetType = {
    groupInsights: false,
    prompts: [
        {
            id: uuidv4(),
            content: '',
            role: 'user',
        },
        {
            id: uuidv4(),
            content: '',
            role: 'assistant',
        },
    ],
    outcomes: null,
    emptyDataMessage: 'No Insight',
    dataWidgetId: -1,
    showTitle: false,
}

/*
 * Filter widget
 * ========================================= */
export const defaultFilterWidgetValues: FilterWidgetType = {
    selectedDataSource: {
        id: -1,
        datasourceType: 'static',
        filters: [],
    },
    field: {
        type: 'basic',
        field: '',
    },
    value: '',
    label: '',
    isMultiple: false,
    connectedWidgets: [],
    fontSize: 16,
}

/*
 * Table widget
 * ========================================= */
export const defaultTableWidgetValues: TableWidgetType = {
    selectedDataSource: null,
    filters: null,
    dataDefinition: TableDefinitionEachRowDefaultValues,
    parsedData: null,
    config: TableConfigGridViewDefaultValues,
    selectedRowId: null,
    connectedWidgets: [],
}

/*
 * Info widget
 * ========================================= */
export const defaultInfoWidgetValues: InfoWidgetType = {
    icon: {
        type: 'exclamation',
        size: 'medium',
        color: '#58585AFF', // theme secondary.main
    },
    text: {
        value: '(Additional info)',
        fontFamily: 'Poppins',
        fontSize: 12,
        color: undefined,
    },
    video: {
        source: '',
    },
    tooltip: {
        placement: 'bottom',
        shouldShowOnHover: true,
        backgroundColor: {
            isEnabled: false,
            color: undefined,
        },
    },
}

/*
 * Nav link widget
 * ========================================= */
export const defaultNavLinkWidgetValues: Record<NavLinkWidgetType['mode'], NavLinkWidgetType> = {
    internal: {
        mode: 'internal',
        targetSlideId: null,
        label: 'Nav Link',
        variant: 'outlined',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 14,
            fontWeight: 'normal',
            color: '#217BBF', // theme primary.main
        },
    },
    external: {
        mode: 'external',
        href: '',
        label: 'Nav Link',
        variant: 'outlined',
        styles: {
            fontFamily: 'Poppins',
            fontSize: 14,
            fontWeight: 'normal',
            color: '#217BBF', // theme primary.main
        },
    },
}

/*
 * Horizontal line widget
 * ========================================= */
export const defaultHorizontalLineWidgetValues: HorizontalLineWidgetType = {
    height: 1,
    color: '#808080FF',
}

/*
 * Vertical line widget
 * ========================================= */
export const defaultVerticalLineWidgetValues: VerticalLineWidgetType = {
    width: 1,
    color: '#808080FF',
}

/*
 * Panel widget
 * ========================================= */
export const defaultPanelWidgetValues: PanelWidgetType = {
    views: {
        root: {
            widgets: [],
            parent: null,
        },
    },
    variable: '',
    selectedWidgetId: null,
}

/*
 * Key Insight widget
 * ========================================= */
export const defaultKeyInsightWidgetValues: KeyInsightWidgetType = {
    items: [],
}
/*
 * Widget
 * ========================================= */
export const defaultReportWidgetValues: ReportWidgetType = {
    // Placeholder: This property should be overwritten on initialization.
    id: -1,
    title: 'New Widget',
    // Placeholder: This property should be overwritten on initialization.
    position: {
        top: 0,
        left: 0,
    },
    // Placeholder: This property should be overwritten on initialization.
    dimensions: {
        width: 100,
        height: 100,
    },
    conditionalVisibility: {
        isEnabled: false,
        variableValue: null,
        variableName: null,
        closeOnOutsideClick: false,
        hasCloseButton: true,
        placement: 'top-right-inside',
    },
    hide: false,
    styles: {
        backgroundColor: {
            isEnabled: false,
            color: undefined,
        },
        border: {
            isEnabled: false,
            width: '1px',
            style: 'solid',
            color: '#000000FF',
            radius: 1,
        },
        shadow: {
            isEnabled: false,
            color: '#777777',
            preset: 'small',
        },
        rotation: 0,
    },
    // Placeholder: This property should be overwritten on initialization.
    content: {
        kind: 'text',
        details: defaultTextWidgetValues,
    },
    tooltip: {
        isEnabled: false,
        icon: {
            type: 'exclamation',
            size: 'medium',
            color: '#2d77bcFF',
        },
        text: '',
        container: {
            mode: 'floating',
            paddingX: 1,
            paddingY: 1,
            placement: 'top-end',
        },
        filterInfo: false,
    },
}
