import React, { useEffect, useMemo, useState } from 'react'
import {
    Typography,
    Stack,
    DialogContent,
    DialogActions,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormGroup,
    FormLabel,
    Tooltip,
    IconButton,
} from '@mui/material'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
import { NetworkVizContextType, useNetworkVizContext, useNetworkVizDispatch } from '../context/NetworkVizContext'
import {
    ERGMAnalyticsType,
    ERGMBehaviorType,
    ERGMCharacteristicType,
    ERGMSettingsType,
    EdgeRenderType,
    EdgeType,
    NodeRenderType,
} from '../types/NetworkViz.types'
import WebWorker from 'helpers/webWorkerHelper'
import BaseButton from 'components/base/BaseButton'
import { toast } from 'react-toastify'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import InfoIcon from '@mui/icons-material/Info'
import DeleteIcon from '@mui/icons-material/Delete'
import { v4 as uuidv4 } from 'uuid'
import { useParams } from 'react-router'
import BaseFilledTextField from 'components/base/BaseFilledTextField'

var lz = require('lz-string')

// Define the properties that this component expects
interface ERGMDialogProps {
    onClose: () => void
}

const ERGMCharacteristicInfo: Record<ERGMCharacteristicType, any> = {
    changeArc: {
        name: 'Network Density (Change Arc)',
        description:
            "This parameter measures the overall connectivity in the network, often referred to as 'density'. It quantifies the proportion of potential connections in the network that are actual connections.",
    },
    changeReciprocity: {
        name: 'Reciprocity (Change Reciprocity)',
        description:
            'This parameter measures the tendency of node pairs to form mutual connections between each other.',
    },
    changeAltInStars: {
        name: 'Popularity (Change Alternating In-Stars)',
        description:
            'This parameter measures the popularity distribution of nodes by assessing the in-degree of nodes in the network.',
    },
    changeAltOutStars: {
        name: 'Activity (Change Alternating Out-Stars)',
        description:
            'This parameter measures the activity distribution of nodes by assessing the out-degree of nodes in the network.',
    },
    changeAltKTrianglesT: {
        name: 'Closure (Change Alternating k-Triangles)',
        description:
            "This parameter measures the local clustering of the network, reflecting the tendency of a node's connections to also be connected with each other.",
    },
    changeAltTwoPathsT: {
        name: 'Brokerage (Change Alternating Two-Paths)',
        description:
            'This parameter measures the potential for brokerage or gatekeeping roles within the network, reflecting the tendency of nodes to connect other nodes that are not directly connected.',
    },
}

const ERGMBehaviorInfo: Record<ERGMBehaviorType, any> = {
    Density: {
        name: 'Density',
        description: 'Indicates the proportion of potential connections in the network that are actual connections.',
    },
    Reciprocity: {
        name: 'Reciprocity',
        description: 'Indicates the tendency of node pairs to form mutual connections between each other.',
    },
    Popularity: {
        name: 'Popularity',
        description:
            'Indicates the popularity distribution of nodes by assessing the in-degree of nodes in the network.',
    },
    Activity: {
        name: 'Activity',
        description:
            'Indicates the activity distribution of nodes by assessing the out-degree of nodes in the network.',
    },
    Closure: {
        name: 'Closure',
        description:
            "Indicates the local clustering of the network, reflecting the tendency of a node's connections to also be connected with each other.",
    },
    Brokerage: {
        name: 'Brokerage',
        description:
            'Indicates the potential for brokerage or gatekeeping roles within the network, reflecting the tendency of nodes to connect other nodes that are not directly connected.',
    },
}

const ERGMDialog: React.FC<ERGMDialogProps> = ({ onClose }) => {
    // Use the network visualization context
    const {
        nodeRenders,
        edgeRenders,
        analyticsSettings,
        nodeDataSchema,
        edgeDataSchema,
        nodes,
        edges,
        analytics,
        ...networkVizContext
    } = useNetworkVizContext()
    const { pid, wid } = useParams()
    // Use the network visualization dispatch
    const dispatchContext = useNetworkVizDispatch()

    // Define the state for this component
    const [isRunning, setIsRunning] = useState<boolean>(false)

    const [localErgmSettings, setLocalErgmSettings] = useState<ERGMSettingsType>(analyticsSettings.ergm)

    useEffect(() => {
        setLocalErgmSettings({
            ...analyticsSettings.ergm,
            behavior: Object.keys(ERGMBehaviorInfo) as ERGMBehaviorType[],
            characteristics: Object.keys(ERGMCharacteristicInfo) as ERGMCharacteristicType[],
        })
    }, [analyticsSettings.ergm])

    const networkOptions = useMemo<string[]>(() => {
        const groupByField = edgeDataSchema.fields[analyticsSettings.groupBy]
        if (groupByField.type === 'string') {
            return groupByField.range
        } else if (groupByField.type === 'number') {
            return groupByField.range.values
        } else if (groupByField.type === 'date') {
            // convert Date objects to strings
            return groupByField.range.values.map((date) => date.toISOString())
        }
        return [] // return empty array as default
    }, [edgeDataSchema, analyticsSettings.groupBy])

    // Determine whether the "Run" button should be disabled
    const isRunDisabled = useMemo(
        () =>
            !(
                localErgmSettings.characteristics.length > 0 &&
                localErgmSettings.explanatoryAttributes.length > 0 &&
                localErgmSettings.behavior.length > 0 &&
                localErgmSettings.networks.length > 0
            ),
        [localErgmSettings]
    )

    // Handler for when the "Apply Advance Analytics" operation is performed
    const handleApplyAdvanceAnalytics = async () => {
        if (isRunDisabled) return

        const abortController = new AbortController()

        const ToastCloseButton = ({ closeToast }: { closeToast?: () => void }) => (
            <BaseButton
                onClick={() => {
                    abortController.abort()
                    closeToast && closeToast()
                }}
                label="Cancel"
            />
        )

        const toastId = toast.loading('Calculating Network Analytics', {
            // add close button to toast
            closeButton: <ToastCloseButton />,
        })

        setIsRunning(true)
        try {
            const nodeDict: NetworkVizContextType['nodes'] = {}

            const dic: Record<string, string> = {}

            for (const [id, node] of Object.entries(nodes)) {
                const randomId = uuidv4()
                dic[id] = randomId
                nodeDict[randomId] = {
                    id: randomId,
                    ...Object.fromEntries(
                        Object.entries(node).filter(([key]) =>
                            localErgmSettings.explanatoryAttributes.some((x) => x.attribute === key)
                        )
                    ),
                }
            }

            const networks: Record<string, EdgeType[]> = {}

            for (const edge of Object.values(edges)) {
                const network = (edge[analyticsSettings.groupBy] + '').toLowerCase()
                if (!localErgmSettings.networks.includes(network)) continue
                if (networks[network] === undefined) {
                    networks[network] = []
                }
                networks[network].push({ ...edge, source: dic[edge.source], target: dic[edge.target] })
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}/analytics/ergm`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    characteristics: localErgmSettings.characteristics,
                    behaviors: localErgmSettings.behavior,
                    explanatoryAttributes: localErgmSettings.explanatoryAttributes,
                    networks: networks,
                    nodes: nodeDict,
                }),
                credentials: 'include',
                signal: abortController.signal, // pass the abort signal to the fetch request
            })

            if (response.status === 200) {
                toast.update(toastId, {
                    render: 'ERGM Calculation Complete.',
                    type: 'success',
                    isLoading: false,
                    autoClose: 1000,
                    closeButton: null,
                })
                const ergms: Record<string, ERGMAnalyticsType> = await response.json()
                const newAnalytics = analytics === null ? {} : structuredClone(analytics)
                Object.entries(ergms).forEach(([key, ergm]) => {
                    if (newAnalytics[key] === undefined) {
                        newAnalytics[key] = {
                            nodes: {},
                            errors: {},
                            isolates: [],
                            strongly_connected_components: [],
                            weakly_connected_components: [],
                            greedy_modularity_communities: {},
                            rich_club_coefficient: {},
                            graph: {},
                            schema: {},
                            ergm: ergm ?? undefined,
                        }
                    } else {
                        newAnalytics[key].ergm = ergm ?? undefined
                    }
                })

                dispatchContext({ type: 'ANALYTICS_SETTINGS_EDIT', payload: { ergm: localErgmSettings } })
                dispatchContext({ type: 'ANALYTICS_UPDATE', payload: newAnalytics })
                handleClose()
            } else {
                toast.update(toastId, {
                    render: 'Failed to run analytics.',
                    type: 'error',
                    isLoading: false,
                    autoClose: 1000,
                    closeButton: null,
                })
            }
        } catch (e: any) {
            console.log(e)
            if (abortController.signal.aborted) {
                // Fetch was aborted
                toast.update(toastId, {
                    render: 'Request was cancelled.',
                    type: 'warning',
                    isLoading: false,
                    autoClose: 1000,
                    closeButton: null,
                })
            } else {
                // Other error occurred
                toast.update(toastId, {
                    render: 'Failed to run analytics.',
                    type: 'error',
                    isLoading: false,
                    autoClose: 1000,
                    closeButton: null,
                })
            }
        }
        setIsRunning(false)
    }
    // Handler for when the "Close Dialog" operation is performed
    const handleClose = () => {
        onClose()
    }

    // the handleCharacteristicChange and handleBehaviorChange are defined as

    const handleCharacteristicChange = (event: React.ChangeEvent<HTMLInputElement>, value: ERGMCharacteristicType) => {
        if (event.target.checked) {
            setLocalErgmSettings((prev) => ({ ...prev, characteristics: [...prev.characteristics, value] }))
        } else {
            setLocalErgmSettings((prev) => ({
                ...prev,
                characteristics: prev.characteristics.filter((item) => item !== value),
            }))
        }
    }

    const handleBehaviorChange = (event: React.ChangeEvent<HTMLInputElement>, value: ERGMBehaviorType) => {
        if (event.target.checked) {
            setLocalErgmSettings((prev) => ({ ...prev, behavior: [...prev.behavior, value] }))
        } else {
            setLocalErgmSettings((prev) => ({
                ...prev,
                behavior: prev.behavior.filter((item) => item !== value),
            }))
        }
    }

    const handleAttributeChange = (values: string[]) => {
        setLocalErgmSettings((prev) => ({
            ...prev,
            explanatoryAttributes: values.map((attribute) => ({
                attribute: attribute,
                type: prev.explanatoryAttributes.find((x) => x.attribute === attribute)?.type ?? 'auto',
            })),
        }))
    }

    const handleNetworkChange = (values: string[]) => {
        setLocalErgmSettings((prev) => ({ ...prev, networks: values }))
    }

    return (
        <React.Fragment>
            <DialogContent>
                <Grid2 container>
                    <Grid2 xs={12} px={2}>
                        <Typography variant="h6">Exponential Random Graph Model (ERGM) Settings</Typography>
                        <Typography>
                            ERGMs are a family of statistical models for analyzing data about social and other networks.
                            You can select characteristics and behaviors to customize your ERGM.
                        </Typography>
                        <Typography>
                            Choose the desired characteristics and behaviors and press the 'Run' button.
                        </Typography>
                    </Grid2>
                    <Grid2 xs={12} md={6} p={2}>
                        <BaseSelectWithLabel
                            fullWidth
                            selectProps={{ multiple: true }}
                            label="Networks"
                            options={networkOptions}
                            value={localErgmSettings.networks}
                            onChange={handleNetworkChange}
                        />
                    </Grid2>
                    <Grid2 xs={12} md={6} p={2}>
                        <BaseSelectWithLabel
                            fullWidth
                            selectProps={{ multiple: true }}
                            label="Attributes"
                            options={Object.keys(nodeDataSchema.fields).sort((a, b) => a.localeCompare(b))}
                            value={localErgmSettings.explanatoryAttributes.map((x) => x.attribute)}
                            onChange={handleAttributeChange}
                        />
                    </Grid2>
                    {/* <Grid2 xs={12} md={6} p={2}>
                        <FormControl component="fieldset" fullWidth>
                            <FormLabel component="legend">Select ERGM Characteristics</FormLabel>
                            <FormGroup>
                                {Object.entries(ERGMCharacteristicInfo).map(([value, { name, description }]) => (
                                    <FormControlLabel
                                        key={value}
                                        control={
                                            <Checkbox
                                                checked={localErgmSettings.characteristics.includes(
                                                    value as ERGMCharacteristicType
                                                )}
                                                onChange={(event) =>
                                                    handleCharacteristicChange(event, value as ERGMCharacteristicType)
                                                }
                                            />
                                        }
                                        label={
                                            <>
                                                <span>{name}</span>
                                                <Tooltip title={description}>
                                                    <IconButton size="small">
                                                        <InfoIcon color="info" fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        }
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </Grid2>

                    <Grid2 xs={12} md={6} p={2}>
                        <FormControl component="fieldset" fullWidth>
                            <FormLabel component="legend">Select ERGM Behaviors</FormLabel>
                            <FormGroup>
                                {Object.entries(ERGMBehaviorInfo).map(([value, { name, description }]) => (
                                    <FormControlLabel
                                        key={value}
                                        control={
                                            <Checkbox
                                                checked={localErgmSettings.behavior.includes(value as ERGMBehaviorType)}
                                                onChange={(event) =>
                                                    handleBehaviorChange(event, value as ERGMBehaviorType)
                                                }
                                            />
                                        }
                                        label={
                                            <>
                                                <span>{name}</span>
                                                <Tooltip title={description}>
                                                    <IconButton size="small">
                                                        <InfoIcon color="info" fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        }
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </Grid2> */}
                    <Grid2 xs={12} p={2}>
                        <Stack
                            direction="column"
                            gap={1}
                            sx={{ height: '500px', overflowY: 'auto' }}
                            className="u-scrollbar"
                        >
                            <Typography variant="h6">ERGM Attributes</Typography>
                            {localErgmSettings.explanatoryAttributes.length === 0 && (
                                <Typography variant="body2" color="textSecondary">
                                    No attributes selected
                                </Typography>
                            )}
                            {localErgmSettings.explanatoryAttributes.map((attribute) => {
                                console.log(attribute)
                                return (
                                    <Stack direction="row" gap={1} key={attribute.attribute}>
                                        <BaseFilledTextField
                                            label="Attribute"
                                            disabled
                                            value={attribute.attribute}
                                            fullWidth
                                        />
                                        <BaseSelectWithLabel
                                            fullWidth
                                            label="Data type"
                                            options={['auto', 'binary', 'categorical', 'numeric']}
                                            value={attribute.type}
                                            onChange={(value) => {
                                                setLocalErgmSettings((prev) => ({
                                                    ...prev,
                                                    explanatoryAttributes: prev.explanatoryAttributes.map((x) =>
                                                        x.attribute === attribute.attribute ? { ...x, type: value } : x
                                                    ),
                                                }))
                                            }}
                                        />
                                        <Tooltip title="Remove attribute">
                                            <IconButton
                                                onClick={() => {
                                                    setLocalErgmSettings((prev) => ({
                                                        ...prev,
                                                        explanatoryAttributes: prev.explanatoryAttributes.filter(
                                                            (x) => x.attribute !== attribute.attribute
                                                        ),
                                                    }))
                                                }}
                                            >
                                                <DeleteIcon color="warning" fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                )
                            })}
                        </Stack>
                    </Grid2>
                </Grid2>
            </DialogContent>

            <DialogActions>
                <BaseButton color="secondary" onClick={handleClose}>
                    Close
                </BaseButton>
                <BaseButton
                    variant="contained"
                    disabled={isRunDisabled || isRunning}
                    color="primary"
                    onClick={handleApplyAdvanceAnalytics}
                >
                    Run
                </BaseButton>
            </DialogActions>
        </React.Fragment>
    )
}

export default ERGMDialog
