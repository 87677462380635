import NetworkAlgorithmsControlButton from './NetworkAlgorithmsControlButton'
import NetworkClustersControlButton from './NetworkClustersControlButton'
import BaseButton from 'components/base/BaseButton'
import { IconButton, Stack, Tooltip } from '@mui/material'
import { useEffect, useState } from 'react'
import { NetworkVizContextType, useNetworkVizDispatch } from 'features/network-viz/context/NetworkVizContext'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import FitScreenIcon from '@mui/icons-material/FitScreen'
import SubtitlesOffIcon from '@mui/icons-material/SubtitlesOff'
import SubtitlesIcon from '@mui/icons-material/Subtitles'
import { NodeRenderType } from 'features/network-viz/types/NetworkViz.types'
import useKeyframeAnimation from 'features/network-viz/network-viz-settings/keyframe/useKeyframeAnimation'
import PlayIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'
export interface NetworkControlButtonsProps {
    viewMode: NetworkVizContextType['viewMode']
    nodeStyle: NetworkVizContextType['nodeStyle']
    nodeRenders: NetworkVizContextType['nodeRenders']
    onShowLabelsChange?: (showLabels: boolean) => void
}

export default function NetworkControlButtons({
    nodeRenders,
    nodeStyle,
    viewMode,
    onShowLabelsChange,
}: NetworkControlButtonsProps) {
    const dispatchContext = useNetworkVizDispatch()
    const [fullscreen, setFullscreen] = useState(false)
    const [showLabelEnabled, setShowLabelEnabled] = useState(true)

    useEffect(() => {
        let enabled = false
        for (let group in nodeStyle) {
            if (nodeStyle[group].label.show === false) {
                enabled = true
                break
            }
        }
        setShowLabelEnabled(enabled)
    }, [nodeStyle])

    const toggleLabels = () => {
        const newNodeStyle = structuredClone(nodeStyle)
        for (let group in newNodeStyle) {
            newNodeStyle[group].label.show = showLabelEnabled
        }
        const newNodeRenders = structuredClone(nodeRenders) as NodeRenderType[]

        newNodeRenders.forEach((nodeRender) => {
            if (nodeRender.label) {
                nodeRender.label.show = showLabelEnabled
            }
            if (showLabelEnabled) nodeRender.attributes.label = nodeRender.label?.formatter
            else nodeRender.attributes.label = undefined
        })

        onShowLabelsChange && onShowLabelsChange(showLabelEnabled)

        dispatchContext({
            type: 'NODE_STYLE_EDIT',
            payload: {
                nodeRenders: newNodeRenders,
                nodeStyle: newNodeStyle,
            },
        })
    }

    const fitNetwork = () => {
        dispatchContext({ type: 'ACTION', payload: { type: 'fit' } })
    }

    const keyframeManger = useKeyframeAnimation()

    return (
        <Stack direction="row-reverse" alignItems="center" gap={1}>
            {viewMode === 'design' && (
                <Tooltip title="Toggle Fullscreen">
                    <BaseButton
                        onClick={(e) => {
                            fullscreen
                                ? document.exitFullscreen()
                                : e.currentTarget.closest('.network-viz')?.requestFullscreen()
                            setFullscreen((pv) => !pv)
                            fullscreen
                                ? document.exitFullscreen()
                                : e.currentTarget.closest('.network-viz')?.requestFullscreen()
                            setFullscreen((pv) => !pv)
                        }}
                        disableElevation
                        sx={(theme) => ({
                            height: 48,
                            minWidth: 0,
                            width: 48,

                            borderRadius: 0,
                            // @Theme conditional
                            backgroundColor:
                                theme.palette.mode === 'light' ? theme.palette.common.bg_3 : theme.palette.common.bg_4,
                            color: theme.palette.primary.main,

                            '&:hover': {
                                // @Theme conditional
                                backgroundColor:
                                    theme.palette.mode === 'light'
                                        ? theme.palette.common.bg_3
                                        : theme.palette.common.bg_4,
                            },
                        })}
                    >
                        {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                    </BaseButton>
                </Tooltip>
            )}

            <Tooltip title={showLabelEnabled ? 'Show lables' : 'Hide Labels'}>
                <BaseButton
                    className="network-viz-labels-button"
                    onClick={toggleLabels}
                    disableElevation
                    sx={(theme) => ({
                        height: 48,
                        minWidth: 0,
                        width: 48,

                        borderRadius: 0,
                        // @Theme conditional
                        backgroundColor:
                            theme.palette.mode === 'light' ? theme.palette.common.bg_3 : theme.palette.common.bg_4,
                        color: theme.palette.primary.main,

                        '&:hover': {
                            // @Theme conditional
                            backgroundColor:
                                theme.palette.mode === 'light' ? theme.palette.common.bg_3 : theme.palette.common.bg_4,
                        },
                    })}
                >
                    {showLabelEnabled ? <SubtitlesIcon /> : <SubtitlesOffIcon />}
                </BaseButton>
            </Tooltip>

            <Tooltip title="Fit Network">
                <BaseButton
                    onClick={() => fitNetwork()}
                    className="network-viz-fit-button"
                    disableElevation
                    sx={(theme) => ({
                        height: 48,
                        minWidth: 0,
                        width: 48,

                        borderRadius: 0,
                        // @Theme conditional
                        backgroundColor:
                            theme.palette.mode === 'light' ? theme.palette.common.bg_3 : theme.palette.common.bg_4,
                        color: theme.palette.primary.main,

                        '&:hover': {
                            // @Theme conditional
                            backgroundColor:
                                theme.palette.mode === 'light' ? theme.palette.common.bg_3 : theme.palette.common.bg_4,
                        },
                    })}
                >
                    <FitScreenIcon />
                </BaseButton>
            </Tooltip>

            {keyframeManger.hasKeyframes && (
                <Tooltip title={keyframeManger.isPlaying ? 'Stop' : 'Explain Network'}>
                    <BaseButton
                        onClick={() => {
                            keyframeManger.isPlaying ? keyframeManger.stopAnimation() : keyframeManger.startAnimation()
                        }}
                        className="network-viz-play-button"
                        disableElevation
                        disabled={!keyframeManger.isReady}
                        sx={(theme) => ({
                            height: 48,
                            minWidth: 0,
                            width: 48,

                            borderRadius: 0,
                            // @Theme conditional
                            backgroundColor:
                                theme.palette.mode === 'light' ? theme.palette.common.bg_3 : theme.palette.common.bg_4,
                            color: theme.palette.primary.main,

                            '&:hover': {
                                // @Theme conditional
                                backgroundColor:
                                    theme.palette.mode === 'light'
                                        ? theme.palette.common.bg_3
                                        : theme.palette.common.bg_4,
                            },
                        })}
                    >
                        {keyframeManger.isPlaying ? <StopIcon /> : <PlayIcon />}
                    </BaseButton>
                </Tooltip>
            )}

            {viewMode === 'design' && (
                <Tooltip title="Change Algorithem">
                    <>
                        <NetworkAlgorithmsControlButton />
                    </>
                </Tooltip>
            )}
            {/* <NetworkClustersControlButton /> */}

            {/* <div onClick={stopTimer} className={`${cssBaseClass}-icons-div`}>
                    <StopIcon sx={{ color: "#217BBF" }} />
                </div> */}
        </Stack>
    )
}
