//* ======= Libraries
import React, { useEffect, useMemo, useState } from 'react'
import { PartialDeep } from 'type-fest'
import {
    Checkbox,
    Divider,
    Stack,
    Typography,
    Tabs,
    Tab,
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    InputAdornment,
} from '@mui/material'
//* ======= Components and features
import TableDataConfigDialog from 'features/report-designer/widgets/table-widget/TableDataConfigDialog'
import BorderPicker from 'components/widgets/BorderPicker'
import FontPicker from 'components/widgets/FontPicker'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import BaseSwitch from 'components/base/BaseSwitch'
import BaseButton from 'components/base/BaseButton'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelectWithLabel, { BaseSelectWithLabelOptionType } from 'components/base/BaseSelectWithLabel'
//* ======= Custom logic
import useReportStore, {
    useReportActiveSlide,
    useReportActiveWidget,
} from 'features/report-designer/store/reportDesignerStore'
import { getDataSourceData } from 'features/report-designer/helpers/reportDesigner.helper'
import {
    ReportWidgetContentKindType,
    ReportWidgetSettingsBaseType,
    ReportWidgetType,
    TableWidgetType,
} from 'features/report-designer/types/reportDesigner.types'
import {
    getTableAttributes,
    getTableConfigViewModeInitialValues,
    parseTableData,
} from 'features/report-designer/widgets/table-widget/helpers/TableWidget.helper'
import { TableConfig } from 'features/report-designer/widgets/table-widget/helpers/TableWidget.asset'
import ReportWidgetTooltipSettings from '../components/ReportWidgetTooltipSettings'
import ReportConditionalVisibilitySettings from '../components/ReportWidgetConditionalVisibilitySettings'
import ConnectedWidgetsSettings from '../components/ConnectedWidgetsSettings'
import ColorPicker from 'components/widgets/ColorPicker'
import EditableTextField from 'components/base/EditableTextField'
//* ======= Assets and styles

const tableWidgetViewModeOptions: BaseSelectWithLabelOptionType = [
    {
        label: 'Grid View',
        value: 'grid',
    },
    {
        label: 'Card View',
        value: 'card',
    },
    {
        label: 'Chip View',
        value: 'chip',
    },
]

type InternalWidgetStateType = {
    kind: Extract<ReportWidgetContentKindType, 'table'>
    settings: Omit<ReportWidgetType, 'content'>
    details: TableWidgetType
}

function TableWidgetSettings({ widgetId, isInternal = false }: ReportWidgetSettingsBaseType) {
    const { dataSources, updateWidget, updateWidgetStyles, updateTableWidgetContent } = useReportStore((store) => ({
        dataSources: store.dataSources,
        updateWidget: store.updateWidget,
        updateWidgetStyles: store.updateWidgetStyles,
        updateTableWidgetContent: store.updateTableWidgetContent,
    }))

    const activeSlide = useReportActiveSlide()
    const activeWidget = useReportActiveWidget(widgetId)

    // Create an internal widget state on activeWidget change.
    const widget = useMemo<InternalWidgetStateType | null>(() => {
        if (activeWidget === null || activeWidget.content.kind !== 'table') return null

        const {
            content: { kind, details },
            ...settings
        } = activeWidget

        return {
            kind: kind,
            settings: settings,
            details: details,
        }
    }, [activeWidget])

    const [currentTabIndex, setCurrentTabIndex] = useState(1)

    const [isDataConfigDialogOpen, setIsDataConfigDialogOpen] = useState<boolean>(false)

    const updateStyles = (styles: PartialDeep<ReportWidgetType['styles']>) => {
        if (activeSlide !== null && widget !== null) {
            updateWidgetStyles({
                slideId: activeSlide.id,
                widgetId: widget.settings.id,
                styles: styles,
            })
        }
    }

    const updateDetails = (details: TableWidgetType) => {
        if (activeSlide !== null && widget !== null) {
            updateTableWidgetContent({
                slideId: activeSlide.id,
                widgetId: widget.settings.id,
                data: details,
            })
        }
    }

    const onViewModeChange = (viewMode: TableWidgetType['config']['viewMode']) => {
        if (widget === null) return

        const initialConfigValues = getTableConfigViewModeInitialValues(viewMode)

        if (initialConfigValues !== null) {
            updateDetails({
                ...widget.details,
                config: {
                    ...initialConfigValues,
                },
            })
        }
    }

    const onTableConfigUpdate = (updatedValues: Partial<TableWidgetType['config']>) => {
        if (widget === null) return

        updateDetails({
            ...widget.details,
            config: {
                ...widget.details.config,
                ...updatedValues,
            } as TableConfig,
        })
    }

    const onTableDataUpdate = (data: Pick<TableWidgetType, 'selectedDataSource' | 'dataDefinition' | 'config'>) => {
        if (widget === null) return

        const parsedData = parseTableData({
            filteredData:
                getDataSourceData(
                    dataSources,
                    data.selectedDataSource,
                    getTableAttributes(data.dataDefinition),
                    undefined,
                    'compareWith' in data.dataDefinition ? data.dataDefinition.compareWith : null
                ) || [],
            definition: data.dataDefinition,
        })

        updateDetails({
            ...widget.details,
            selectedDataSource: structuredClone(data.selectedDataSource),
            dataDefinition: structuredClone(data.dataDefinition),
            parsedData: structuredClone(parsedData),
            config: structuredClone(data.config),
        })

        setIsDataConfigDialogOpen(false)
    }

    return (
        <>
            {activeSlide !== null && widget !== null ? (
                <Stack
                    gap={2}
                    sx={(theme) => ({
                        height: '100%',
                    })}
                >
                    {/* Tabs
                        ========================================= */}
                    {isInternal === false && (
                        <Box
                            sx={{
                                flexShrink: 0,

                                borderBottom: 1,
                                borderColor: 'divider',
                            }}
                        >
                            <Tabs
                                value={currentTabIndex}
                                onChange={(evt, currentIndex) => setCurrentTabIndex(currentIndex)}
                                variant="fullWidth"
                            >
                                <Tab label="Container" />
                                <Tab label="Table" />
                            </Tabs>
                        </Box>
                    )}

                    {/* Tab panels
                        ========================================= */}
                    {currentTabIndex === 0 ? (
                        /*  Container settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Title
                                ========================================= */}
                            <EditableTextField
                                label="Widget Title"
                                value={widget.settings.title}
                                onBlur={(v) =>
                                    updateWidget({
                                        slideId: activeSlide.id,
                                        widgetId: widget.settings.id,
                                        data: {
                                            title: v,
                                        },
                                    })
                                }
                                size="small"
                                fullWidth
                            />

                            {/* Background color
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Background Color"
                                hasToggle
                                isToggledOff={widget.settings.styles.backgroundColor.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        backgroundColor: {
                                            isEnabled: isEnabled,
                                            color: widget.settings.styles.backgroundColor.color ?? '#ffffff',
                                        },
                                    })
                                }
                                hasBottomBorder
                            >
                                <ColorPicker
                                    value={widget.settings.styles.backgroundColor.color}
                                    onChange={(newColor) =>
                                        updateStyles({
                                            backgroundColor: {
                                                color: newColor,
                                            },
                                        })
                                    }
                                    disabled={widget.settings.styles.backgroundColor.isEnabled === false}
                                />
                            </StyledWidgetAccordion>

                            {/* Border
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Border"
                                hasToggle
                                isToggledOff={widget.settings.styles.border.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        border: {
                                            isEnabled: isEnabled,
                                        },
                                    })
                                }
                                hasBottomBorder
                            >
                                <BorderPicker
                                    value={widget.settings.styles.border}
                                    onChange={(value) =>
                                        updateStyles({
                                            border: {
                                                width: value.width,
                                                style: value.style,
                                                color: value.color,
                                                radius: value.radius,
                                            },
                                        })
                                    }
                                    disabled={widget.settings.styles.border.isEnabled === false}
                                />
                            </StyledWidgetAccordion>

                            {/* Conditional visibility
                                ========================================= */}
                            <ReportConditionalVisibilitySettings
                                conditionalVisibility={widget.settings.conditionalVisibility}
                                onChange={(conditionalVisibility) => {
                                    updateWidget({
                                        slideId: activeSlide.id,
                                        widgetId: widget.settings.id,
                                        data: {
                                            conditionalVisibility,
                                        },
                                    })
                                }}
                            />

                            {/* Tooltip
                                ========================================= */}
                            <ReportWidgetTooltipSettings
                                tooltip={widget.settings.tooltip}
                                onChange={(tooltip) => {
                                    updateWidget({
                                        slideId: activeSlide.id,
                                        widgetId: widget.settings.id,
                                        data: {
                                            tooltip,
                                        },
                                    })
                                }}
                            />
                        </Stack>
                    ) : currentTabIndex === 1 ? (
                        /*  Content settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Configure data button
				                ========================================= */}
                            <BaseButton
                                label="Configure Data"
                                onClick={(evt) => setIsDataConfigDialogOpen(true)}
                                variant="outlined"
                                sx={(theme) => ({
                                    alignSelf: 'center',

                                    width: '80%',
                                    paddingY: theme.spacing(1.5),
                                    marginBottom: theme.spacing(1),
                                })}
                            />

                            {/* View mode
				                ========================================= */}
                            <BaseSelectWithLabel
                                label="View Mode"
                                value={widget.details.config.viewMode}
                                options={tableWidgetViewModeOptions}
                                onChange={(value) => onViewModeChange(value)}
                                size="small"
                            />

                            {/* Title switch
				                ========================================= */}
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                <Typography
                                    fontSize={14}
                                    noWrap
                                    textAlign="left"
                                    sx={{
                                        flex: '1 0 0',
                                    }}
                                >
                                    Show Title:
                                </Typography>

                                <BaseSwitch
                                    checked={widget.details.config.hasTitle}
                                    onChange={(evt, checked) =>
                                        onTableConfigUpdate({
                                            hasTitle: checked,
                                        })
                                    }
                                    size="small"
                                    color="primary"
                                    sx={{
                                        flexShrink: 0,
                                    }}
                                />
                            </Stack>

                            {/* Empty data message
                                ========================================= */}
                            <BaseFilledTextField
                                label="Empty Data Message"
                                defaultValue={widget.details.config.emptyDataMessage}
                                onBlur={(evt) =>
                                    onTableConfigUpdate({
                                        emptyDataMessage: evt.target.value.trim(),
                                    })
                                }
                                size="small"
                                fullWidth
                            />

                            {/* View mode specific options
				                ========================================= */}
                            {widget.details.config.viewMode === 'grid' ? (
                                /*  Grid view options
				                    ========================================= */
                                <>
                                    {/* Search switch
				                        ========================================= */}
                                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                        <Typography
                                            fontSize={14}
                                            noWrap
                                            textAlign="left"
                                            sx={{
                                                flex: '1 0 0',
                                            }}
                                        >
                                            Search field:
                                        </Typography>

                                        <BaseSwitch
                                            checked={widget.details.config.hasSearch}
                                            onChange={(evt, checked) =>
                                                onTableConfigUpdate({
                                                    hasSearch: checked,
                                                })
                                            }
                                            size="small"
                                            color="primary"
                                            sx={{
                                                flexShrink: 0,
                                            }}
                                        />
                                    </Stack>

                                    {/* Header switch
				                        ========================================= */}
                                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                        <Typography
                                            fontSize={14}
                                            noWrap
                                            textAlign="left"
                                            sx={{
                                                flex: '1 0 0',
                                            }}
                                        >
                                            Show Header:
                                        </Typography>

                                        <BaseSwitch
                                            checked={widget.details.config.hasHeader}
                                            onChange={(evt, checked) =>
                                                onTableConfigUpdate({
                                                    hasHeader: checked,
                                                })
                                            }
                                            size="small"
                                            color="primary"
                                            sx={{
                                                flexShrink: 0,
                                            }}
                                        />
                                    </Stack>

                                    {/* Reset column widths to auto button
                                        ========================================= */}
                                    <BaseButton
                                        label="Reset Column Widths"
                                        onClick={() => {
                                            onTableConfigUpdate({
                                                columnsWidth: {},
                                            })
                                        }}
                                        variant="outlined"
                                    />
                                </>
                            ) : widget.details.config.viewMode === 'card' ? (
                                /*  Card view options
				                    ========================================= */
                                <StyledWidgetAccordion
                                    title="Header"
                                    hasToggle={true}
                                    isToggledOff={!widget.details.config.hasHeader}
                                    onToggle={(isEnabled) =>
                                        onTableConfigUpdate({
                                            hasHeader: isEnabled,
                                        })
                                    }
                                >
                                    <Stack gap={1}>
                                        {/* Row change switch
				                        ========================================= */}
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            gap={1}
                                        >
                                            <Typography
                                                fontSize={14}
                                                noWrap
                                                textAlign="left"
                                                sx={{
                                                    flex: '1 0 0',
                                                }}
                                            >
                                                Enable Row change:
                                            </Typography>

                                            <BaseSwitch
                                                checked={widget.details.config.canChangeRows}
                                                onChange={(evt, checked) =>
                                                    onTableConfigUpdate({
                                                        canChangeRows: checked,
                                                    })
                                                }
                                                disabled={!widget.details.config.hasHeader}
                                                size="small"
                                                color="primary"
                                                sx={{
                                                    flexShrink: 0,
                                                }}
                                            />
                                        </Stack>

                                        {/* Search switch
				                        ========================================= */}
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            gap={1}
                                        >
                                            <Typography
                                                fontSize={14}
                                                noWrap
                                                textAlign="left"
                                                sx={{
                                                    flex: '1 0 0',
                                                }}
                                            >
                                                Enable Search:
                                            </Typography>

                                            <BaseSwitch
                                                checked={widget.details.config.hasSearch}
                                                disabled={!widget.details.config.hasHeader}
                                                onChange={(evt, checked) =>
                                                    onTableConfigUpdate({
                                                        hasSearch: checked,
                                                    })
                                                }
                                                size="small"
                                                color="primary"
                                                sx={{
                                                    flexShrink: 0,
                                                }}
                                            />
                                        </Stack>

                                        {/* Primary field label switch
				                        ========================================= */}
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            gap={1}
                                        >
                                            <Typography
                                                fontSize={14}
                                                noWrap
                                                textAlign="left"
                                                sx={{
                                                    flex: '1 0 0',
                                                }}
                                            >
                                                Show Primary Label:
                                            </Typography>

                                            <BaseSwitch
                                                checked={widget.details.config.isPrimaryLabelVisible}
                                                disabled={!widget.details.config.hasHeader}
                                                onChange={(evt, checked) =>
                                                    onTableConfigUpdate({
                                                        isPrimaryLabelVisible: checked,
                                                    })
                                                }
                                                size="small"
                                                color="primary"
                                                sx={{
                                                    flexShrink: 0,
                                                }}
                                            />
                                        </Stack>

                                        {/* Header style settings
				                        ========================================= */}
                                        <StyledWidgetAccordion
                                            isDisabled={!widget.details.config.hasHeader}
                                            title="Header Style"
                                        >
                                            <Stack gap={1}>
                                                {/* Title font and color settings
						                        ========================================= */}
                                                <FontPicker
                                                    onChange={(value) =>
                                                        onTableConfigUpdate({
                                                            headerStyle: {
                                                                fontFamily: value.fontFamily,
                                                                fontSize: value.fontSize,
                                                                fontWeight: value.isBold ? 'bold' : 'normal',
                                                                color: value.color,
                                                            },
                                                        })
                                                    }
                                                    defaultValue={{
                                                        fontFamily: widget.details.config.headerStyle.fontFamily,
                                                        fontSize: widget.details.config.headerStyle.fontSize,
                                                        color: widget.details.config.headerStyle.color ?? '#000000',
                                                        isBold: widget.details.config.headerStyle.fontWeight === 'bold',
                                                    }}
                                                    disabled={!widget.details.config.hasHeader}
                                                    styleControls={{
                                                        bold: true,
                                                        color: true,
                                                    }}
                                                />
                                                <BaseFilledTextField
                                                    label="Avatar Size"
                                                    size="small"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="start">px</InputAdornment>
                                                        ),
                                                    }}
                                                    type="number"
                                                    defaultValue={widget.details.config.avatarSize ?? 40}
                                                    onBlur={(evt) => {
                                                        try {
                                                            const value = parseInt(evt.target.value)
                                                            if (value >= 0) {
                                                                onTableConfigUpdate({
                                                                    avatarSize: value,
                                                                })
                                                            }
                                                        } catch (e) {}
                                                    }}
                                                />
                                            </Stack>
                                        </StyledWidgetAccordion>
                                    </Stack>
                                </StyledWidgetAccordion>
                            ) : widget.details.config.viewMode === 'chip' ? (
                                /*  Chip view options               
                                    ========================================= */
                                <>
                                    {/* Search switch
                                        ========================================= */}
                                    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                                        <Typography
                                            fontSize={14}
                                            noWrap
                                            textAlign="left"
                                            sx={{
                                                flex: '1 0 0',
                                            }}
                                        >
                                            Enable Search:
                                        </Typography>

                                        <BaseSwitch
                                            checked={widget.details.config.hasSearch}
                                            onChange={(evt, checked) =>
                                                onTableConfigUpdate({
                                                    hasSearch: checked,
                                                })
                                            }
                                            size="small"
                                            color="primary"
                                            sx={{
                                                flexShrink: 0,
                                            }}
                                        />
                                    </Stack>

                                    {/* Gap
                                            ========================================= */}
                                    <BaseSelectWithLabel
                                        label="Spacing"
                                        value={widget.details.config.gap}
                                        size="small"
                                        onChange={(value) =>
                                            onTableConfigUpdate({
                                                gap: value,
                                            })
                                        }
                                        options={[
                                            {
                                                label: 'Dense',
                                                value: 0.5,
                                            },
                                            {
                                                label: 'Normal',
                                                value: 1,
                                            },
                                            {
                                                label: 'Wide',
                                                value: 2,
                                            },
                                        ]}
                                    />

                                    {/* Chip color settings
                                        ========================================= */}
                                    <StyledWidgetAccordion title="Chip's Color">
                                        <Stack gap={1}>
                                            <Stack gap={0.5}>
                                                <Typography fontSize={12} fontWeight={400} noWrap color="common.text_1">
                                                    Default Background Color:
                                                </Typography>
                                                <ColorPicker
                                                    value={widget.details.config.defaultChipColor}
                                                    onChange={(value) =>
                                                        onTableConfigUpdate({ defaultChipColor: value })
                                                    }
                                                />
                                            </Stack>
                                            <Stack gap={0.5}>
                                                <Typography fontSize={12} fontWeight={400} noWrap color="common.text_1">
                                                    Selected Background Color:
                                                </Typography>
                                                <ColorPicker
                                                    value={widget.details.config.selectedChipColor}
                                                    onChange={(value) =>
                                                        onTableConfigUpdate({ selectedChipColor: value })
                                                    }
                                                />
                                            </Stack>
                                        </Stack>
                                    </StyledWidgetAccordion>
                                </>
                            ) : (
                                false
                            )}

                            {/* Font style settings
                                ========================================= */}
                            <StyledWidgetAccordion title="Default Text Font Style">
                                <FontPicker
                                    onChange={(value) =>
                                        onTableConfigUpdate({
                                            fontStyles: {
                                                fontFamily: value.fontFamily,
                                                fontSize: value.fontSize,
                                                fontWeight: value.isBold ? 'bold' : 'normal',
                                            },
                                        })
                                    }
                                    defaultValue={{
                                        fontFamily: widget.details.config.fontStyles.fontFamily,
                                        fontSize: widget.details.config.fontStyles.fontSize,
                                        isBold: widget.details.config.fontStyles.fontWeight === 'bold',
                                    }}
                                    styleControls={{
                                        bold: widget.details.config.viewMode === 'chip',
                                    }}
                                />
                            </StyledWidgetAccordion>

                            <Divider />

                            {/* Controllable widgets
                                ========================================= */}
                            <ConnectedWidgetsSettings
                                connectedWidgets={widget.details.connectedWidgets}
                                onConnectedWidgetsUpdate={(newConnectedWidgets) =>
                                    updateDetails({ ...widget.details, connectedWidgets: newConnectedWidgets })
                                }
                                slideWidgets={activeSlide.widgets}
                                selectedWidgetId={widgetId}
                            />
                        </Stack>
                    ) : (
                        false
                    )}

                    {/* (Out-of-flow) Data config dialog
				        ========================================= */}
                    {isDataConfigDialogOpen && (
                        <TableDataConfigDialog
                            isOpen={isDataConfigDialogOpen}
                            data={widget.details}
                            dataSources={dataSources}
                            onConfirm={onTableDataUpdate}
                            onClose={() => setIsDataConfigDialogOpen(false)}
                        />
                    )}
                </Stack>
            ) : (
                /*  Null view
                    ========================================= */
                false
            )}
        </>
    )
}

export default TableWidgetSettings
