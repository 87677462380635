//* ======= Libraries
import React, { useState } from 'react'
import { useParams } from 'react-router'
import { Stack, IconButton, Tooltip, DialogTitle, DialogContent, DialogActions } from '@mui/material'
//* ======= Components and features
import ShareReportDialog from 'features/share-report-dialog/ShareReportDialog'
//* ======= Custom logic
import useReportStore from 'features/report-designer/store/reportDesignerStore'
import { saveReport } from 'features/report-designer/helpers/reportDesigner.helper'
import { APP_VERSIONS } from 'helpers/constants'
//* ======= Assets and styles
import SaveIcon from '@mui/icons-material/Save'
import SettingsIcon from '@mui/icons-material/Settings'
import ShareIcon from '@mui/icons-material/Share'
import KeyboardIcon from '@mui/icons-material/Keyboard'
import StyledDialog from 'components/dialog/StyledDialog'
import KeyboardShortcutsGuide from '../guides/KeyboardShortcutsGuide'
import BaseButton from 'components/base/BaseButton'

function ReportControlsToolbar() {
    const { pid, rid } = useParams()

    const [showKeyboardShortcuts, setShowKeyboardShortcuts] = useState(false)

    const {
        status,
        aspectRatio,
        masterSettings,
        layoutSettings,
        slides,
        dataSources,
        customViews,
        reportAccess,
        accessToken,
        updateLayoutSettingsSidebarView,
    } = useReportStore((store) => ({
        status: store.status,
        aspectRatio: store.aspectRatio,
        masterSettings: store.masterSettings,
        layoutSettings: store.layoutSettings,
        slides: store.slides,
        dataSources: store.dataSources,
        customViews: store.customViews,
        reportAccess: store.reportAccess,
        accessToken: store.accessToken,
        updateLayoutSettingsSidebarView: store.updateLayoutSettingsSidebarView,
    }))

    const [shareDialogOpen, setShareDialogOpen] = useState(false)
    const handleOpenShareDialog = () => setShareDialogOpen(true)
    const handleCloseShareDialog = () => setShareDialogOpen(false)

    const onSettingsButtonClick = () => {
        if (['pending', 'faulty'].includes(status) === false) {
            if (layoutSettings.settingsSidebar.view !== 'report') {
                updateLayoutSettingsSidebarView({ view: 'report' })
            }
        }
    }

    return (
        <Stack direction="column" alignItems="center" gap={1.5}>
            {/* Save button
				========================================= */}
            <Tooltip title="Save Changes" enterDelay={200} arrow placement="right">
                <IconButton
                    onClick={(evt) =>
                        saveReport(
                            {
                                reportVersion: APP_VERSIONS.REPORT,
                                aspectRatio,
                                masterSettings: masterSettings,
                                dataSources,
                                slides,
                            },
                            pid!,
                            rid!
                        )
                    }
                >
                    <SaveIcon
                        sx={{
                            width: 28,
                            height: 28,
                        }}
                    />
                </IconButton>
            </Tooltip>

            {/* Share button
				========================================= */}
            <Tooltip title="Share Report" enterDelay={200} arrow placement="right">
                <IconButton onClick={handleOpenShareDialog}>
                    <ShareIcon
                        sx={{
                            width: 28,
                            height: 28,
                        }}
                    />
                </IconButton>
            </Tooltip>

            <Tooltip title="Keyboard Shortcuts" enterDelay={200} arrow placement="right">
                <IconButton onClick={() => setShowKeyboardShortcuts(true)}>
                    <KeyboardIcon
                        sx={{
                            width: 28,
                            height: 28,
                        }}
                    />
                </IconButton>
            </Tooltip>

            {/* Settings button
				========================================= */}
            <Tooltip title="Report Settings" enterDelay={200} arrow placement="right">
                <IconButton onClick={(evt) => onSettingsButtonClick()}>
                    <SettingsIcon
                        sx={{
                            width: 28,
                            height: 28,
                        }}
                    />
                </IconButton>
            </Tooltip>

            {/* Share Dialog
				========================================= */}
            {shareDialogOpen && (
                <ShareReportDialog
                    isOpen={shareDialogOpen}
                    onClose={handleCloseShareDialog}
                    token={accessToken}
                    defaultReportAccess={reportAccess}
                    defaultCustomViews={customViews}
                    slides={slides}
                    dataSources={dataSources}
                />
            )}

            {/* Keyboard Shortcuts Dialog
				========================================= */}
            {showKeyboardShortcuts && (
                <StyledDialog
                    open={showKeyboardShortcuts}
                    onClose={() => setShowKeyboardShortcuts(false)}
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle>Keyboard Shortcuts</DialogTitle>
                    <DialogContent className="u-scrollbar">
                        <KeyboardShortcutsGuide />
                    </DialogContent>

                    <DialogActions>
                        <BaseButton onClick={() => setShowKeyboardShortcuts(false)}>Close</BaseButton>
                    </DialogActions>
                </StyledDialog>
            )}
        </Stack>
    )
}

export default ReportControlsToolbar
