//* ======= Libraries
import React, { useState, useEffect, useMemo } from 'react'
import { PartialDeep } from 'type-fest'
import { Stack, Typography, Tabs, Tab, Divider, Box, IconButton, FormControlLabel } from '@mui/material'
//* ======= Components and features
import ColorPicker from 'components/widgets/ColorPicker'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
//* ======= Custom logic
import useReportStore, {
    useReportActiveSlide,
    useReportActiveWidget,
} from 'features/report-designer/store/reportDesignerStore'
import {
    ReportWidgetType,
    ReportWidgetContentKindType,
    HorizontalLineWidgetType,
    CombinedWidgetType,
    ReportWidgetSettingsBaseType,
    CombinedWidgetSelectType,
} from 'features/report-designer/types/reportDesigner.types'
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import BorderPicker from 'components/widgets/BorderPicker'
import BaseButton from 'components/base/BaseButton'
import { ReportWidgetSettingsSwitcher } from '../ReportWidgetSettings'
import GenericDragDropList, { moveItemInArray } from 'features/GenericDragDropList/GenericDragDropList'
import CombinedWidgetLinkDialog from './CombinedWidgetLinkDialog'
//* ======= Assets and styles
import EditIcon from '@mui/icons-material/Edit'
import FontPicker from 'components/widgets/FontPicker'
import ReportWidgetTooltipSettings from '../components/ReportWidgetTooltipSettings'
import ReportConditionalVisibilitySettings from '../components/ReportWidgetConditionalVisibilitySettings'
import DynamicIcon from 'features/dynamic-icon/DynamicIcon'
import { StyledSwitch } from 'features/StyledComponents/StyledSwitch'

type InternalWidgetStateType = {
    kind: Extract<ReportWidgetContentKindType, 'combined'>
    settings: Omit<ReportWidgetType, 'content'>
    details: CombinedWidgetType
}

function CombinedWidgetSettings({ widgetId, isInternal = false }: ReportWidgetSettingsBaseType) {
    const {
        updateWidgetStyles,
        updateWidget,
        unCombineWidgets,
        updateCombinedWidgetContent,
        updateCombinedWidgetInnerWidgetsOrder,
    } = useReportStore((store) => ({
        activeSlideId: store.activeSlideId,
        updateWidget: store.updateWidget,
        updateWidgetStyles: store.updateWidgetStyles,
        unCombineWidgets: store.unCombineWidgets,
        updateCombinedWidgetContent: store.updateCombinedWidgetContent,
        updateCombinedWidgetInnerWidgetsOrder: store.updateCombinedWidgetInnerWidgetsOrder,
    }))

    const [currentTabIndex, setCurrentTabIndex] = useState(1)
    const [editInnerWidgetDialogOpen, setEditInnerWidgetDialogOpen] = useState<{
        isOpen: boolean
        widgetId: ReportWidgetType['id'] | null
    }>({ isOpen: false, widgetId: null })

    const activeWidget = useReportActiveWidget(widgetId)
    const activeSlide = useReportActiveSlide()

    // Create an internal widget state on activeWidget change.
    const widget = useMemo<InternalWidgetStateType | null>(() => {
        if (activeWidget === null || activeWidget.content.kind !== 'combined') return null

        const {
            content: { kind, details },
            ...settings
        } = activeWidget

        return {
            kind: kind,
            settings: settings,
            details: details,
        }
    }, [activeWidget])

    const activeInternalWidget = useMemo<ReportWidgetType | null>(() => {
        if (widget === null) return null

        return activeSlide?.widgets.find((_widget) => _widget.id === widget.details.selectedWidgetId) ?? null
    }, [widget, activeSlide?.widgets])

    const updateStyles = (styles: PartialDeep<ReportWidgetType['styles']>) => {
        if (activeSlide !== null && widget !== null) {
            updateWidgetStyles({
                slideId: activeSlide.id,
                widgetId: widget.settings.id,
                styles: styles,
            })
        }
    }

    const innerWidgets = useMemo<{ title: string; id: number | string; icon: string | undefined }[]>(() => {
        if (widget === null || activeSlide === null) return []

        const result: { title: string; id: number | string; icon: string | undefined }[] = []

        widget.details.widgets.forEach(({ id: _id, icon }) => {
            const _widget = activeSlide.widgets.find((_widget) => _widget.id === _id)
            if (_widget) {
                result.push({
                    title: _widget.title,
                    id: _id,
                    icon: icon,
                })
            }
        })

        return result
    }, [widget, activeSlide])

    return (
        <>
            {activeSlide !== null && widget !== null ? (
                <Stack
                    gap={2}
                    sx={(theme) => ({
                        height: '100%',
                        oveflowY: 'hidden',
                    })}
                >
                    {/* Tabs
                        ========================================= */}
                    <Box
                        sx={{
                            flexShrink: 0,

                            borderBottom: 1,
                            borderColor: 'divider',
                        }}
                    >
                        <Tabs
                            value={currentTabIndex}
                            onChange={(evt, currentIndex) => setCurrentTabIndex(currentIndex)}
                            variant="fullWidth"
                        >
                            <Tab label="Container" />
                            <Tab
                                label={activeInternalWidget?.title}
                                wrapped={(activeInternalWidget?.title?.length ?? 0) > 12 ? true : false}
                            />
                        </Tabs>
                    </Box>

                    {/* Tab panels
                        ========================================= */}
                    {currentTabIndex === 0 ? (
                        /*  Container settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Title
                                ========================================= */}
                            <BaseFilledTextField
                                label="Widget Title"
                                defaultValue={widget.settings.title}
                                onBlur={(evt) =>
                                    updateWidget({
                                        slideId: activeSlide.id,
                                        widgetId: widget.settings.id,
                                        data: {
                                            title: evt.target.value.trim(),
                                        },
                                    })
                                }
                                size="small"
                                fullWidth
                            />

                            {/* Background color */}
                            <StyledWidgetAccordion
                                title="Background Color"
                                hasToggle
                                isToggledOff={widget.settings.styles.backgroundColor.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        backgroundColor: {
                                            isEnabled: isEnabled,
                                            color: widget.settings.styles.backgroundColor.color ?? '#ffffff',
                                        },
                                    })
                                }
                                hasBottomBorder
                            >
                                <ColorPicker
                                    value={widget.settings.styles.backgroundColor.color}
                                    onChange={(newColor) =>
                                        updateStyles({
                                            backgroundColor: {
                                                color: newColor,
                                            },
                                        })
                                    }
                                    disabled={widget.settings.styles.backgroundColor.isEnabled === false}
                                />
                            </StyledWidgetAccordion>

                            {/* Border
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Border"
                                hasToggle
                                isToggledOff={widget.settings.styles.border.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        border: {
                                            isEnabled: isEnabled,
                                        },
                                    })
                                }
                            >
                                <BorderPicker
                                    value={widget.settings.styles.border}
                                    onChange={(value) =>
                                        updateStyles({
                                            border: {
                                                width: value.width,
                                                style: value.style,
                                                color: value.color,
                                                radius: value.radius,
                                            },
                                        })
                                    }
                                    disabled={widget.settings.styles.border.isEnabled === false}
                                />
                            </StyledWidgetAccordion>

                            {/* Conditional visibility
                                ========================================= */}
                            <ReportConditionalVisibilitySettings
                                conditionalVisibility={widget.settings.conditionalVisibility}
                                onChange={(conditionalVisibility) => {
                                    updateWidget({
                                        slideId: activeSlide.id,
                                        widgetId: widget.settings.id,
                                        data: {
                                            conditionalVisibility,
                                        },
                                    })
                                }}
                            />

                            {/* Tooltip
                                ========================================= */}
                            <ReportWidgetTooltipSettings
                                tooltip={widget.settings.tooltip}
                                onChange={(tooltip) => {
                                    updateWidget({
                                        slideId: activeSlide.id,
                                        widgetId: widget.settings.id,
                                        data: {
                                            tooltip,
                                        },
                                    })
                                }}
                            />

                            {/* variant - conditional: only for select mode
                                ========================================= */}
                            {widget.details.mode === 'select' && (
                                <>
                                    <BaseSelectWithLabel
                                        onChange={(value) =>
                                            updateCombinedWidgetContent({
                                                slideId: activeSlide.id,
                                                widgetId: widget.settings.id,
                                                data: {
                                                    variant: value as CombinedWidgetSelectType['variant'],
                                                },
                                            })
                                        }
                                        label="Variant"
                                        value={widget.details.variant}
                                        options={[
                                            {
                                                label: 'None',
                                                value: 'none',
                                            },
                                            {
                                                label: 'Tabs',
                                                value: 'tabs',
                                            },
                                            {
                                                label: 'Select',
                                                value: 'drop-down',
                                            },
                                            {
                                                label: 'Floating Menu',
                                                value: 'floating-menu',
                                            },
                                            {
                                                label: 'List',
                                                value: 'stack',
                                            },
                                        ]}
                                        size="small"
                                    />
                                    {widget.details.variant === 'tabs' && (
                                        <>
                                            <StyledSwitch
                                                checked={widget.details.iconOnly}
                                                size="small"
                                                label="Icon Only"
                                                onChange={(evt, checked) => {
                                                    updateCombinedWidgetContent({
                                                        slideId: activeSlide.id,
                                                        widgetId: widget.settings.id,
                                                        data: {
                                                            iconOnly: checked,
                                                        },
                                                    })
                                                }}
                                            />

                                            <BaseSelectWithLabel
                                                label="Icon Position"
                                                value={widget.details.iconPosition}
                                                onChange={(value) =>
                                                    updateCombinedWidgetContent({
                                                        slideId: activeSlide.id,
                                                        widgetId: widget.settings.id,
                                                        data: {
                                                            iconPosition: value,
                                                        },
                                                    })
                                                }
                                                options={[
                                                    {
                                                        label: 'Left',
                                                        value: 'start',
                                                    },
                                                    {
                                                        label: 'Right',
                                                        value: 'end',
                                                    },
                                                    {
                                                        label: 'Top',
                                                        value: 'top',
                                                    },
                                                    {
                                                        label: 'Bottom',
                                                        value: 'bottom',
                                                    },
                                                ]}
                                                size="small"
                                            />

                                            <StyledWidgetAccordion title="Tabs font style" hasBottomBorder>
                                                <FontPicker
                                                    defaultValue={widget.details.fontStyle}
                                                    onChange={(value) =>
                                                        updateCombinedWidgetContent({
                                                            slideId: activeSlide.id,
                                                            widgetId: widget.settings.id,
                                                            data: {
                                                                fontStyle: {
                                                                    color: value.color,
                                                                    family: value.fontFamily,
                                                                    size: value.fontSize,
                                                                    weight: value.isBold ? 'bold' : 'normal',
                                                                },
                                                            },
                                                        })
                                                    }
                                                    styleControls={{
                                                        bold: true,
                                                    }}
                                                />
                                            </StyledWidgetAccordion>
                                        </>
                                    )}

                                    {widget.details.variant === 'stack' && (
                                        <>
                                            <BaseSelectWithLabel
                                                label="Direction"
                                                value={widget.details.stackDirection}
                                                onChange={(value) =>
                                                    updateCombinedWidgetContent({
                                                        slideId: activeSlide.id,
                                                        widgetId: widget.settings.id,
                                                        data: {
                                                            stackDirection: value,
                                                        },
                                                    })
                                                }
                                                options={[
                                                    {
                                                        label: 'Vertical',
                                                        value: 'column',
                                                    },
                                                    {
                                                        label: 'Horizontal',
                                                        value: 'row',
                                                    },
                                                ]}
                                                size="small"
                                            />
                                            <StyledWidgetAccordion
                                                title="Accordion"
                                                hasBottomBorder={true}
                                                hasToggle={true}
                                                isToggledOff={!widget.details.accordion.enabled}
                                                onToggle={(isEnabled) => {
                                                    updateCombinedWidgetContent({
                                                        slideId: activeSlide.id,
                                                        widgetId: widget.settings.id,
                                                        data: {
                                                            accordion: {
                                                                enabled: isEnabled,
                                                            },
                                                        },
                                                    })
                                                }}
                                            >
                                                <StyledSwitch
                                                    label="Close others"
                                                    size="small"
                                                    checked={widget.details.accordion.closeOthers}
                                                    onChange={(evt, checked) => {
                                                        updateCombinedWidgetContent({
                                                            slideId: activeSlide.id,
                                                            widgetId: widget.settings.id,
                                                            data: {
                                                                accordion: {
                                                                    closeOthers: checked,
                                                                },
                                                            },
                                                        })
                                                    }}
                                                    disabled={!widget.details.accordion.enabled}
                                                />
                                            </StyledWidgetAccordion>
                                        </>
                                    )}
                                </>
                            )}

                            {/* uncombined widgets
                                    ========================================= */}
                            <BaseButton
                                variant="outlined"
                                disabled={widget.settings.hide}
                                size="small"
                                fullWidth
                                onClick={unCombineWidgets}
                            >
                                Uncombine
                            </BaseButton>

                            {/* Divider
                                ========================================= */}
                            <Divider />

                            {/* List of widgets
                                ========================================= */}
                            <Stack gap={1}>
                                <GenericDragDropList
                                    items={innerWidgets}
                                    onItemMove={(dragId, newIndex) => {
                                        updateCombinedWidgetInnerWidgetsOrder({
                                            slideId: activeSlide.id,
                                            widgetId: widget.settings.id,
                                            data: {
                                                dragId,
                                                newIndex,
                                            },
                                        })
                                    }}
                                    getKeyValue={(item, idx) =>
                                        item.id + idx.toString() + (item.icon ?? '') + item.title
                                    }
                                    renderItem={(item) => {
                                        return (
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                gap={1}
                                                justifyContent="space-between"
                                            >
                                                {item.icon && <DynamicIcon icon={item.icon} />}
                                                <Typography>{item.title}</Typography>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setEditInnerWidgetDialogOpen({
                                                            isOpen: true,
                                                            widgetId: item.id,
                                                        })
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Stack>
                                        )
                                    }}
                                    targetType="widget"
                                />
                            </Stack>

                            {/* Out of flow - Edit inner widget dialog
                                ========================================= */}
                            {editInnerWidgetDialogOpen.isOpen && (
                                <CombinedWidgetLinkDialog
                                    isOpen={editInnerWidgetDialogOpen.isOpen}
                                    onClose={() => {
                                        setEditInnerWidgetDialogOpen({
                                            isOpen: false,
                                            widgetId: null,
                                        })
                                    }}
                                    onSave={(updatedState) => {
                                        if (activeSlide !== null && editInnerWidgetDialogOpen.widgetId !== null) {
                                            updateWidget({
                                                slideId: activeSlide.id,
                                                widgetId: editInnerWidgetDialogOpen.widgetId,
                                                data: {
                                                    title: updatedState.title,
                                                },
                                            })

                                            updateCombinedWidgetContent({
                                                slideId: activeSlide.id,
                                                widgetId: widgetId!,
                                                data: {
                                                    widgets: widget.details.widgets.map((innerWidget) => {
                                                        if (innerWidget.id === editInnerWidgetDialogOpen.widgetId) {
                                                            return {
                                                                ...innerWidget,
                                                                linkedWidgets: updatedState.linkedWidgets,
                                                                icon: updatedState.icon,
                                                            }
                                                        }
                                                        return innerWidget
                                                    }),
                                                },
                                            })
                                            setEditInnerWidgetDialogOpen({
                                                isOpen: false,
                                                widgetId: null,
                                            })
                                        }
                                    }}
                                    widgetId={widgetId!}
                                    innerWidgetId={editInnerWidgetDialogOpen.widgetId}
                                />
                            )}
                        </Stack>
                    ) : currentTabIndex === 1 ? (
                        /*  Content settings tab
                            ========================================= */
                        <ReportWidgetSettingsSwitcher widget={activeInternalWidget} isInternal={true} />
                    ) : (
                        false
                    )}
                </Stack>
            ) : (
                /*  Null view
                    ========================================= */
                false
            )}
        </>
    )
}

export default CombinedWidgetSettings
