//* ======= Libraries
import { useState } from 'react'
import {
    Stack,
    Box,
    Typography,
    IconButton,
    Tooltip,
    Divider,
    DialogTitle,
    DialogContent,
    Chip,
    DialogActions,
    AccordionSummary,
    Accordion,
    AccordionDetails,
} from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
//* ======= Components and features
import StyledDialog from 'components/dialog/StyledDialog'
import BaseButton from 'components/base/BaseButton'
import BaseSelectWithLabel from 'components/base/BaseSelectWithLabel'
//* ======= Custom logic
import {
    AIWidgetType,
    ChartWidgetType,
    KeyInsightWidgetType,
    NetworkWidgetType,
    ReportDataSourceAttributeType,
    ReportNetworkDataSourceType,
} from 'features/report-designer/types/reportDesigner.types'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
//* ======= Assets and styles
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import DynamicIconSelector from 'features/dynamic-icon-slector/DynamicIconSelector'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { e } from 'mathjs'

type Props = {
    isOpen: boolean
    data: KeyInsightWidgetType
    onConfirm: (data: KeyInsightWidgetType) => void
    onClose: () => void
}

function KeyInsightWidgetDataConfigDialog({ isOpen = false, data, onConfirm, onClose = () => undefined }: Props) {
    const [items, setItems] = useState<KeyInsightWidgetType['items']>(data.items)

    const [expanded, setExpanded] = useState<string | false>(false)

    const addKeyInsightItem = () => {
        const id = uuidv4()
        setItems((prev) => [
            ...prev,
            {
                id,
                title: 'New Key Insight',
                content: '',
                icon: 'info',
                chips: [],
            },
        ])
        setExpanded(id)
    }

    const updateItem = (id: string, key: string, value: any) => {
        setItems((prev) =>
            prev.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        [key]: value,
                    }
                }
                return item
            })
        )
    }

    const handleAddChip = (id: string) => {
        setItems((prev) =>
            prev.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        chips: [...item.chips, ''],
                    }
                }
                return item
            })
        )
    }

    const handleChipChange = (id: string, index: number, value: any) => {
        setItems((prev) =>
            prev.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        chips: item.chips.map((chip, idx) => {
                            if (idx === index) {
                                return value
                            }
                            return chip
                        }),
                    }
                }
                return item
            })
        )
    }

    const handleDeleteChip = (id: string, index: number) => {
        setItems((prev) =>
            prev.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        chips: item.chips.filter((_, idx) => idx !== index),
                    }
                }
                return item
            })
        )
    }

    const deleteKeyInsightItem = (id: string) => {
        setItems((prev) => prev.filter((item) => item.id !== id))
    }

    const toggleExpand = (id: string) => {
        setExpanded(id === expanded ? false : id)
    }

    return (
        <StyledDialog open={isOpen} onClose={() => undefined} fullWidth maxWidth="xl">
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                    Key Insight
                    <IconButton onClick={addKeyInsightItem}>
                        <AddIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack gap={1}>
                    {items.map((currentItem, index) => (
                        <Accordion expanded={currentItem.id === expanded} key={index}>
                            <AccordionSummary onClick={() => toggleExpand(currentItem.id)}>
                                <Stack direction="row" justifyContent="space-between" gap={2} sx={{ width: '100%' }}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        {currentItem.title}
                                    </Typography>
                                    {expanded !== currentItem.id ? (
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                deleteKeyInsightItem(currentItem.id)
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                toggleExpand(currentItem.id)
                                            }}
                                        >
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    )}
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack gap={2}>
                                    <Stack direction="row" justifyContent="space-between" gap={2}>
                                        {/* Title Input */}
                                        <BaseFilledTextField
                                            label="Title"
                                            sx={{ flex: 1 }}
                                            value={currentItem.title}
                                            onChange={(e) => updateItem(currentItem.id, 'title', e.target.value)}
                                        />
                                        <Box sx={{ flexShrink: 0, flex: 1 }}>
                                            {/* Icon Input */}
                                            <DynamicIconSelector
                                                label="Icon"
                                                onSelectIcon={(icon) => updateItem(currentItem.id, 'icon', icon)}
                                                defaultIcon={currentItem.icon}
                                            />
                                        </Box>
                                    </Stack>

                                    {/* Content Input */}
                                    <BaseFilledTextField
                                        label="Content"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        value={currentItem.content}
                                        onChange={(e) => updateItem(currentItem.id, 'content', e.target.value)}
                                    />

                                    {/* Chips */}
                                    <Stack gap={1}>
                                        <Stack direction="row" justifyContent="space-between">
                                            <Typography variant="subtitle1" gutterBottom>
                                                Chips
                                            </Typography>
                                            <IconButton onClick={() => handleAddChip(currentItem.id)}>
                                                <AddIcon />
                                            </IconButton>
                                        </Stack>
                                        {currentItem.chips.map((chip, index) => (
                                            <Stack direction="row" spacing={1} key={index} gap={1}>
                                                <BaseFilledTextField
                                                    label="Label"
                                                    value={chip}
                                                    fullWidth
                                                    onChange={(e) =>
                                                        handleChipChange(currentItem.id, index, e.target.value)
                                                    }
                                                />

                                                <IconButton onClick={() => handleDeleteChip(currentItem.id, index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Stack>
                                        ))}
                                    </Stack>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Stack>
            </DialogContent>
            <DialogActions>
                <BaseButton onClick={onClose} color="secondary">
                    Cancel
                </BaseButton>
                <BaseButton
                    onClick={() => {
                        onConfirm({ items })
                        onClose()
                    }}
                    color="primary"
                    variant="contained"
                >
                    Save
                </BaseButton>
            </DialogActions>
        </StyledDialog>
    )
}

export default KeyInsightWidgetDataConfigDialog
