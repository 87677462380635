//* ======= Libraries
import { useState, useEffect, useMemo } from 'react'
import { PartialDeep } from 'type-fest'
import { Stack, Box, Tabs, Tab } from '@mui/material'
//* ======= Components and features
import StyledWidgetAccordion from 'components/styled-widget-accordion/StyledWidgetAccordion'
import ColorPicker from 'components/widgets/ColorPicker'
import BorderPicker from 'components/widgets/BorderPicker'
import BaseFilledTextField from 'components/base/BaseFilledTextField'
//* ======= Custom logic
import useReportStore, { useReportActiveWidget } from 'features/report-designer/store/reportDesignerStore'
import {
    ReportWidgetType,
    ReportWidgetContentKindType,
    ReportWidgetSettingsBaseType,
    KeyInsightWidgetType,
} from 'features/report-designer/types/reportDesigner.types'
import BaseButton from 'components/base/BaseButton'
import KeyInsightWidgetDataConfigDialog from './KeyInsightWidgetConfigDialog'
import ReportWidgetTooltipSettings from '../components/ReportWidgetTooltipSettings'
import ReportConditionalVisibilitySettings from '../components/ReportWidgetConditionalVisibilitySettings'
//* ======= Assets and styles

type InternalWidgetStateType = {
    kind: Extract<ReportWidgetContentKindType, 'keyInsight'>
    settings: Omit<ReportWidgetType, 'content'>
    details: KeyInsightWidgetType
}

function KeyInsightWidgetSettings({ widgetId, isInternal = false }: ReportWidgetSettingsBaseType) {
    const { activeSlideId, updateWidget, updateWidgetStyles, updateKeyInsightWidgetContent } = useReportStore(
        (store) => ({
            activeSlideId: store.activeSlideId,
            updateWidget: store.updateWidget,
            updateWidgetStyles: store.updateWidgetStyles,
            updateKeyInsightWidgetContent: store.updateKeyInsightWidgetContent,
        })
    )

    const activeWidget = useReportActiveWidget(widgetId)

    // Create an internal widget state on activeWidget change.
    const widget = useMemo<InternalWidgetStateType | null>(() => {
        if (activeWidget === null || activeWidget.content.kind !== 'keyInsight') return null

        const {
            content: { kind, details },
            ...settings
        } = activeWidget

        return {
            kind: kind,
            settings: settings,
            details: details,
        }
    }, [activeWidget])

    const [currentTabIndex, setCurrentTabIndex] = useState(1)

    // Update and any dependant states on activeWidget change.
    useEffect(() => {
        if (activeWidget !== null && activeWidget.content.kind === 'info') {
            const {
                content: { details },
            } = activeWidget
        }
    }, [activeWidget])

    const updateStyles = (styles: PartialDeep<ReportWidgetType['styles']>) => {
        if (activeSlideId !== null && widget !== null) {
            updateWidgetStyles({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                styles: styles,
            })
        }
    }

    const updateDetails = (details: PartialDeep<KeyInsightWidgetType>) => {
        if (activeSlideId !== null && widget !== null) {
            updateKeyInsightWidgetContent({
                slideId: activeSlideId,
                widgetId: widget.settings.id,
                data: details,
            })
        }

        if (isDataConfigDialogOpen) {
            setIsDataConfigDialogOpen(false)
        }
    }

    const [isDataConfigDialogOpen, setIsDataConfigDialogOpen] = useState<boolean>(false)

    return (
        <>
            {activeSlideId !== null && widget !== null ? (
                <Stack
                    gap={2}
                    sx={(theme) => ({
                        height: '100%',
                    })}
                >
                    {/* Tabs
                        ========================================= */}
                    {isInternal === false && (
                        <Box
                            sx={{
                                flexShrink: 0,

                                borderBottom: 1,
                                borderColor: 'divider',
                            }}
                        >
                            <Tabs
                                value={currentTabIndex}
                                onChange={(evt, currentIndex) => setCurrentTabIndex(currentIndex)}
                                variant="fullWidth"
                            >
                                <Tab label="Container" />
                                <Tab label="Info" />
                            </Tabs>
                        </Box>
                    )}

                    {/* Tab panels
                        ========================================= */}
                    {currentTabIndex === 0 ? (
                        /*  Container settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Title
                                ========================================= */}
                            <BaseFilledTextField
                                label="Widget Title"
                                defaultValue={widget.settings.title}
                                onBlur={(evt) =>
                                    updateWidget({
                                        slideId: activeSlideId,
                                        widgetId: widget.settings.id,
                                        data: {
                                            title: evt.target.value.trim(),
                                        },
                                    })
                                }
                                size="small"
                                fullWidth
                            />

                            {/* Background color
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Background Color"
                                hasToggle
                                isToggledOff={widget.settings.styles.backgroundColor.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        backgroundColor: {
                                            isEnabled: isEnabled,
                                            color: widget.settings.styles.backgroundColor.color ?? '#ffffff',
                                        },
                                    })
                                }
                                hasBottomBorder
                            >
                                <ColorPicker
                                    value={widget.settings.styles.backgroundColor.color}
                                    onChange={(newColor) =>
                                        updateStyles({
                                            backgroundColor: {
                                                color: newColor,
                                            },
                                        })
                                    }
                                    disabled={widget.settings.styles.backgroundColor.isEnabled === false}
                                    isPopover={true}
                                />
                            </StyledWidgetAccordion>

                            {/* Border
                                ========================================= */}
                            <StyledWidgetAccordion
                                title="Border"
                                hasToggle
                                isToggledOff={widget.settings.styles.border.isEnabled === false}
                                onToggle={(isEnabled) =>
                                    updateStyles({
                                        border: {
                                            isEnabled: isEnabled,
                                        },
                                    })
                                }
                                hasBottomBorder
                            >
                                <BorderPicker
                                    value={widget.settings.styles.border}
                                    onChange={(value) =>
                                        updateStyles({
                                            border: {
                                                width: value.width,
                                                style: value.style,
                                                color: value.color,
                                                radius: value.radius,
                                            },
                                        })
                                    }
                                    disabled={widget.settings.styles.border.isEnabled === false}
                                />
                            </StyledWidgetAccordion>

                            {/* Conditional visibility
                                ========================================= */}
                            <ReportConditionalVisibilitySettings
                                conditionalVisibility={widget.settings.conditionalVisibility}
                                onChange={(conditionalVisibility) => {
                                    updateWidget({
                                        slideId: activeSlideId,
                                        widgetId: widget.settings.id,
                                        data: {
                                            conditionalVisibility,
                                        },
                                    })
                                }}
                            />

                            {/* Tooltip
                            ========================================= */}
                            <ReportWidgetTooltipSettings
                                tooltip={widget.settings.tooltip}
                                onChange={(tooltip) => {
                                    updateWidget({
                                        slideId: activeSlideId,
                                        widgetId: widget.settings.id,
                                        data: {
                                            tooltip,
                                        },
                                    })
                                }}
                            />
                        </Stack>
                    ) : currentTabIndex === 1 ? (
                        /*  Content settings tab
                            ========================================= */
                        <Stack
                            gap={2}
                            sx={(theme) => ({
                                flexGrow: 1,

                                paddingX: theme.spacing(2),
                                overflowY: 'auto',
                                // This line removes the horizontal scrollbar during sidebar expand/collapse animation.
                                overflowX: 'hidden',
                            })}
                            className="u-scrollbar"
                        >
                            {/* Configure data button
				                ========================================= */}
                            <BaseButton
                                label="Configure Data"
                                onClick={(evt) => setIsDataConfigDialogOpen(true)}
                                variant="outlined"
                                sx={(theme) => ({
                                    alignSelf: 'center',

                                    width: '80%',
                                    paddingY: theme.spacing(1.5),
                                })}
                            />
                        </Stack>
                    ) : (
                        false
                    )}
                </Stack>
            ) : (
                /*  Null view
                    ========================================= */
                false
            )}

            {/* (Out-of-flow) Data config dialog
				        ========================================= */}
            {isDataConfigDialogOpen && widget && (
                <KeyInsightWidgetDataConfigDialog
                    isOpen={isDataConfigDialogOpen}
                    data={widget.details}
                    onConfirm={updateDetails}
                    onClose={() => setIsDataConfigDialogOpen(false)}
                />
            )}
        </>
    )
}

export default KeyInsightWidgetSettings
